import React, {useEffect} from 'react';
import {Button} from 'antd';
import { Popconfirm } from 'antd';
import { API } from "services";
import {useSelector,useDispatch} from 'react-redux';
import { timeStamp } from 'helpers/date-formatter';

function ActiveInactive({record,tempData}) {
  const dispatch = useDispatch();
  const { lastUpdatedTime } = useSelector((state) => state.profile);

  useEffect(()=>{

  },[lastUpdatedTime])
    
  function confirm(e,record) {

        let data = [{
          market: record?.market?.id,
          emailNotification: e
        }]

        const CREDENTIALS1 = {
					url: `/users/${tempData}/markets`,
					method: 'put',
					data: data
				};
		
				API.common(CREDENTIALS1)
					.then((response) => {
            dispatch({
			type: 'PROFILE_LAST_UPDATED_TIME',
			payload: timeStamp(),
		});
					})
        }
      
      function cancel(e) {
        console.log(e.target.value);
      }

  return (
    <>
    <Popconfirm
    title={record?.emailNotification === 1? "Click to Disable Email Notification":"Click to Enable Email Notification"}
    onConfirm={()=>confirm(record?.emailNotification===1?0:1, record)}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
{record?.emailNotification ===1? 
  <Button type="link">Enabled</Button>:<Button type="link">Disabled</Button>
  }
   </Popconfirm>
    </>
  )
}

export default ActiveInactive