import React, { useState, useEffect } from "react";
import { Form, Input, Select } from "formik-antd";
import { Col, Row, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { FormActionButtons } from "../FormActionButtons";
import { getCategories,getServices } from "services/common";
import * as Yup from "yup";
import { API } from "services";
import { leftToFilterOptions } from "helpers";

export function PricingForm({ onClose, editMode, editablePricing }) {
  const dispatch = useDispatch();
  let { productsCategories } = useSelector((state) => state?.model);
  let { markets = [] } = useSelector((state) => state?.model);
  let { Services = [] } = useSelector(
    (state) => state?.model
  );
  let { vehicleTypes = [] } = useSelector((state) => state?.model);
  const [selectedCategory, setselectedCategory] = useState();
  const [DateSevice, setDataSevice]=useState([]);
  console.log(editablePricing);
  const initialValues = {
    service: editMode
      ? editablePricing?.service === null
        ? undefined
        : editablePricing?.service?.id
      : undefined,
    vehicleType: editMode
      ? editablePricing?.vehicleType === null
        ? undefined
        : editablePricing?.vehicleType?.id
      : undefined,
    market: editMode
      ? editablePricing?.market === null
        ? undefined
        : editablePricing?.market?.id
      : undefined,
    price: editMode
      ? editablePricing?.price === null
        ? undefined
        : editablePricing?.price
      : undefined,
      servicecategory:editMode? editablePricing?.service?.category?.id : undefined,
      status:editMode? editablePricing.status?editablePricing.status:1:1
  };

  const handleSubmit = (values, { setErrors, resetForm, setSubmitting }) => {
    const valuesCopy = { ...values };

    if (editMode) {
      valuesCopy.id = editablePricing.id;
    }
    const CREDENTIALS = {
      url: `/services/pricings`,
      method: "put",
      data: valuesCopy,
      setErrors,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        showSuccessMessage(valuesCopy);
        resetForm();
        onClose();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  function handleMarketChange(e,setFieldValue){
    dispatch(getServices({ markets:[e],all: true, active:[0,1] })).then((res) => setDataSevice(res?.data?.marketServices));
    setFieldValue('servicecategory',undefined);
    setselectedCategory(undefined);
  }
  function handleCategory(data) {
    setselectedCategory(data);
	let serviceData = Services?.filter(item=> data == item.category.id);
	setDataSevice(serviceData);
  }

  function showSuccessMessage(values) {
    let text = editMode ? "updated" : "added";
    message.success(`Pricing ${text} successfully`);
  }

  const PricingSchema = Yup.object({
    price: Yup.string().required("price is required"),
    market: Yup.string().required("market is required").nullable(),
    service: Yup.string().required("service is required").nullable(),
    vehicleType: Yup.string().required("vehicle type is required").nullable(),
  });
  const { Option } = Select;

  useEffect(() => {
	  dispatch(getCategories());
	  setDataSevice(Services);
    if(editMode){
    setselectedCategory(editablePricing?.service?.category?.id);
    }
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={PricingSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form layout="vertical" hideRequiredMark>
             <Row>
               {!editMode ? (
              <Col span={24} className="service_form">
                <Form.Item
                  name="market"
                  label="Markets"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    showSearch
                    name="market"
                    style={{ width: "100%" }}
                    placeholder={"Select market"}
                    allowClear={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => handleMarketChange(e,setFieldValue)}
                  >
                    {markets &&
                      markets.length > 0 &&
                      markets.map(({ id, city, alias }) => (
                        <Option key={id} value={id}>
                          {alias ? alias : "NA"}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              ):( <Col span={24} className="service_form">
              <Form.Item
                name="market"
                label="Markets"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Select
                  showSearch
                  name="market"
                  style={{ width: "100%" }}
                  placeholder={"Select market"}
                  allowClear={true}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => handleMarketChange(e,setFieldValue)}
                  disabled
                >
                  {markets &&
                    markets.length > 0 &&
                    markets.map(({ id, city, alias }) => (
                      <Option key={id} value={id}>
                        {alias ? alias : "NA"}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>)}
            </Row>
            <Row>
              {!editMode ?(
              <Col span={24}>
                <Form.Item
                  name="servicecategory"
                  label="Category"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    showSearch
                    name="servicecategory"
                    style={{ width: "100%" }}
                    value={selectedCategory}
                    placeholder={"Select Category"}
                    allowClear={true}
                    optionFilterProp="children"
                    onChange={(e) => handleCategory(e)}
                  >
                    {productsCategories?.length > 0 &&
                      productsCategories.map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              ):(<Col span={24}>
                <Form.Item
                  name="servicecategory"
                  label="Category"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    showSearch
                    name="servicecategory"
                    style={{ width: "100%" }}
                    value={selectedCategory}
                    placeholder={"Select Category"}
                    allowClear={true}
                    optionFilterProp="children"
                    onChange={(e) => handleCategory(e)}
                    disabled
                  >
                    {productsCategories?.length > 0 &&
                      productsCategories.map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>)}
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name="service"
                  label="Service"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    showSearch
                    name="service"
                    style={{ width: "100%" }}
                    placeholder={"Select service"}
                    allowClear={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={() => console.log(values)}
                    disabled={editMode?true:false}
                  >
                    {DateSevice &&
                      DateSevice.length > 0 &&
                      DateSevice.map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <Form.Item
                  name="vehicleType"
                  label="Pricing Tier"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    showSearch
                    name="vehicleType"
                    style={{ width: "100%" }}
                    placeholder={"Select Pricing Tier"}
                    allowClear={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={() => console.log(values)}
                  >
                    {vehicleTypes &&
                      vehicleTypes.length > 0 &&
                      vehicleTypes.map(({ id, name, tier }) => (
                        <Option key={id} value={id}>
                          {tier}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label="App Display"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    showSearch
                    name="status"
                    style={{ width: "100%" }}
                    placeholder={"Select Status"}
                    allowClear={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={() => console.log(values)}
                  >
                    <option value={1}>Show</option>
                    <option value={2}>Hide</option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="price"
                  label="Price"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input type="number" name="price" placeholder="Price" />
                </Form.Item>
              </Col>
            </Row>

            <FormActionButtons onClose={onClose} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </div>
  );
}
