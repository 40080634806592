const initialState = {
  selectedJobsKey: undefined,
  jobFormVisible: false,
  jobEditFormVisible: false,
  editableJob: {},
  selectedUnAssignJobs: [],
  liveTrackerJobsModal: false,
  liveTrackerJobsData: [],
  liveTrackerJobKey: undefined,
  showWorkOrderForm: false,
  editJrid: {},
  set401Err: [],
  erro401ModelVisible: false,
  lastUpdatedTime: "",
  lastUpdatedEditTime: "",
  editableEditJob: {},
  jobEditFormJobStatusVisible: false,
  editableEditJobStatus: {},
  jobkey: "",
  saveJobform: "",
  isOpen: false,
  index: 0,
  editApplyForm: false,
  applyForm: {},
  tempVar: "",
  newTempVar: "",
  paymentsTemp:{},
  addId:0,
  tempDataBooking:{},
  btnDisable:true,
  updatedTime:""
};

export function job(state = initialState, action) {
  switch (action.type) {
    case "SHOW_JOB_FORM":
      return {
        ...state,
        jobFormVisible: true,
        editableJob: action.payload,
        lastUpdatedTime: "",
        saveJobform: "",
      };
    case "SHOW_EDIT_JOB_FORM":
      return {
        ...state,
        jobEditFormVisible: true,
        lastUpdatedTime: "",
        saveJobform: "",
      };
    case "SHOW_EDIT_JOB_STATUS_FORM":
      return {
        ...state,
        jobEditFormJobStatusVisible: true,
        lastUpdatedTime: "",
        saveJobform: "",
      };
    case "HIDE_EDIT_JOB_FORM":
      return { ...state, jobEditFormVisible: false };
    case "HIDE_EDIT_JOB_STATUS_FORM":
      return { ...state, jobEditFormJobStatusVisible: false };
    case "HIDE_JOB_FORM":
      return { ...state, jobFormVisible: false, editableJob: {} };
    case "SAVE_JOB_FORM":
      return { ...state, saveJobform: action.payload };
    case "HIDE_SAVE_JOB_FORM":
      return { ...state, saveJobform: "" };
    case "REMOVE_SELECTED_ALL_JOBS":
      return {
        ...state,
        selectedUnAssignJobs: [],
      };
    case "SELECTED_JOB_KEY":
      console.log(state);
      return {
        ...state,
        selectedJobsKey: action.payload,
      };
    case "CLEAR_SELECTED_JOB_KEY":
      return {
        ...state,
        selectedJobsKey: undefined,
      };
    case "USER_JOB_LAST_UPDATED_TIME": {
      return { ...state, lastUpdatedTime: action.payload };
    }
    case "JOBS_KEY": {
      return { ...state, jobkey: action.payload };
    }
    case "CLEAR_JOBS_KEY": {
      return { ...state, jobkey: "" };
    }
    case "ADD_EDIT_JOB": {
      return { ...state, editableEditJob: action.payload };
    }
    case "ADD_EDIT_JOB_STATUS": {
      return { ...state, editableEditJobStatus: action.payload };
    }
    case "OPEN_LIGHTBOX": {
      return {
        ...state,
        isOpen: action.payload.open,
        index: action.payload.index,
      };
    }
    case "CHANGE_INDEX": {
      return { ...state, index: action.payload };
    }
    case "REMOVE_SELECTED_JOBS": {
      let { editableEditJob } = state;
      const array = editableEditJob.filter(
        (val) => val.id !== action.payload.id
      );

      return {
        ...state,
        editableEditJob: array,
      };
    }
    case "REMOVE_SELECTED_JOBS_FROM_STATUS": {
      let { editableEditJobStatus } = state;
      const array = editableEditJobStatus.filter(
        (val) => val.id !== action.payload.id
      );

      return {
        ...state,
        editableEditJobStatus: array,
      };
    }
    case "APPLY_COUPONS_FORM": {
      return {
        ...state,
        editApplyForm: true,
        lastUpdatedTime: "",
      };
    }
    case "ADD_COUPONS_FORM": {
      return {
        ...state,
        applyForm: action.payload,
        lastUpdatedTime: "",
      };
    }
    case "HIDE_APPLY_COUPONS": {
      return {
        ...state,
        editApplyForm: false,
        applyForm: {}
      };
    }
    case "APPLY_COUPONS_LAST_UPDATED_TIME": {
      return {
        ...state,
        lastUpdatedTime: action.payload,
      };
    }
    case "ADD_COUPONS_DATA_TEMP": {
      return {
        ...state,
        newTempVar: action?.payload,
      };
    }
    case "REMOVE_COUPONS_DATA_TEMP": {
      return {
        ...state,
        tempVar: action?.payload,
      };
    }
    case "GET_TEMP_PAYMENT": {
      return {
        ...state,
        paymentsTemp: action?.payload,
      };
    }
    case "REMOVE_TEMP_PAYMENT": {
      return {
        ...state,
        paymentsTemp: {},
      };
    }
    case "ADD_ID": {
      return {
        ...state,
        addId: action.payload,
      };
    }
    case "TEMP_DATA": {
      return {
        ...state,
        tempDataBooking:action.payload
      };
    }
    case "DISABLE_BTN": {
      return {
        ...state,
        btnDisable:action.payload
      };
    }

    case "UPDATED_TIME": {
      return {
        ...state,
        updatedTime: action.payload,
      };
    }
    default:
      return state;
  }
}
