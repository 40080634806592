import React from 'react';
import { Drawer } from 'antd';
import { ProfileForm } from 'forms/profile';
import { useDispatch, useSelector } from 'react-redux';
import { timeStamp } from 'helpers/date-formatter';

export default function CUProfile() {
    const dispatch = useDispatch();
    const { profileFormVisible, editableProfile } = useSelector(
		(state) => state.profile
	);
	const editMode = 'id' in editableProfile ? true : false;
	const formHeaderText = editMode
		? 'Edit Profile ':'Edit Profile'
    
    function onClose() {
		dispatch({
			type: 'HIDE_PROFILE_FORM',
			payload: {},
		});
		dispatch({
			type: 'PROFILE_LAST_UPDATED_TIME',
			payload: timeStamp(),
		});
	}

    function showDrawer(){
        dispatch({
            type: 'SHOW_PROFILE_FORM',
            payload: {},
        });
    }
 
	//show edit profile form in drawer
    return (
        <div>
			<div style={{display:"flex", justifyContent:"flex-end"}}>
            {/* <Button type="primary" onClick={showDrawer}>
				<PlusOutlined /> Create User
			</Button> */}
			</div>
            <div>
            <Drawer
				title={formHeaderText}
				width={750}
				onClose={onClose}
				visible={profileFormVisible}
				destroyOnClose={true}
				closable={false}
				maskClosable={true}
			>
				<ProfileForm
					onClose={onClose}
					editMode={editMode}
					editableProfile={editableProfile}
				/>
			</Drawer>
            </div>
        </div>
    )
}


