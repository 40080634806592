import axios from 'axios';
import { checkACL } from 'helpers/acl';
import { isEmpty } from 'lodash';
import store from 'store/configureStore';

let userInfoData;

(() => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	if (urlParams.has('userInfoData')) {
		/**
		 * when first time login, clear old redux state and localStorage data
		 */
		store.dispatch({
			type: 'LOGOUT',
		});
		userInfoData = urlParams.get('userInfoData');
		localStorage.setItem('userInfoData', userInfoData);
		let user = JSON.parse(userInfoData);
		store.dispatch({
			type: 'SET_CURRENT_USER_ID',
			payload: user?.id,
		});
	} else {
		userInfoData = localStorage.getItem('userInfoData');
	}
})();

/**
 * set baseurl for all axios request
 */
axios.defaults.baseURL = process.env.REACT_APP_API_URL_V1;
axios.defaults.timeout = 60000; // Max timeout

// Add a request interceptor
axios.interceptors.request.use(
	function (config) {
		// console.log('request interceptors', config.url);
		// Do something before request is sent

		// if (!checkACL(config)) {
		// 	return Promise.reject('no access');
		// 	// return false;
		// }

		try {
			if (!isEmpty(userInfoData)) {
				let token = JSON.parse(userInfoData).authToken;
				config.headers.Authorization = `Bearer ${token}`;
				delete config.headers.Cookie;
			}
		} catch (error) {
			console.log('catch', error);
		}

		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// Add a response interceptor
axios.interceptors.response.use(
	function (response) {
		// console.log('response interceptors', response);
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		return Promise.reject(error);
	}
);
export default axios;
