import React,{useState,useEffect} from 'react'
import { Form, Input, Select } from 'formik-antd'
import {  Col, Row,message } from 'antd'
import { Formik } from 'formik'
import { FormActionButtons } from '../FormActionButtons'
import {leftToFilterOptions} from 'helpers';
import * as Yup from 'yup'
import {API} from "services";

export function TechinicianForm({onClose,editMode,editableTechnician}) {
	const [markets,setMarkets]=useState([]);
	let data = JSON.parse(localStorage.getItem('userInfoData'));
	let adminUserType = data?.userType?.id;
let adminMarket = {};

if(adminUserType === 4){
	adminMarket = data?.markets;
}
	const initialValues={
		firstName:
			editMode?editableTechnician.firstName
				: undefined,
		lastName:
			editMode? editableTechnician.lastName
				: undefined,
		email: editMode ? editableTechnician.email : undefined,
		countryCode: editMode ? Number(editableTechnician.countryCode): 1,
		phone: editMode ? editableTechnician.phone : undefined,
		technicianCode:editMode?editableTechnician.technicianCode:undefined,
		markets:
			editMode && editableTechnician.markets && editableTechnician.markets.length > 0
				? editableTechnician.markets.map((obj) => obj.id)
				: undefined,
	}

	const UserSchema= Yup.object({
		firstName: Yup.string()
			.matches(/^[a-zA-Z ]*$/, 'Is not in correct format')
			.required('First Name required'),
		lastName: Yup.string()
			.matches(/^[a-zA-Z ]*$/, 'Is not in correct format')
			.required('Last name required'),
		email: Yup.string()
			.required('Email required')
			.email('Invalid email format'),
		countryCode: Yup.number().required('Country code required'),
		phone: Yup.string()
			.required('Phone number required')
			.matches(/^[1-9]\d{9}$/, {
				message: 'Please enter valid phone number.',
				excludeEmptyString: false,
			}),
		markets: Yup.string().required('market is required').nullable(),
		technicianCode: Yup.string().when('role', {
			is: (role) => role === "field",
			then: Yup.string().required('Technician Number is required'),
		}),
		})
    const { Option } = Select;

	useEffect(()=>{
		const CREDENTIALS = {
			url: `/markets`,
			method: 'get',
      queryParams:{
          all:true
      }
		};

		API.common(CREDENTIALS)
			.then((response) => {
				if(adminUserType === 4){
					data = response?.data?.markets?.filter(item=> adminMarket?.includes(item?.id));
				   }else{
					data = response?.data?.markets;
				   }
					setMarkets(data);
			}).catch(err=>console.log(err))
	},[])

	const handleSubmit=(values,{ setErrors, resetForm, setSubmitting})=>{
		const valuesCopy ={...values};
		valuesCopy.phone = String(valuesCopy.phone);
		valuesCopy.technicianCode = String(valuesCopy.technicianCode);
		valuesCopy.countryCode = String(valuesCopy.countryCode);
		if(editMode){
			valuesCopy.id = editableTechnician.id;
		}
			delete valuesCopy.role;
			const CREDENTIALS = {
				url: `/technicians`,
				method: editMode?'put':'post',
				data: valuesCopy,setErrors
			};
	
			API.common(CREDENTIALS)
				.then((response) => {
					showSuccessMessage(valuesCopy);
				resetForm();
				onClose();
				})
				.finally(() => {
					setSubmitting(false);
				});
		
	}

	function showSuccessMessage(values) {
		let text = editMode ? 'updated' : 'added';
		message.success(`${values.firstName} employee ${text} successfully`);
	}
    return (
            <Formik
			initialValues={initialValues}
			validationSchema={UserSchema}
			onSubmit={handleSubmit}
		>
			{({ values, isSubmitting, setFieldValue,setFieldTouched }) => (
				<Form layout="vertical" hideRequiredMark>
					<Row gutter={8}>
						<Col span={12}>
							<Form.Item
								name="firstName"
								label="First name"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Input
									name="firstName"
									placeholder="First name"
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="lastName"
								label="Last name"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Input
									name="lastName"
									placeholder="Last name"
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={8}>
						{editMode &&(
						<Col span={12}>
							<Form.Item
								name="email"
								label="Email"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Input name="email" placeholder="Email" disabled={true}/>
							</Form.Item>
						</Col>
						)}
						{!editMode &&(
						<Col span={12}>
							<Form.Item
								name="email"
								label="Email"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Input name="email" placeholder="Email" />
							</Form.Item>
						</Col>
						)}
						<Col span={12}>
							<Form.Item
								name="phone"
								label="Phone number"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Input
									addonBefore={
										<Select
											showSearch
											name="countryCode"
											style={{ width: 120 }}
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.props.children
													.toLowerCase()
													.indexOf(
														input.toLowerCase()
													) >= 0
											}
										>
											<Option key={1} value={1}>
												+1 - USA
											</Option>
											<Option key={91} value={91}>
												+91 - India
											</Option>
										</Select>
									}
									type="number"
									step="1"
									name="phone"
									placeholder="Phone number"
									style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
					<Col span={12}>
							<Form.Item
								name="technicianCode"
								label="Technician Number"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Input
									name="technicianCode"
									placeholder="Technician Number"
                                    type="number"
								/>
							</Form.Item>
						</Col>
					<Col span={12}>
						<Form.Item
								name="markets"
								label="Markets"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Select
									showSearch
									name="markets"
									style={{ width: '100%' }}
									placeholder={'Select markets'}
									allowClear={true}
									mode="multiple"
									optionFilterProp="children"
									
									filterOption={(input, option) =>
										leftToFilterOptions(input, option)
									}
								>
									{markets &&
										markets.length > 0 &&
										markets.map(({ id, city,alias }) => (
											<Option key={id} value={id}>
												{alias? alias:'NA'}
											</Option>
										))}
								</Select>
							</Form.Item>
						</Col>
						</Row>

					<FormActionButtons
						onClose={onClose}
						isSubmitting={isSubmitting}
					/>
				</Form>
			)}
		</Formik>
    )
}


