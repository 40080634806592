import React, { lazy, Suspense } from "react";
import { PageHeader } from "antd";
import { CUServices} from "../components/pages/services";
const DTServices = lazy(() =>import("components/pages/services/DTServices"));

function Services() {
  return (
    <div>
      <div className="p-20 white-bc title-box-shadow">
        <PageHeader className="mb-10" title="Services" />
      </div>

      <div className="p-20 pt-0">
        <CUServices/>

        <Suspense fallback={<p>Loading...</p>}>
          <DTServices />
        </Suspense>
      </div>
    </div>
  );
}

export default Services;
