import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { PricingForm } from '../../../forms/pricing';
import { useDispatch, useSelector } from 'react-redux';
import { timeStamp } from 'helpers/date-formatter';
import { getPricings } from 'services/common';
import { getParamsFromUrl, pushParamsToUrl } from "helpers/history";
import { useHistory, useLocation } from "react-router-dom";

export default function CUPrices() {
	const history = useHistory();

	const params = getParamsFromUrl(history);

	const dispatch = useDispatch();
	const { pricingFormVisible, editablePricing } = useSelector(
		(state) => state.pricings
	);
	const editMode = 'id' in editablePricing ? true : false;
	const formHeaderText = editMode
		? 'Edit Pricing' : 'Create Pricing';

	function onClose() {
		dispatch({
			type: 'HIDE_PRICING_FORM',
			payload: {},
		});
		dispatch({
			type: 'HIDE_PRICING_FORM',
			payload: {},
		});

		const params = getParamsFromUrl(history);
		const queryParams = { ...params };
		dispatch(getPricings(queryParams));
	}

	function showDrawer() {
		dispatch({
			type: 'SHOW_PRICING_FORM',
			payload: {},
		});
	}

	return (
		<div>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button type="primary" onClick={showDrawer}>
					<PlusOutlined /> Add Pricing
				</Button>
			</div>
			<div>
				<Drawer
					title={formHeaderText}
					width={550}
					onClose={onClose}
					visible={pricingFormVisible}
					destroyOnClose={true}
					closable={false}
					maskClosable={true}
				>
					<PricingForm
						onClose={onClose}
						editMode={editMode}
						editablePricing={editablePricing}
					/>
				</Drawer>
			</div>
		</div>
	)
}


