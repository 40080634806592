import {
  Col,
  message,
  Row,
  Typography,
  TimePicker,
  Card,
  Button,
  Switch,
} from "antd";
import { Formik, FieldArray } from "formik";
import { Form, InputNumber, Input, Select } from "formik-antd";
import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import Geosuggest from "react-geosuggest";
import { API } from "services";
import { useQueryParams } from "hooks";
import { TIME_ZONES } from "utils/constants";
import moment from "moment-timezone";
import normalMoment from "moment";
import * as geolib from "geolib";
import { leftToFilterOptions } from "helpers";

import {
  convertAddressComponents,
  getInitialValues,
  getGroupedMarketInitialValues,
  getValidationSchema,
  getGroupedMarketsValidationSchema,
} from ".";
import _ from "lodash";
import { FormActionButtons } from "..";
import { EditOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Text } = Typography;

const TIME_ZONES_LIST = TIME_ZONES.map(({ id }) => (
  <Option key={id} value={id}>
    {id}
  </Option>
));

function MarketForm({ editMode, editableMarket, onClose, isSingle }) {
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [cancellationOffsetTime, setCancellationOffsetTime] = useState();
  const [switchValue,setSwitchValue]=useState(false);

  useEffect(() => {
    if (editMode) {
      if(editableMarket?.marketPreference?.reviewBooking == 2){
        setSwitchValue(true);
      }else{
        setSwitchValue(false);
      }
      if (editableMarket?.marketPreference === null) {
        setStartTime(moment(`00:00`, "hh:mm A"));
        setEndTime(moment(`00:00`, "hh:mm A"));
        setCancellationOffsetTime(moment(`00:00`, "hh:mm A"));
      }
      if (editableMarket?.marketPreference?.shiftStartTime === null) {
        setStartTime(moment(`00:00`, "hh:mm A"));
      } else {
        let date = moment.utc().format("YYYY-MM-DD");
        let newDate = moment
          .utc(
            `${date} ${editableMarket?.marketPreference?.shiftStartTime}`,
            "YYYY-MM-DD hh:mm A"
          )
          .clone()
          .tz(editableMarket?.timezone)
          .format("hh:mm A");

        if (newDate === "Invalid date") {
          setStartTime(moment(`00:00`, "hh:mm A"));
        } else {
          setStartTime(moment(`${newDate}`, "hh:mm A"));
        }
        let newDateEnd = moment
          .utc(
            `${date} ${editableMarket?.marketPreference?.shiftEndTime}`,
            "YYYY-MM-DD hh:mm A"
          )
          .clone()
          .tz(editableMarket?.timezone)
          .format("hh:mm A");

        if (newDateEnd === "Invalid date") {
          setEndTime(moment(`00:00`, "hh:mm A"));
        } else {
          setEndTime(moment(`${newDateEnd}`, "hh:mm A"));
        }
        // setStartTime(moment(`${editableMarket?.marketPreference?.shiftStartTime}`,'HH:mm:ss'));
        // setEndTime(moment(`${editableMarket?.marketPreference?.shiftEndTime}`,'HH:mm:ss'));
      }
      if (editableMarket?.marketPreference?.shiftEndTime === null) {
        setEndTime(moment(`00:00`, "hh:mm A"));
      }

      if (editableMarket?.marketPreference?.cancellationOffsetMinutes != null) {
        setCancellationOffsetTime(
          moment(
            moment
              .utc()
              .hours(
                editableMarket?.marketPreference?.cancellationOffsetMinutes / 60
              )
              .minutes(
                editableMarket?.marketPreference?.cancellationOffsetMinutes % 60
              )
              .format("hh:mm A"),
            "hh:mm A"
          )
        );
      }
    }
  }, []);

  const _geoSuggest = useRef();
  const closeRef = useRef(0);

  console.log(closeRef.current);
  function handleResetCallBack() {
    _geoSuggest.current.clear();
  }

  function hoursToMinutes(hour) {
    return moment.duration(moment(hour, "hh:mm A").format("HH:mm")).asMinutes();
  }

  function onSuggestSelect(suggest, setValues, values) {
    if (suggest) {
      const selectedLocation = {
        lat: Number(suggest.location.lat),
        lon: Number(suggest.location.lng),
      };
      const mylocation = {
        lat: 52.523,
        lon: 13.412,
      };
      const d = geolib.getDistance(selectedLocation, mylocation, 1);
      const distanceInMiles = geolib.convertDistance(d, "mi");
      console.log(distanceInMiles + "Miles");

      const newAddr = convertAddressComponents(
        suggest.gmaps.address_components
      );
      setValues({
        ...values,
        cityName: newAddr.cityName,
        stateName: newAddr.stateName,
        countryName: newAddr.countryName,
        latitude: suggest.location.lat,
        longitude: suggest.location.lng,
      });
    }
  }

  function handleChangeTime(setFieldValue, timeString) {
    console.log(timeString);
    setFieldValue("shiftStartTime", timeString);
    setStartTime(moment(`${timeString}`, "hh:mm A"));
  }

  function handleEndTime(setFieldValue, timeString) {
    console.log(timeString);
    setFieldValue("shiftEndTime", timeString);
    setEndTime(moment(`${timeString}`, "hh:mm A"));
  }

  function handleChangeCancellationTime(setFieldValue, timeString) {
    console.log(timeString);
    setFieldValue("cancellationOffsetTime", timeString);
    setCancellationOffsetTime(moment(`${timeString}`, "hh:mm A"));
  }

  function handleSubmit(values, { setErrors, resetForm, setSubmitting }) {
    console.log(values);
    let url = `/markets`,
      data = {
        tax: values.tax,
        timezone: values.timezone,
        city: values.cityName,
        state: values.stateName,
        country: values.countryName,
        latitude: values.latitude,
        longitude: values.longitude,
        alias: values.alias,
      };

    const newData = {
      id: editableMarket?.id,
      tax: values?.tax,
      timezone: editableMarket?.timezone,
      city: editableMarket?.cityName,
      state: editableMarket?.stateName,
      country: editableMarket?.countryName,
      latitude: editableMarket?.latitude,
      longitude: editableMarket?.longitude,
      alias: values.alias,
    };
    let date = moment.utc().format("YYYY-MM-DD");
    // let toUtc = moment.tz(`${date} ${values?.shiftStartTime}`, 'YYYY-MM-DD HH:mm', values?.timezone).utc().format('HH:mm:ss');
    // console.log(toUtc);

    const valueCopy = {
      servicesPerTimeslot: values.servicesPerTimeslot,
      shiftStartTime: moment
        .tz(
          `${date} ${startTime.format("HH:mm:ss")}`,
          "YYYY-MM-DD HH:mm:ss",
          values?.timezone
        )
        .utc()
        .format("HH:mm:ss"),
      shiftEndTime: moment
        .tz(
          `${date} ${endTime.format("HH:mm:ss")}`,
          "YYYY-MM-DD HH:mm:ss",
          values?.timezone
        )
        .utc()
        .format("HH:mm:ss"),
      frequency: values.frequency,
      cancellationFee: values.cancellationFee,
      cancellationOffsetMinutes: hoursToMinutes(values?.cancellationOffsetTime),
      dispatch: values.dispatch,
      bookingOffsetHours: values.bookingOffsetHours,
    };
    if (editMode) {
      valueCopy.id = editableMarket?.marketPreference?.id;
      valueCopy.market = editableMarket?.id;
    }
    if(values?.reviewBooking === true){
      valueCopy.reviewBooking = 2;
    }else{
      valueCopy.reviewBooking = 1;
    }
    const CREDENTIALS = {
      url,
      method: editMode ? "put" : "post",
      data: editMode ? newData : data,
      setErrors,
    };

    const CREDENTIALS1 = {
      url: "/markets/preferences",
      method: editMode ? "put" : "post",
      data: valueCopy,
      setErrors,
    };

    const valueService = [...values.serviceZones];

    if (editMode) {
      valueService.map((service) => (service.market = editableMarket?.id));
      valueService.map(
        (service) => (service.latitude = String(service.latitude))
      );
      valueService.map(
        (service) => (service.longitude = String(service.longitude))
      );
      delete valueService.map((service) => delete service.createdAt);
      delete valueService.map((service) => delete service.updatedAt);
      delete valueService.map((service) => delete service.active);
    }

    const CREDENTIALS2 = {
      url: "/markets/service-zones",
      method: editMode ? "put" : "post",
      data: valueService,
      setErrors,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        if (!editMode) {
          CREDENTIALS1.data.market = response?.data?.market?.id;
          CREDENTIALS2.data.forEach(
            (service) => (service.market = response?.data?.market?.id)
          );
        }

        API.common(CREDENTIALS1)
          .then(() => {
            API.common(CREDENTIALS2)
              .then(() => {
                showSuccessMessage();
                resetForm();
                onClose();
              })
              .catch((e) => {})
              .finally(() => {
                setSubmitting(false);
              });
          })
          .catch((e) => {})
          .finally(() => {
            setSubmitting(false);
          });
      })
      .catch((e) => {})
      .finally(() => {
        setSubmitting(false);
      });
  }

  function showSuccessMessage() {
    let text = editMode ? "updated" : "added";
    message.success(`market ${text} successfully`);
    onClose();
  }

  let startTimeinitial = "";
  let endTimeinitial = "";
  if (editMode) {
    let date = moment.utc().format("YYYY-MM-DD");
    let newDate = moment
      .utc(
        `${date} ${editableMarket?.marketPreference?.shiftStartTime}`,
        "YYYY-MM-DD hh:mm:ss"
      )
      .clone()
      .tz(editableMarket?.timezone)
      .format("hh:mm A");

    if (newDate === "Invalid date") {
      startTimeinitial = moment(`00:00`, "hh:mm A").format("hh:mm A");
    } else {
      startTimeinitial = moment(`${newDate}`, "hh:mm A").format("hh:mm A");
    }

    let newDateEnd = moment
      .utc(
        `${date} ${editableMarket?.marketPreference?.shiftEndTime}`,
        "YYYY-MM-DD hh:mm:ss"
      )
      .clone()
      .tz(editableMarket?.timezone)
      .format("hh:mm A");

    if (newDateEnd === "Invalid date") {
      endTimeinitial = moment(`00:00`, "hh:mm A").format("hh:mm A");
    } else {
      endTimeinitial = moment(`${newDateEnd}`, "hh:mm A").format("hh:mm A");
    }
  }

  let newServiceZone = {
    latitude: undefined,
    longitude: undefined,
    miles: undefined,
    zipcode: undefined,
  };

  const initialValues = {
    cityName: editMode ? editableMarket?.address?.city : undefined,
    stateName: editMode ? editableMarket?.address?.state : undefined,
    countryName: editMode ? editableMarket?.address?.country : undefined,
    alias: editMode ? editableMarket?.alias : undefined,
    tax: editMode ? parseFloat(editableMarket?.tax) : undefined,
    latitude: editMode ? editableMarket?.address?.latitude : undefined,
    longitude: editMode ? editableMarket?.address?.longitude : undefined,
    timezone: editMode ? editableMarket?.timezone : undefined,
    servicesPerTimeslot: editMode
      ? editableMarket?.marketPreference?.servicesPerTimeslot
      : undefined,
    frequency: editMode
      ? editableMarket?.marketPreference?.frequency
      : undefined,
    cancellationFee: editMode
      ? editableMarket?.marketPreference?.cancellationFee
      : undefined,
    shiftStartTime: editMode
      ? editableMarket?.marketPreference?.shiftStartTime
        ? startTimeinitial
        : undefined
      : undefined,
    shiftEndTime: editMode
      ? editableMarket?.marketPreference?.shiftEndTime
        ? endTimeinitial
        : undefined
      : undefined,
    dispatch: editMode
      ? editableMarket?.marketPreference?.dispatch
        ? editableMarket?.marketPreference?.dispatch
        : undefined
      : undefined,
    bookingOffsetHours: editMode
      ? editableMarket?.marketPreference?.bookingOffsetHours === null
        ? undefined
        : editableMarket?.marketPreference?.bookingOffsetHours
      : undefined,
    serviceZones: editMode
      ? editableMarket?.serviceZones == null ||
        editableMarket?.serviceZones?.length == 0
        ? [newServiceZone]
        : editableMarket?.serviceZones
      : [newServiceZone],
    copy: undefined,
    reviewBooking: editMode?editableMarket?.marketPreference?.reviewBooking:2,
  };
  const handleClick = (index) => {
    if (index.length === 1) {
      message.error("Minimum 1 service zone is needed");
    }
  };
  // const handleCopy=(values,setFieldValue)=>{
  //   let data = copyMarkets.filter(item=> item.id === values?.copy);
  //   const newData = data[0]?.serviceZones;
  //   if(editMode){

  //   if(values?.serviceZones?.length > 0){
  //     if(newData.length >0){
  //       setErrorService(false);
  //     let serviceLength =values?.serviceZones;
  //     for(let i=0; i<=newData?.length-1; i++){
  //       let tempData={};
  //       let latitude = newData[i]?.latitude;
  //       let longitude =newData[i]?.longitude;
  //       let miles = newData[i]?.miles;
  //       let zipcode = newData[i]?.zipcode;
  //       tempData.latitude = latitude;
  //       tempData.longitude = longitude;
  //       tempData.miles =miles;
  //       tempData.zipcode =zipcode;
  //       serviceLength.push(tempData);
  //     }
  //     setFieldValue('serviceZones',serviceLength);
  //     if(values?.serviceZones?.length < 0){
  //         message.error("Minimum 1 service zone is needed");
  //         setErrorService(false);
  //     }
  //   }else{
  //     setErrorService(true);
  //   }}}else{
  //     if(newData.length >0){
  //       let newdataAdded=[];
  //     for(let i=0; i<=newData?.length-1; i++){
  //       let tempData={};
  //       let latitude = newData[i]?.latitude;
  //       let longitude =newData[i]?.longitude;
  //       let miles = newData[i]?.miles;
  //       let zipcode = newData[i]?.zipcode;
  //       tempData.latitude = latitude;
  //       tempData.longitude = longitude;
  //       tempData.miles =miles;
  //       tempData.zipcode =zipcode;
  //       newdataAdded.push(tempData);
  //     }
  //     setFieldValue('serviceZones',newData);
  //     setErrorService(false);
  //     }else{
  //       setErrorService(true);
  //     }
  //   }
  // }

  const onChangeSwitch=(e,setFieldValue)=>{
    setFieldValue("reviewBooking", e);
    setSwitchValue(e);
  }
  return (
    <div>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={getValidationSchema(editMode)}
          onSubmit={handleSubmit}
          onReset={handleResetCallBack}
        >
          {({ values, isSubmitting, setValues, setFieldValue }) => (
            <Form layout="vertical" hideRequiredMark noValidate>
              {!editMode && (
                <>
                  <Row gutter={8}>
                    <Col span={24}>
                      <Form.Item
                        name="cityName"
                        label="Search place"
                        style={{
                          marginBottom: "0",
                          paddingLeft: "1px",
                        }}
                      >
                        <Geosuggest
                          onSuggestSelect={(suggest) => {
                            onSuggestSelect(suggest, setValues, values);
                          }}
                          id="geosuggest-field"
                          name="cityName"
                          ref={_geoSuggest}
                          disabled={editMode ? true : false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <Text strong>City</Text>
                      <div>{values?.cityName}</div>
                    </Col>
                    <Col span={8}>
                      <Text strong>State</Text>
                      <div>{values?.stateName}</div>
                    </Col>
                    <Col span={8}>
                      <Text strong>Country</Text>
                      <div>{values?.countryName}</div>
                    </Col>
                  </Row>
                </>
              )}
              {editMode && (
                <Row>
                  <Col span={8}>
                    <Text strong>City</Text>
                    <div>{editableMarket?.city}</div>
                  </Col>
                  <Col span={8}>
                    <Text strong>State</Text>
                    <div>{editableMarket?.state}</div>
                  </Col>
                  <Col span={8}>
                    <Text strong>Country</Text>
                    <div>{editableMarket?.country}</div>
                  </Col>
                </Row>
              )}
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item
                    name="alias"
                    label="Market Display Name"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input name="alias" placeholder="Market Display Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item
                    name="tax"
                    label="Tax"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <InputNumber
                      name="tax"
                      data-testid="tax-field"
                      min={0}
                      max={99}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      // defaultValue={0}
                      step={1}
                      placeholder="Tax"
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item
                    name="timezone"
                    label="Timezone"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Select
                      showSearch
                      data-testid="timezone-field"
                      name="timezone"
                      style={{ width: "100%" }}
                      placeholder={"Select timezone"}
                      allowClear={true}
                      disabled={editMode ? true : false}
                    >
                      {TIME_ZONES_LIST}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item
                    name="servicesPerTimeslot"
                    label="Number of Services per Appointment Time Slot"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input
                      name="servicesPerTimeslot"
                      placeholder="Number of Services per Appointment Time Slot"
                      type="number"
                      min={0}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item
                    name="dispatch"
                    label="Dispatch Fee"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input
                      name="dispatch"
                      placeholder="Dispatch Fee"
                      type="number"
                      min="0"
                      step={0.1}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item
                    name="frequency"
                    label="Length of Appointment Time Slots (Minutes)"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input
                      name="frequency"
                      placeholder="Length of Appointment Time Slots (Minutes)"
                      type="number"
                      min="0"
                    />
                  </Form.Item>
                </Col>
              </Row>

             
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item
                    name="bookingOffsetHours"
                    label="Booking Lead time"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input
                      name="bookingOffsetHours"
                      placeholder="Booking Lead time"
                      type="number"
                      min="0"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    name="shiftStartTime"
                    label="Start Time"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <TimePicker
                      format="hh:mm A"
                      name="shiftStartTime"
                      value={startTime}
                      placeholder="Start Time"
                      style={{ width: "100%" }}
                      onChange={(time, timeString) =>
                        handleChangeTime(setFieldValue, timeString)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="shiftEndTime"
                    label="End Time"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <TimePicker
                      format="hh:mm A"
                      name="shiftEndTime"
                      placeholder="End Time"
                      style={{ width: "100%" }}
                      onChange={(time, timeString) =>
                        handleEndTime(setFieldValue, timeString)
                      }
                      value={endTime}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    name="cancellationOffsetTime"
                    label="Cancel Offset Time"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <TimePicker
                      format="hh:mm A"
                      name="cancellationOffsetTime"
                      value={cancellationOffsetTime}
                      placeholder="Time"
                      style={{ width: "100%", height: "39px" }}
                      onChange={(time, timeString) =>
                        handleChangeCancellationTime(setFieldValue, timeString)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="cancellationFee"
                    label="Cancellation Fee"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    <Input
                      name="cancellationFee"
                      placeholder="Cancellation Fee (USD)"
                      type="number"
                      min="0"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <Form.Item
                    name="reviewBooking"
                    label="Review Booking"
                    hasFeedback={true}
                    showValidateSuccess={false}
                  >
                      <>
                        <span style={{ paddingRight: "20px",color:switchValue?'#d9d9d9':'#000'}}>No Review to collect Payment</span>
                        <Switch
                          onChange={(e) => onChangeSwitch(e,setFieldValue)}
                          checked={switchValue}
                        />
                        <span style={{ paddingLeft: "20px", color:switchValue?'#000':'#d9d9d9' }}>
                          Review to collect Payment
                        </span>
                      </>
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row gutter={8}>
                <Col span={12}>
                <Form.Item
                    name="copy"
                    label="Markets"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                   <Select
									showSearch
									name="copy"
									style={{ width: '100%' }}
									placeholder={'Select markets to Copy Schedule'}
									allowClear={true}
									optionFilterProp="children"
									
									filterOption={(input, option) =>
										leftToFilterOptions(input, option)
									}
								>
									{copyMarkets &&
										copyMarkets.length > 0 &&
										copyMarkets.map(({ id, city,alias }) => (
											<Option key={id} value={id}>
												{alias? alias:'NA'}
											</Option>
										))}
								</Select>
                  </Form.Item>
                </Col>
                <Col span={12} style={{marginTop:"32px"}}>
                <Button onClick={()=>handleCopy(values,setFieldValue)}>Copy Service</Button>
                </Col>
                {errorService && <p style={{color:'red'}}>Market does not have service</p>}
              </Row> */}
              {/* start service zone */}
              <Row gutter={8}>
                <Col span={24}>
                  <FieldArray
                    name="serviceZones"
                    render={(arrayHelpers) => (
                      <div>
                        {values?.serviceZones &&
                          values?.serviceZones?.map((data, index) => (
                            <div key={index}>
                              <Card
                                title="Service Zones"
                                extra={
                                  <span
                                    onClick={() => {
                                      handleClick(values?.serviceZones);
                                      return arrayHelpers.remove(index);
                                    }}
                                  >
                                    <CloseOutlined />
                                  </span>
                                }
                                style={{
                                  marginBottom: "25px",
                                }}
                              >
                                <Row gutter={8}>
                                  <Col span={12}>
                                    <Form.Item
                                      name={`serviceZones[${index}].latitude`}
                                      label="Latitude"
                                      hasFeedback={true}
                                      showValidateSuccess={true}
                                    >
                                      <Input
                                        name={`serviceZones[${index}].latitude`}
                                        placeholder="Latitude"
                                        type="number"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      name={`serviceZones[${index}].longitude`}
                                      label="Longitude"
                                      hasFeedback={true}
                                      showValidateSuccess={true}
                                    >
                                      <Input
                                        name={`serviceZones[${index}].longitude`}
                                        placeholder="Longitude"
                                        type="number"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={8}>
                                  <Col span={12}>
                                    <Form.Item
                                      name={`serviceZones[${index}].zipcode`}
                                      label="Zip Code"
                                      hasFeedback={true}
                                      showValidateSuccess={true}
                                    >
                                      <Input
                                        name={`serviceZones[${index}].zipcode`}
                                        placeholder="Zip Code"
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      name={`serviceZones[${index}].miles`}
                                      label="Distance"
                                      hasFeedback={true}
                                      showValidateSuccess={true}
                                    >
                                      <Input
                                        name={`serviceZones[${index}].miles`}
                                        placeholder="Distance (in miles)"
                                        type="number"
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Card>
                            </div>
                          ))}
                        <Row gutter={8} style={{ marginBottom: "40px" }}>
                          <Col span={24}>
                            <Button
                              type="primary"
                              style={{
                                width: "100%",
                              }}
                              onClick={() => arrayHelpers.push(newServiceZone)}
                            >
                              <PlusOutlined /> Add Service Zones
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  />
                </Col>
              </Row>

              {/*end service zone  */}
              <FormActionButtons
                onClose={onClose}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

MarketForm.propTypes = {
  editMode: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { MarketForm };
