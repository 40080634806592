import moment from 'moment';

export function timeLineChange(dayType = 'week') {
	/**
	 * dayType = year, month, week, day
	 */
	let startDate, endDate;

	startDate = moment()
		.startOf(dayType)
		.format('YYYY-MM-DD');
	endDate = moment()
		.endOf(dayType)
		.format('YYYY-MM-DD');

	return { startDate, endDate };
}
