import React, { useEffect, useState, lazy, Suspense } from "react";
import { PageHeader, Row, Col, Form, Radio, Select, Typography } from "antd";
import { CUJobs } from "../components/pages/jobs";
import JobsTimeline from "components/pages/jobs/JobsTimeline";
import { useSelector, useDispatch } from "react-redux";
import {
  getMarkets,
  getJobs,
  getSelectedJobs,
  getSelectedJobsBasedOnTime,
  getTechnicians,
  getServices,
  getConsumers,
  getAddress,
  getCompanyName
} from "services/common";
import {
  jobsStatusTypes,
  findJobStatusTotal,
} from "components/pages/jobs/DTController";
import { getParamsFromUrl, pushParamsToUrl } from "helpers";
import { timeStamp } from "helpers/date-formatter";
import { useHistory, useLocation } from "react-router-dom";

const DTJobs = lazy(() => import("components/pages/jobs/DTJobs"));
const { Option,OptGroup } = Select;
const { Title } = Typography;

function Jobs() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = getParamsFromUrl(history);
  const [jobsStatusType, setJobsStatusType] = useState();
  const [technicians, setTechnicians] = useState([]);
  const [slectedMarket, setSelectedMarket] = useState(0);
  const [slectedCatagory, setSelectedCatagory] = useState("Select Catagory");
  const [selectedService, setSelectedService] = useState("Select Service");
  const [DateSevice, setDataSevice] = useState([]);
  const [selectedCompanyName,setSelectedCompanyName]=useState('Select Company Name');
  const [selectedConsumerName,setSelectedConsumerName]=useState('Select Consumer Name');
  const [selectedLocation,setSelectedLocation]=useState('Select Sevice Location');

  const [selectedFieldTechnician, setSelectedFieldTechnician] = useState(
    "Select Field Technician"
  );
  

  let {
    markets = [],
    productsCategories = [],
    bookings = [],
    JobBasedOnTime = [],
    Services = []
  } = useSelector((state) => state?.model);
  let { lastUpdatedTime, saveJobform } = useSelector((state) => state?.job);
  let { consumers = [],address=[],companyName=[] } = useSelector((state) => state?.model);

  function compare( a, b ) {
			if ( a.name.toLowerCase() < b.name.toLowerCase() ){
			  return -1;
			}
			if ( a.name.toLowerCase() > b.name.toLowerCase() ){
			  return 1;
			}
			return 0;
		  }
		  
		  let newdata =Services.sort( compare );
      Services = newdata;

  useEffect(() => {
    dispatch(getTechnicians({ all: true }))
      .then((res) => setTechnicians(res?.data?.technicians))
      .catch((err) => console.log(err));
    dispatch(getMarkets({ all: true }));
    dispatch(getServices({ all: true, active:[0,1] }));
    localStorage.setItem('data',history.location.search);
    setDataSevice(Services);
    dispatch(getConsumers());
    dispatch(getAddress());
    dispatch(getCompanyName());
  }, []);

  useEffect(() => {
    let status;
    const params = getParamsFromUrl(history);
    const queryParams = { ...params };
    delete queryParams?.timeLine;
    delete queryParams?.statuses;
    queryParams.all = true;
    dispatch(getSelectedJobsBasedOnTime(queryParams)).then((res) => {
      const data = res?.data?.bookings?.filter(
        (b) => params?.statuses?.length > 0 && params?.statuses[0] === b?.status
      );
      
      const temp = res?.data?.bookings;
      const selectedJob = jobsStatusTypes.filter(
        (job) => params?.statuses?.length > 0 && job?.key == params?.statuses[0]
      );
      const tempKey = selectedJob[0]?.key;
      const tempStatus =temp?.filter(s=> s?.status === tempKey);
      
      
      if (temp > 0) {
        const selectedJob = jobsStatusTypes?.filter(
          (job) => job?.key == [temp[0]?.status]
        );
      
        let selectedJobKey = selectedJob[0]?.key;
        let selectedJobId = selectedJob[0]?.id;
    
        setJobsStatusType(selectedJobId);
        dispatch({
          type: "JOBS_KEY",
          payload: selectedJobKey,
        });
        pushParamsToUrl(history, {
          ...params,
          statuses: [selectedJobKey]
        });
      }else {
        const selectedJob = jobsStatusTypes.filter(
          (job) => params?.statuses?.length > 0 && job?.key == params?.statuses[0]
        );
        // if(tempStatus?.length === 0 && temp.length > 0){
        //   let newStatus =temp[0]?.status;
        //   const selectedJob = jobsStatusTypes.filter(
        //     (job) => job?.key == newStatus
        //   );
        //   let newid = selectedJob[0]?.id;
        //   setJobsStatusType(newid);
        
        // pushParamsToUrl(history, {
        //   ...params,
        //   statuses: [newStatus],
        // });
        // }
        let selectedJobId = selectedJob[0]?.id;
      
        setJobsStatusType(selectedJobId);
        
        dispatch({
          type: "CLEAR_JOBS_KEY",
          payload: {},
        });
      }
    });
  }, [location,saveJobform,lastUpdatedTime]);

  

  function handleCatagoryChange(data) {
    pushParamsToUrl(history, {
      ...params,
      categories: data ? [data] : "",
      services:'',
      consumers:''
    });

    setSelectedCatagory(data);
    let serviceData;
    if(data){
    serviceData = Services?.filter(item=> data == item.category.id);
    }else{
      serviceData=Services;
    }
	setDataSevice(serviceData);
  setSelectedService('Select Service');
  setSelectedConsumerName('Select Consumer Name');
  }

  function handleTechnicianChange(data) {
    pushParamsToUrl(history, {
      ...params,
      technicians: data ? [data] : "",
      services:'',
      consumers:''
    });
    setSelectedFieldTechnician(data);
    setSelectedService('Select Service');
    setSelectedConsumerName('Select Consumer Name');

  }
  const handleMarketChange = (e) => {
    setSelectedMarket(e);
    setSelectedCatagory("Select Catagory");
    setSelectedFieldTechnician("Select Field Technician");
    const dataTech = [];
    dispatch(getTechnicians({ all: true }))
      .then((res) => {
        res.data.technicians.forEach((response) => {
          response.markets.filter((mkt) => {
            if (e == mkt.id) {
              dataTech.push(response);
            }
          });
        });
        if (e == 0) {
          setTechnicians(res.data.technicians);
        } else {
          setTechnicians(dataTech);
        }
      }).catch((err) => console.log(err));

      pushParamsToUrl(history,{
        ...params,
        markets:e? [e]:'',
        categories:'',
        technicians:'',
        services:'',
        consumers:''
      })

    // if (e != 0) {
    //   pushParamsToUrl(history, {
    //     ...params,
    //     markets: e ? [e] : "",
    //     categories: "",
    //     technicians: "",
    //   });
    // } else {
    //   pushParamsToUrl(history, {
    //     ...params,
    //     markets: "",
    //     categories: "",
    //     technicians: "",
    //   });
    // }
  }

  function handleJobsTypesStatusChange(data) {
    
    /**
     * If the value in jobsType button changes,
     * update that data and push that data to the url
     */
    let jobsType = data.target.value;
    setJobsStatusType(jobsType);
    const selectedJob = jobsStatusTypes.filter((job) => job.id == jobsType);
    let selectedJobKey = selectedJob[0].key;
    if(selectedJobKey == "IN_PROGRESS"){
      pushParamsToUrl(history, {
        ...params,
        statuses: ['IN_PROGRESS','CHECKOUT'],
      });
    }
    else{
      console.log(selectedJobKey)
      pushParamsToUrl(history, {
        ...params,
        statuses: [selectedJobKey],
      });
    }

    dispatch({ type: "SELECTED_JOB_KEY", payload: selectedJobKey });
  }
 
  function handleServiceChange(data) {
    pushParamsToUrl(history, {
      ...params,
      services: data ? [data] : "",
      consumers:''
    });
   
    setSelectedService(data);
    setSelectedConsumerName('Select Consumer Name');
  }

  function handleCompanyChange(data) {
    pushParamsToUrl(history, {
      ...params,
      companyNames: data ? [data] : "",
      consumers:""
    });
    
    setSelectedCompanyName(data);
  }
  
  function handleConsumerChange(data) {
    pushParamsToUrl(history, {
      ...params,
      consumers: data ? [data] : "",
    });
   
    setSelectedConsumerName(data);
  }
  
  function handleServiceLocation(data){
    pushParamsToUrl(history, {
      ...params,
      fullAddresses: data ? [data] : "",
    });
    setSelectedLocation(data);
  }
  
  return (
    <div>
      <div className="p-20 white-bc title-box-shadow">
        <JobsTimeline />
        <Row type="flex" align="middle">
          <Col span={5}>
            <Form.Item label="Markets" className={"search-label"}>
              <Select
                showSearch
                name="market"
                style={{ width: "100%" }}
                value={slectedMarket}
                placeholder={"Select Market"}
                allowClear={true}
                optionFilterProp="children"
                onChange={(e) => handleMarketChange(e)}
              >
                <Option key={0} value={0}>
                  All
                </Option>
                {markets.map(({ id, city,alias }) => (
                  <Option key={id} value={id}>
                    {alias? alias:'NA'}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5} className="ml-20">
            <Form.Item label="Category" className={"search-label"}>
              <Select
                showSearch
                optionFilterProp="children"
                name="category"
                style={{ width: "100%" }}
                placeholder={"Select Category"}
                value={slectedCatagory}
                allowClear={true}
                
                onChange={(e) => handleCatagoryChange(e)}
              >
                {productsCategories.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        
          <Col span={6} className="ml-20">
          <Form.Item label="Service Primary/Addons" className={"search-label"}>
          <Select
                  showSearch
                  optionFilterProp="children"
                  name="services"
                  allowClear={true}
                  style={{ width: "100%" }}
                  placeholder="Select Service"
                  value={selectedService}
                  onChange={(e) => handleServiceChange(e)}
                >
                  <OptGroup label="Primary Service">
                  {DateSevice?.filter(item=> item.type === 1).map((item) => (
                    <Option key={item?.id}>{item?.name}</Option>
                  ))}
                  </OptGroup>
                  <OptGroup label="Add on Service">
                  {DateSevice?.filter(item=> item.type === 2).map((item) => (
                    <Option key={item?.id}>{item?.name}</Option>
                  ))}
                  </OptGroup>
                  
                </Select>
          </Form.Item>
          </Col>
          <Col span={6} className="ml-20">
            <Form.Item label="Field Technician" className={"search-label"}>
              <Select
                showSearch
                name="team_members"
                style={{ width: "100%" }}
                placeholder={"Select Field Technician"}
                allowClear={true}
                value={selectedFieldTechnician}
                optionFilterProp="children"
                onChange={(e) => handleTechnicianChange(e)}
              >
                {technicians.map(({ id, firstName, lastName }) => (
                  <Option key={id} value={id}>
                    {`${lastName}, ${firstName}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" align="middle">
        <Col span={5}>
          <Form.Item label="Company Name" className={"search-label"}>
          <Select
                  showSearch
                  optionFilterProp="children"
                  name="company_name"
                  allowClear={true}
                  style={{ width: "100%" }}
                  placeholder="Company Name"
                  value={selectedCompanyName}
                  onChange={(e) => handleCompanyChange(e)}
                >
                   {companyName?.map((item,index)=>(
                      <Option key={item.companyName}>{item.companyName}</Option>
                  ))}
                </Select>
          </Form.Item>
          </Col>
          <Col span={5} className="ml-20">
          <Form.Item label="Consumer Name" className={"search-label"}>
          <Select
                  showSearch
                  optionFilterProp="children"
                  name="consumer_name"
                  allowClear={true}
                  style={{ width: "100%" }}
                  placeholder="Consumer Name"
                  value={selectedConsumerName}
                  onChange={(e) => handleConsumerChange(e)}
                >
                  {consumers?.map(({id,firstName,lastName})=>(
                      <Option key={id}>{lastName !==null ? lastName:"NA"}, {firstName !==null? firstName:"NA"}</Option>
                  ))}
                </Select>
          </Form.Item>
          </Col>
          <Col span={6} className="ml-20">
          <Form.Item label="Service Location" className={"search-label"}>
          <Select 
          showSearch
          optionFilterProp="children"
          value={selectedLocation}  allowClear={true}
                  style={{ width: "100%" }}
                  placeholder="Select Sevice Location"
                  onChange={(e)=>handleServiceLocation(e)}
                  >
                  {address.map(({full})=>(
                    <Option key={full}>{full}</Option>
                  ))}
          </Select>
          </Form.Item>
          </Col>
        </Row>
        {/* Jobstypes total section starts */}
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <Radio.Group
              value={jobsStatusType}
              onChange={handleJobsTypesStatusChange}
            >
              {jobsStatusTypes.map((type) =>
                findJobStatusTotal(type.key, JobBasedOnTime) > 0 ? (
                  <Radio.Button value={type.id} key={type.id} className="mb-10">
                    <div>
                      <span>{type.name} - </span>
                      <span>
                        {findJobStatusTotal(type.key, JobBasedOnTime)}
                      </span>
                    </div>
                  </Radio.Button>
                ) : null
              )}
            </Radio.Group>
          </Col>
        </Row>
        {/* Jobstypes total section ends */}
      </div>

      <div className="p-20 pt-0">
        <CUJobs jobsStatusType={jobsStatusType} />
        <Suspense fallback={<p>Loading...</p>}>
          <DTJobs jobsStatusType={jobsStatusType} />
        </Suspense>
      </div>
    </div>
  );
}

export default Jobs;
