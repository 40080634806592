import React, { useState, lazy, Suspense } from "react";
import {
  ToolOutlined,
  UserOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { PageHeader, Col, Row, Button } from "antd";
import { CUUser } from "../components/pages/users";
import { CUTechnician } from "../components/pages/technician";
const DTUser = lazy(() => import("components/pages/users/DTUser"));
const DTTechnician = lazy(() =>import("components/pages/technician/DTTechnician"));
const DTCosumers = lazy(() => import("components/pages/consumers/DTConsumers"));

function Users() {
    //calling different user types (Admin, consumer, field Technician)
  const [userType, setUserType] = useState("user");
  const [sel_btn, setSelBtn] = useState("selected_btn_active");
  const [tech_btn, settechBtn] = useState("sel_default");
  const [cosumer_btn, setconsumerBtn] = useState("sel_default");

  function changeUser(data) {
    if (data === "user") {
      setUserType("user");
      settechBtn("sel_default");
      setconsumerBtn("sel_default");
    }
    if (data === "technician") {
      setUserType("technician");
      setSelBtn("sel_default");
      setconsumerBtn("sel_default");
      settechBtn("selected_btn_active");
    }
    if (data === "consumer") {
      setUserType("consumer");
      setSelBtn("sel_default");
      setconsumerBtn("selected_btn_active");
      settechBtn("sel_default");
    }
  }

  return (
    <div>
      <div className="p-20 white-bc title-box-shadow">
        <PageHeader className="mb-10" title="Users" />
      </div>
      <Row gutter={8} style={{ margin: "20px 0 0 20px" }}>
        <Col>
          <Button
            type="default"
            onClick={() => changeUser("user")}
            className={sel_btn}
          >
            <UserOutlined /> Admins
          </Button>
        </Col>
        <Col>
          <Button
            type="default"
            onClick={() => changeUser("technician")}
            className={tech_btn}
          >
            <ToolOutlined /> Field Technicians
          </Button>
        </Col>
        <Col>
          <Button
            type="default"
            onClick={() => changeUser("consumer")}
            className={cosumer_btn}
          >
            <TeamOutlined /> Consumers
          </Button>
        </Col>
      </Row>
      {userType === "user" && (
        <div className="p-20 pt-0">
          <CUUser />
          <Suspense fallback={<p>Loading...</p>}>
            <DTUser />
          </Suspense>
        </div>
      )}
      {userType === "technician" && (
        <div className="p-20 pt-0">
          <CUTechnician />
          <Suspense fallback={<p>Loading...</p>}>
            <DTTechnician />
          </Suspense>
        </div>
      )}
      {userType === "consumer" && (
        <div className="p-20 pt-0">
          <Suspense fallback={<p>Loading...</p>}>
            <DTCosumers />
          </Suspense>
        </div>
      )}
    </div>
  );
}

export default Users;
