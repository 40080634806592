import {
  PieChartOutlined,
  PoweroffOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { ChangePassword, CustomIcon } from "components/common";
import moment from "moment";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import { API } from "services";
import { redirectToLogin } from "services/authentication";
import { session } from "services/session";
import logo_main from "assets/logo_main.svg";
import CouponIcon from "assets/images/coupon_icon.svg";
import CouponIconWhite from "assets/images/coupon_icon_white.svg";
import { getParamsFromUrl, pushParamsToUrl } from "helpers";

const { Sider } = Layout;
const { SubMenu } = Menu;

let from = moment().startOf("week").format("YYYY-MM-DD 00:00");
let to = moment().endOf("week").format("YYYY-MM-DD 00:00");

const PARAMS = {
  timeLine: "week",
  startDate: from,
  endDate: to,
  status: 3, // Default status is completed jobs
};

const QUERY_PARAMS = qs.stringify(PARAMS, { addQueryPrefix: true });

const Sidebar = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [key, setKey] = useState(2);
  const history = useHistory();
  const dispatch = useDispatch();
  const { previousVisitedUrl } = useSelector((state) => state.history);
  const [changeImage,setImage]=useState('first');
  const params = getParamsFromUrl(history);

  
  if(history.location.pathname == "/jobs" && history.location.search == "") {
    console.log(localStorage.getItem('data'));
    let data = `?timeLine=week&startDate=&endDate=`
      history.location.search=localStorage.getItem('data');
  }

  useEffect(() => {
    switch (props.location.pathname) {
      case "/profile":
        return setKey("1");
      case "/jobs":
        return setKey("2");
      case "/users":
        return setKey("3");
      case "/markets":
        return setKey("0");
      case "/markets/timeslots":
        return setKey("0");
      case "/markets/schedule":
          return setKey("0");
      case "/services":
        return setKey("4");
      case "/pricings":
        return setKey("5");
        case "/discount":
          return setKey("26");
      default:
        return setKey("3");
    }
  }, [props.location.pathname]);

  useEffect(() => {
    if (
      history.location.pathname === "/profile" &&
      history.location.pathname !== previousVisitedUrl
    ) {
    }
    dispatch({
      type: "SET_PREVIOUS_URL",
      payload: history.location.pathname,
    });
  }, [props.location.pathname]);

  function onCollapse(colspse) {
    setCollapsed(colspse);
  }

  const handleLogout = () => {
    const CREDENTIALS = {
      url: `/users/logout`,
      method: "post",
    };

    API.common(CREDENTIALS).finally(() => {
      redirectToLogin();
    });
  };

  const handleMouseOver=()=>{
    setImage('second');
  }

  const handleMouseOut=()=>{
    setImage('first');
  }
  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <div className="logo text-center py-10">
        <img src={logo_main} alt="dfs_logo" width="80%"/>
      </div>
      {/* Menus */}
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[key]} selectedKeys={[key]}>
        <Menu.Item key="0" icon={<CustomIcon type="icon-icon_market" />}>
          <NavLink to="/markets" />
          <span>Markets</span>
        </Menu.Item>

        <Menu.Item key="4" icon={<CustomIcon type="icon-icon_service" />}>
          <NavLink to="/services" />
          <span>Services</span>
        </Menu.Item>

        <Menu.Item key="5" icon={<CustomIcon type="icon-icon_service" />}>
          <NavLink to="/pricings" />
          <span>Pricings</span>
        </Menu.Item>

        <Menu.Item key="3" icon={<CustomIcon type="icon-icon_user" />}>
          <NavLink to="/users" />
          <span>Users</span>
        </Menu.Item>

        <Menu.Item key="2" icon={<CustomIcon type="icon-icon_jobs" />}>
          <NavLink to="/jobs" />
          <span>Jobs</span>
        </Menu.Item>
        <Menu.Item key="26" icon={<img src={changeImage ==="first"? CouponIcon:CouponIconWhite}/>}  onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <NavLink to="/discount"/>
          <span>Discount</span>
        </Menu.Item>

        {/* Sub Menu */}
        <SubMenu key="sub5" title={<span> <UserOutlined /> <span>Profile</span></span>}
        >
          <Menu.Item key="1" icon={<CustomIcon type="icon-icon_product" />}>
            <NavLink to="/profile" />
            <span>Profile</span>
          </Menu.Item>

          <Menu.Item key="15">
            <ChangePassword />
          </Menu.Item>

          <Menu.Item key="16" onClick={handleLogout}>
            <PoweroffOutlined />
            <span>Log Out</span>
          </Menu.Item>

        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default withRouter(Sidebar);
