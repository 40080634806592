import React,{useState,useEffect} from 'react'
import { Form, Input, Select } from 'formik-antd'
import {  Col, Row,message } from 'antd'
import { Formik } from 'formik'
import { FormActionButtons } from '../FormActionButtons'
import {leftToFilterOptions} from 'helpers';
import * as Yup from 'yup'
import {API} from "services";

export function UsersForm({onClose,editMode,editableUser}) {
	let data = JSON.parse(localStorage.getItem('userInfoData'));
	let adminIdTemp = data?.id;
	
	const [showMarkets,setShowMarkets]= useState(1);
	let adminUserType = data?.userType?.id;
	let adminMarket = {};
	
	if(adminUserType === 4){
		adminMarket = data?.markets;
	}
	const [markets,setMarkets]=useState([]);
	const initialValues={
		firstName:
			editMode?editableUser.firstName
				: undefined,
		lastName:
			editMode? editableUser.lastName
				: undefined,
		email: editMode ? editableUser.email : undefined,
		countryCode: editMode ? Number(editableUser.countryCode): 1,
		phone: editMode ? editableUser.phone : undefined,
		markets:editMode && editableUser?.markets?.length > 0 ? editableUser.markets.filter(item=> item.active ===1).map((obj) => obj.market.id): undefined,
		userType:editMode && editableUser ? editableUser.userType.id:adminUserType === 4?4:undefined,
	}

	
	const UserSchema= Yup.object({
		firstName: Yup.string()
			.matches(/^[a-zA-Z ]*$/, 'Is not in correct format')
			.required('First Name required'),
		lastName: Yup.string()
			.matches(/^[a-zA-Z ]*$/, 'Is not in correct format')
			.required('Last name required'),
		email: Yup.string()
			.required('Email required')
			.email('Invalid email format'),
		countryCode: Yup.number().required('Country code required'),
		userType:Yup.string().required("User Type is required").nullable(),
		markets: Yup.string().when('userType',{
			is:(userType)=> userType == 4? true:false,
			then: Yup.string().required('Markets are required').nullable(),
		}),
		phone: Yup.string()
			.required('Phone number required')
			.matches(/^[1-9]\d{9}$/, {
				message: 'Please enter valid phone number.',
				excludeEmptyString: false,
			}),
		})
    const { Option } = Select;

	useEffect(()=>{
		const CREDENTIALS = {
			url: `/markets`,
			method: 'get',
      queryParams:{
          all:true
      }
		};

		API.common(CREDENTIALS)
			.then((response) => {
				if(adminUserType === 4){
					data = response?.data?.markets?.filter(item=> adminMarket?.includes(item?.id));
				   }else{
					data = response?.data?.markets;
				   }
				   
					setMarkets(data);
			}).catch(err=>console.log(err))
	},[])


	useEffect(()=>{
		if(editMode){
		setShowMarkets(editableUser?.userType?.id);
		}
		if(adminUserType ===4 && !editMode){
			setShowMarkets(4);
		}
	},[editMode])
	

	const handleSubmit=(values,{ setErrors, resetForm, setSubmitting})=>{
		const valuesCopy ={...values};
		valuesCopy.phone = String(valuesCopy.phone);
		valuesCopy.technicianCode = String(valuesCopy.technicianCode);
		valuesCopy.countryCode = String(valuesCopy.countryCode);
		if(valuesCopy?.userType === 4){
			valuesCopy.markets = values.markets;
			}else{
			valuesCopy.markets = markets?.map(item=> item?.id);
			}

		delete valuesCopy.market;
		
		if(editMode){
			let data=[];
			valuesCopy.id = editableUser.id;
			let oldData = editableUser?.markets?.map(item=> item.market.id);
			if(valuesCopy?.userType === 4){
				let tempMarkets = values?.markets;
				valuesCopy.markets = values.markets.map(item=> data.push({market:item, active:1}));
				let TempData = oldData.filter(item=> !tempMarkets.includes(item))
				valuesCopy.markets = TempData.map(item=>data.push({market:item, active:0}));
				}else{
				valuesCopy.markets = markets?.map(item=> data.push({market:item, active:1}));
				}

				const CREDENTIALS1 = {
					url: `/users/${valuesCopy?.id}/markets`,
					method: 'put',
					data: data,
					setErrors
				};
		
				API.common(CREDENTIALS1)
					.then((response) => {
					
					})
					.finally(() => {
						setSubmitting(false);
					});
		
		}
			
		if(editMode){
			delete valuesCopy?.markets;
		}

		
			const CREDENTIALS = {
				url: `/users`,
				method: editMode?'put':'post',
				data: valuesCopy,
				setErrors
			};
	
			API.common(CREDENTIALS)
				.then((response) => {
				showSuccessMessage(valuesCopy);
				resetForm();
				onClose();
				})
				.finally(() => {
					setSubmitting(false);
				});
		
	}

	function showSuccessMessage(values) {
		let text = editMode ? 'updated' : 'added';
		message.success(`${values.firstName} employee ${text} successfully`);
	}
    return (
            <Formik
			initialValues={initialValues}
			validationSchema={UserSchema}
			onSubmit={handleSubmit}
		>
			{({ values, isSubmitting, setFieldValue,setFieldTouched }) => (
				<Form layout="vertical" hideRequiredMark>
					<Row gutter={8}>
						<Col span={12}>
							<Form.Item
								name="firstName"
								label="First name"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Input
									name="firstName"
									placeholder="First name"
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="lastName"
								label="Last name"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Input
									name="lastName"
									placeholder="Last name"
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={8}>
						{editMode &&(
						<Col span={12}>
							<Form.Item
								name="email"
								label="Email"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Input name="email" placeholder="Email" disabled={true}/>
							</Form.Item>
						</Col>
						)}
						{!editMode &&(
						<Col span={12}>
							<Form.Item
								name="email"
								label="Email"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Input name="email" placeholder="Email" />
							</Form.Item>
						</Col>
						)}
						<Col span={12}>
							<Form.Item
								name="phone"
								label="Phone number"
								hasFeedback={true}
								showValidateSuccess={true}
							>
								<Input
									addonBefore={
										<Select
											showSearch
											name="countryCode"
											style={{ width: 120 }}
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.props.children
													.toLowerCase()
													.indexOf(
														input.toLowerCase()
													) >= 0
											}
										>
											<Option key={1} value={1}>
												+1 - USA
											</Option>
											<Option key={91} value={91}>
												+91 - India
											</Option>
										</Select>
									}
									type="number"
									step="1"
									name="phone"
									placeholder="Phone number"
									style={{ width: '100%' }}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
						{!editMode ?(
					<Col span={12} >
							<Form.Item
								name="userType"
								label="User Type"
								hasFeedback={true}
								showValidateSuccess={true}
							>
										<Select
											name="userType"
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.props.children
													.toLowerCase()
													.indexOf(
														input.toLowerCase()
													) >= 0
											}
											placeholder={"User Type"}
											allowClear={true}
											onChange={(e)=> setShowMarkets(e)}
											disabled={adminUserType === 4? true:false}
										>
											<Option key={1} value={1}>
												Admin
											</Option>
											<Option key={4} value={4}>
												Market Manager
											</Option>
										</Select>
							</Form.Item>
						</Col>):(<Col span={12} >
							<Form.Item
								name="userType"
								label="User Type"
								hasFeedback={true}
								showValidateSuccess={true}
							>
										<Select
											name="userType"
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.props.children
													.toLowerCase()
													.indexOf(
														input.toLowerCase()
													) >= 0
											}
											placeholder={"User Type"}
											allowClear={true}
											onChange={(e)=> setShowMarkets(e)}
											disabled={adminUserType === 4? true:adminIdTemp === editableUser.id?true:false}
										>
											<Option key={1} value={1}>
												Admin
											</Option>
											<Option key={4} value={4}>
												Market Manager
											</Option>
										</Select>
							</Form.Item>
						</Col>)}
						{showMarkets === 4 &&(
              <Col span={12}>
                <Form.Item
                  name="markets"
                  label="Market"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    showSearch
                    name="markets"
                    placeholder={"Select market"}
                    allowClear={true}
					mode="multiple"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={adminUserType === 4 && editMode?true:false}
                  >
                    {markets &&
                      markets?.length > 0 &&
                      markets?.map(({ id, city, alias }) => (
                        <Option key={id} value={id}>
                          {alias ? alias : "NA"}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
			  )}
			 
            </Row>
			
				
					<FormActionButtons
						onClose={onClose}
						isSubmitting={isSubmitting}
					/>
				</Form>
			)}
		</Formik>
    )
}


