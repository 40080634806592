import store from 'store/configureStore';

export function handle401Error(err) {
	console.log(err);
	/**
	 * get user data from local storage
	 */
	const userInfoData = localStorage.getItem('userInfoData');
	const UserData = JSON.parse(userInfoData);
	const token = UserData.refreshToken;
	const url = `${process.env.REACT_APP_API_URL_V1}/users/tokens/refresh`;
	let status;
	fetch(url, {
		method: 'GET',
		headers: {
			Authorization: `bearer ${token}`,
		},
	})
		.then((response) => {
			status = response.status;
			return response.json();
		})
		.then((response) => {
			if(status ==200){
			updateTokens(UserData, response);
			}else{
			redirectToLogin();
			}
		})
		.catch((error) => {
			redirectToLogin();
			console.log('refresh-token error', error);
		});
}

export function redirectToLogin() {
	/**
	 * Refresh token session time also expired, so clear the local storage
	 * then redirect the user to login page
	 */

	store.dispatch({
		type: 'LOGOUT',
	});
	window.location = process.env.REACT_APP_LOGIN_URL;
}

function updateTokens(UserData, response) {
	/**
	 * if the refreshToken has valid time it will send the
	 * new tokens, update that tokens to current user data
	 */
	 UserData.authToken = response?.data?.user?.authToken;
	 UserData.refreshToken = response?.data?.user?.refreshToken;
	localStorage.setItem('userInfoData', JSON.stringify(UserData));
	/**
	 * once new tokens are updated refresh the current route
	 */
window.location.reload(true);
}
