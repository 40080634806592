import React,{useState,useEffect} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useDispatch,useSelector } from 'react-redux';

// const images = [
//   'https://images.unsplash.com/photo-1533630217389-3a5e4dff5683?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
//   'https://images.unsplash.com/photo-1621639532859-8b26570b96fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80',
//   'https://images.unsplash.com/photo-1549064233-945d7063292f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
//   'https://images.unsplash.com/photo-1547731269-e4073e054f12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
//   'https://images.unsplash.com/photo-1592570714618-15e2d4719c6c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80',
//   'https://images.unsplash.com/photo-1625690180114-5530b1304127?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
// ];

const LightboxImages=({photos})=> {
  const [images, setImages]= useState([]);
useEffect(()=>{
  let newData =[];
  if(photos?.length >0){
  photos.map(item=> newData.push(`https://smallfleet-np-images.s3.us-east-1.amazonaws.com/${item?.key}`));
  }
  setImages(newData);
},[])
  const dispatch = useDispatch();
  const { isOpen,index } = useSelector((state) => state.job);
  console.log(index);
    return (
      <div>
        {/* <button type="button" onClick={() => dispatch({type:"OPEN_LIGHTBOX", payload:true})}>
          Open Lightbox
        </button> */}

        {isOpen && (
          <Lightbox
            mainSrc={images[index]}
            nextSrc={images[(index + 1) % images.length]}
            prevSrc={images[(index + images.length - 1) % images.length]}
           onCloseRequest={() => dispatch({type:"OPEN_LIGHTBOX", payload:false})}
            onMovePrevRequest={() =>dispatch({type:"CHANGE_INDEX", payload:(index + images.length - 1) % images.length})}
            onMoveNextRequest={() =>dispatch({type:"CHANGE_INDEX", payload:(index + 1) % images.length})}
          />
        )}
      </div>
    );
  }

  export default LightboxImages;
