import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "services/common";
import { useQueryParams } from "hooks";
import { API } from "services";


export default function DTUser() {
  
  const dispatch = useDispatch();
  const { userStatus } = useQueryParams();
  const [loading, setLoading] = useState(false);
  const { lastUpdatedTime } = useSelector((state) => state.users);
  const { users = [] } = useSelector((state) => state?.model);
  const [markets, setMarkets] = useState([]);
  const [pagination, setPagination] = useState({
    all: true
  });

  useEffect(() => {
    //refresh table compnent if any data change or new data is added
    handleTableChange();
    const CREDENTIALS = {
      url: `/markets`,
      method: "get",
      queryParams: {
        all: true,

      },
    };

    API.common(CREDENTIALS)
      .then((response) => {
        let data = [];
        data = response?.data?.markets;
        setMarkets(data);
      })
      .catch((err) => console.log(err));
  }, [lastUpdatedTime, userStatus]);

  const handleTableChange = (pagi) => {
    let status = userStatus === "false" ? 0 : 1;

    let newFilters = {
      ...pagination,
      status,
    };

    if (pagi) {
      newFilters = {
        ...newFilters,
      };
    }
    
    setLoading(true);
    dispatch(getUsers(newFilters))
      .then((response) => {
        setPagination({
          ...pagination,
          total: response?.meta?.pagination?.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
//gets the data for edit form from table row
  function handleEdit(data) {
    dispatch({
      type: "SHOW_USER_FORM",
      payload: data,
    });
  }

  //show the phone number with country code
  function getPhoneNumber(data) {
    let code =
      data?.countryCode?.indexOf("+") === 0
        ? data.countryCode
        : "+" + data.countryCode;
    let phone = data.phone ? data.phone : "NA";
    return code + " " + phone;
  }

  

  //to show full name
  function getName(record) {
    return `${record.firstName} ${record.lastName}`;
  }

  //to show type of user
  function getUserType(record) {
    
    if (record?.userType?.id == 4) {
      return "Market Manager";
    } else {
      return "Admin";
    }
  }

  const showMarkets=(record)=>{
    let data;
    if(record?.userType?.id ===4){
      if(record?.markets?.length > 0){
        data =record?.markets?.filter(item=> item.active === 1).map(item=> item.market.alias? item.market.alias:"NA");
        return data.join(' , ');
    }else{
      return "NO Market";
   } }else{
      data = markets.filter(item=> item.active === 1).map(item=> item.alias? item.alias:"NA");;
      return data?.join(' , ');
    }
  }


  //data that needs to be shown
  const columns = [
    {
      title: "NAME",
      dataIndex: "firstName",
      key: "id",
      render: (id, record) => getName(record),
      width:"15%"
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "id",
      width:"15%"
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "id",
      sorter: false,
      render: (id, record) => getPhoneNumber(record),
      width:"15%"
    },
    {
      title: "USER TYPE",
      dataIndex: "userType",
      key: "userType",
      render: (id, record) => getUserType(record),
      width:"15%"
    },
    {
      title: "MARKETS",
      dataIndex: "markets",
      key: "markets",
      render: (id, record) => showMarkets(record),
      width:"20%"
    },
    {
      title: "UPDATE",
      dataIndex: "id", // to get data from api
      key: "id", // for column sorting key
      sorter: false,
      width:"10%",
      render: (id, record) => (
        <Button type="link" onClick={() => handleEdit(record)}>
          Update
        </Button>
      ),
    },
  ];

  //table and data
  return (
    <div style={{ marginTop: "20px" }}>
      <Table
        dsize="small"
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={users}
        locale={{ emptyText: "No employee available" }}
        loading={loading}
        style={{ marginTop: "50px" }}
        onChange={handleTableChange}
      />
    </div>
  );
}
