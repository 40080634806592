import React from 'react'
import { PageHeader } from 'antd'
import { CUTechnician, DTTechnician} from '../components/pages/technician'

function FieldTechnician() {
    return (
        <div>
              <div className="p-20 white-bc title-box-shadow">
				<PageHeader className="mb-10" title="Field Technician" />
			</div>
            
            <div className="p-20 pt-0">
                <CUTechnician/>
                <DTTechnician/>
			</div>
        </div>
    )
}

export default FieldTechnician
