const initialState = {
	userFormVisible: false,
	editableUser: {},
	lastUpdatedTime: ''
};

export function users(state = initialState, action) {
	if (action.type === 'SHOW_USER_FORM') {
		return {
			...state,
			userFormVisible: true,
			editableUser: action.payload,
			
		};
	} else if (action.type === 'HIDE_USER_FORM') {
		return { ...state, userFormVisible: false, editableUser: {} };
	} else if (action.type === 'USER_STATUS_CHANGE_FORM') {
		let data = state.editableUser;
		data.status = action.payload;
		return {
			...state,
			editableUser: data,
		};
	} else if (action.type === 'USER_LAST_UPDATED_TIME') {
		return { ...state, lastUpdatedTime: action.payload };
	} else {
		return state;
	}
}
