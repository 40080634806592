import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button,Drawer} from 'antd';
import { TechinicianForm } from '../../../forms/technician';
import { useDispatch, useSelector } from 'react-redux';
import { timeStamp } from 'helpers/date-formatter';

export default function CUTechnician() {
    const dispatch = useDispatch();
    const { technicianFormVisible, editableTechnician } = useSelector(
		(state) => state.technician
	);
	const editMode = 'id' in editableTechnician ? true : false;
	const formHeaderText = editMode
		? 'Edit Technician ':'Create Technician';
    
    function onClose() {
		dispatch({
			type: 'HIDE_TECHNICIAN_FORM',
			payload: {},
		});
		dispatch({
			type: 'TECHNICIAN_LAST_UPDATED_TIME',
			payload: timeStamp(),
		});
	}

    function showDrawer(){
        dispatch({
            type: 'SHOW_TECHNICIAN_FORM',
            payload: {},
        });
    }
 
	//to show field technician create and edit form
    return (
        <div>
			<div style={{display:"flex", justifyContent:"flex-end"}}>
            <Button type="primary" onClick={showDrawer}>
				<PlusOutlined /> Add Field Technician
			</Button>
			</div>
            <div>
            <Drawer
				title={formHeaderText}
				width={750}
				onClose={onClose}
				visible={technicianFormVisible}
				destroyOnClose={true}
				closable={false}
				maskClosable={true}
			>
				<TechinicianForm
					onClose={onClose}
					editMode={editMode}
					editableTechnician={editableTechnician}
				/>
			</Drawer>
            </div>
        </div>
    )
}


