const initialState = {
	discountFormVisible: false,
	editableDiscount: {},
	lastUpdatedTime: ''
};

export function discount(state = initialState, action) {
	if (action.type === 'SHOW_DISCOUNT_FORM') {
		return {
			...state,
			discountFormVisible: true,
			editableDiscount: action.payload,
			
		};
	} else if (action.type === 'HIDE_DISCOUNT_FORM') {
		return { ...state, discountFormVisible: false, editableDiscount: {} 
	};
	} else if (action.type === 'DISCOUNT_LAST_UPDATED_TIME') {
		return { ...state, lastUpdatedTime: action.payload };
	} else {
		return state;
	}
}
