export const TIME_ZONES = [
	{
		id: 'America/Los_Angeles',
		name: 'PST - America/Los Angeles',
		value: 'PST - America/Los Angeles',
		alias: '(PST)',
		diff: '-02:00',
	},
	{
		id: 'America/Chicago',
		name: 'CST - America/Chicago',
		value: 'CST - America/Chicago',
		alias: '(CST)',
		diff: '+00:00',
	},
	{
		id: 'America/New_York',
		name: 'EST - America/New York',
		value: 'EST - America/New York',
		alias: '(EST)',
		diff: '+01:00',
	},
	{
		id: 'America/Creston',
		name: 'MST - America/Creston',
		value: 'MST - America/Creston',
		alias: '(MST)',
		diff: '-07:00',
	},
];

export const EMPLOYEE_ROLES = [
	{ id: 1, label: 'Super Admin' },
	{ id: 2, label: 'Market Manager' },
	{ id: 8, label: 'Team Lead' },
	{ id: 9, label: 'Detailer' },
	{ id: 10, label: 'Op Supervisor' },
];
