import {DTConsumers } from 'components/pages/consumers';
import React from 'react';
import { PageHeader } from 'antd';

function Consumers() {
    return (
        <div>
               <div className="p-20 white-bc title-box-shadow">
				<PageHeader className="mb-10" title="Consumers" />
			</div>

            <div className="p-20 pt-0">
                <DTConsumers/>
			</div>
        </div>
    )
}

export default Consumers;
