// export function addOrChangeObjectLabelValue(data, labelKey, valueKey = 'id') {
// 	/**
// 	 * react-select expects input object contains `label` and `value`
// 	 * so we pass source array here and add `label` or `value` if that not exists
// 	 */
// 	return data.map((obj) => {
// 		if (!obj.hasOwnProperty('label')) {
// 			let manipulateLabel;

// 			if (typeof labelKey === 'string') manipulateLabel = obj[labelKey];
// 			else manipulateLabel = `${obj[labelKey[0]]} ${obj[labelKey[1]]}`;

// 			let label = { label: manipulateLabel };
// 			obj = { ...obj, ...label };
// 		}
// 		if (!obj.hasOwnProperty('value')) {
// 			let value = { value: obj[valueKey] };
// 			obj = { ...obj, ...value };
// 		}
// 		/**
// 		 * if there is no `id` key in object, we add it by ourself
// 		 */
// 		if (!obj.hasOwnProperty('id') && valueKey !== 'id') {
// 			let value = { id: obj[valueKey] };
// 			obj = { ...obj, ...value };
// 		}
// 		return obj;
// 	});
// }

// export function getSelectedIds(data) {
// 	/**
// 	 * will return selected ids, if id key exists in object
// 	 */
// 	if (data && data.length > 0 && data !== undefined) {
// 		let output = data
// 			.filter(obj => Object.keys(obj).includes('id'))
// 			.map(obj => obj.id);
// 		return output;
// 	}
// 	return data;
// }

export function leftToFilterOptions(input, option) {
	return option.props.children
		.toString()
		.toLowerCase()
		.startsWith(input.toLowerCase());
}