export function getUserData() {
	const USER_DATA = localStorage.getItem('userInfoData') || undefined;

	if (USER_DATA) {
		return JSON.parse(USER_DATA);
	}
	
	return {};
}

export function getVendor() {
	const { vendor = null } = getUserData();

	if (vendor) {
		return `["${vendor}"]`;
	}

	return vendor;
}
