import React, { useEffect, useState } from "react";

import { Col, message, Row, Typography,Modal } from "antd";
import { Formik } from "formik";
import { DatePicker, Form, Select } from "formik-antd";
import { useSelector, useDispatch } from "react-redux";
import { JobCard } from "./JobCard";
import { getTechnicians } from "services/common";
import { FormActionButtons } from "forms";
import * as Yup from "yup";
import { API } from "services";
import { timeStamp } from "helpers/date-formatter";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { APPLY } from "./Apply";

const { Option } = Select;
const { Text } = Typography;
const {confirm} = Modal;



export function EditFormStatus({ onClose }) {
  const dispatch = useDispatch();
  const { editableEditJobStatus } = useSelector((state) => state.job);
  const [showCancellationField, setShowCancellationField] = useState(false);
  const [checkedCancelFee, setCheckedCancelFee] = useState(false);
  const [notSubmit,setNotSubmit] = useState("Nothing");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(getTechnicians({ all: true }));
  }, []);

  const initialValues = {
    status: undefined,
  };

  const EditFormSchema = Yup.object({
    status: Yup.string().required("Status is required"),
  });

  const handleRadio=(e)=>{
    setNotSubmit(e.target.value);
  }

  const saveData = () => {
    dispatch({
      type: "SAVE_JOB_FORM",
      payload: timeStamp,
    });
  }

  

  const handleSubmit = (values, { setErrors, resetForm, setSubmitting }) => {
    console.log('values: ', values);
    const tempData = [];
    
    editableEditJobStatus.forEach(data => {
      const valuesCopy = {};
      console.log(data);
      valuesCopy.status = values?.status;
      valuesCopy.id = data?.id;
      valuesCopy.technician = data?.technician?.id;
      tempData.push(valuesCopy);
    });

    console.log('tempData: ', tempData);
    const CREDENTIALS = {
      url: `/bookings/${tempData?.[0]?.id}/status`,
      method: 'patch',
      data: {
        status: tempData?.[0]?.status,
        chargeCancellationFee: checkedCancelFee
      }
    };
    API.common(CREDENTIALS)
      .then((response) => {
        console.log('response: ', response);
        showSuccessMessage();
        resetForm();
        onClose();
        saveData();
      })
      .finally(() => {
        setSubmitting(false);
      });

  };

  function showSuccessMessage() {
    message.success(`status updated successfully`);
  }

  function onChange(e) {
    setCheckedCancelFee(e.target.checked);
  }


  
  function completeSubmit() {
    let status={status:"COMPLETED"};
    const CREDENTIALS = {
      url: `/bookings/${editableEditJobStatus[0]?.id}/status`,
      method: "patch",
      data: status
    };
  
    API.common(CREDENTIALS)
    .then(() => {
      showSuccessMessage();
      dispatch({
        type: "HIDE_EDIT_JOB_STATUS_FORM",
        payload: {},
      });
      dispatch({
        type: "USER_JOB_LAST_UPDATED_TIME",
        payload: timeStamp(),
      });
    })
    .catch(e => console.log(e))
    }

  function showConfirm() {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to complete this job and trigger payment processing?',
      onOk() {
        completeSubmit();
      },
      onCancel() {
      },
    });
  }

  function cancleSubmit() {
    let status={status:"SERVICER_CANCELLED"};
    const CREDENTIALS = {
      url: `/bookings/${editableEditJobStatus[0]?.id}/status`,
      method: "patch",
      data: status
    };
  
    API.common(CREDENTIALS)
    .then(() => {
      showCancleSuccessMessage();
      dispatch({
        type: "HIDE_EDIT_JOB_STATUS_FORM",
        payload: {},
      });
      dispatch({
        type: "USER_JOB_LAST_UPDATED_TIME",
        payload: timeStamp(),
      });
    })
    .catch(e => console.log(e))
    }

  function showCancleConfirm() {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to cancel this job?',
      onOk() {
        cancleSubmit();
      },
      onCancel() {
       
      },
    });
  }
  
  function showCancleSuccessMessage() {
    message.success(`Job cancel successfully`);
    }
  function showSuccessMessage() {
    message.success(`Job completed successfully`);
    }

    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
        let status="CONSUMER_CANCELLED";
        const CREDENTIALS = {
          url: `/bookings/${editableEditJobStatus[0]?.id}/status`,
          method: "patch",
          data: {
            status: status,
            chargeCancellationFee: checkedCancelFee
          }
        };
      
        API.common(CREDENTIALS)
        .then(() => {
          showSuccessMessage();
          dispatch({
            type: "HIDE_EDIT_JOB_STATUS_FORM",
            payload: {},
          });
          dispatch({
            type: "USER_JOB_LAST_UPDATED_TIME",
            payload: timeStamp(),
          });
        })
        .catch(e => console.log(e))
        
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };


    
  return (
    <div>
      <div>
      <Modal title="Consumer Cancelled" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <Checkbox style={{ color: 'red' }} onChange={onChange}>* Charge Cancellation Fee</Checkbox>
      </Modal>
      </div>
      < Formik
        initialValues={initialValues}
        validationSchema={EditFormSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={8} className="mb-20">
              <div>
                {editableEditJobStatus.length} Job
                {editableEditJobStatus.length > 1 ? "s" : ""} Selected
              </div>
              <JobCard editableEditJob={editableEditJobStatus} formtype="editStatus" />
            </Row>
            <Text style={{ fontWeight: "bolder" }}>Change Status</Text>
            <Row gutter={8} className="m-2">
              <Col span={12}>
                <Form.Item
                  name="status"
                  label="Status"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    showSearch
                    name="status"
                    style={{ width: "100%" }}
                    placeholder={"Select Status"}
                    allowClear={true}
                    mode="single"
                    onChange={(e) => {
                      if (e == 'CONSUMER_CANCELLED') {
                        showModal();
                        setShowCancellationField(true);
                      }else{
                        setShowCancellationField(false);
                      }

                      if(e == 'COMPLETED' && editableEditJobStatus[0]?.market?.marketPreference?.reviewBooking == 1){
                        showConfirm();
                      }
                      if(e == "SERVICER_CANCELLED"){
                        showCancleConfirm();
                      }
                      
                    }}

                    optionFilterProp="children"
                  >
                      <Option key={0} value={'COMPLETED'}>
                        ADMIN COMPLETED
                      </Option>
                 
                    <Option key={1} value={'SERVICER_CANCELLED'}>
                      ADMIN CANCELLED
                    </Option>
                    <Option key={2} value={'CONSUMER_CANCELLED'}>
                      CONSUMER CANCELLED
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* {showCancellationField && (
              <Row gutter={8} className="m-2">
                <Col span={12}>
                  <Form.Item
                    name="status"
                    hasFeedback={true}
                    showValidateSuccess={true}
                  >
                    
                  </Form.Item>
                </Col>
              </Row>
            )} */}
            <Row>
              <Col style={{marginBottom:"60px"}}>
                    {/* {(values.status === "COMPLETED" && editableEditJobStatus[0]?.market?.marketPreference?.reviewBooking == 1) && (<div onChange={handleRadio}><p>Are you sure you want to complete this job and trigger payment processing?</p>
                    <input type="radio" name="completed" value="Yes" id="yes"/><label htmlFor="yes">Yes</label> <input type="radio" name="completed" value="No" id="no"/><label htmlFor="no">Cancel</label></div>)} */}
                    {/* {values.status === "SERVICER_CANCELLED" && (<div onChange={handleRadio}><p>Are you sure you want to cancel this job?</p>
                    <input type="radio" name="completed" value="Yes" id="yes"/><label htmlFor="yes">Yes</label> <input type="radio" name="completed" value="No" id="no"/><label htmlFor="no">Cancel</label></div>)} */}
                    {(values?.status === "COMPLETED" && editableEditJobStatus[0]?.market?.marketPreference?.reviewBooking == 2) && (<APPLY applyForm={editableEditJobStatus}/>)}

              </Col>
            </Row>
            

            <FormActionButtons onClose={onClose} noNeedSaveBtn={false} reset={false} isSubmitting={(editableEditJobStatus[0]?.market?.marketPreference?.reviewBooking == 1 && notSubmit !="Nothing" && notSubmit !="No")?false:true} />
          </Form>
        )
        }
      </Formik >
    </div>
  );
}
