import React, { useEffect, useState } from "react";
import { getServices } from "services/common";
import Location from "assets/images/location.png";
import Time from "assets/images/time.png";
import Payments from "assets/images/payments.png";
import visa from "assets/images/Visa.svg";
import mastercard from "assets/images/MasterCard.svg";
import americanexpress from "assets/images/MasterCard.svg";
import discover from "assets/images/Discover.svg";
import dinersclub from "assets/images/DinersClub.svg";
import jcb from "assets/images/JCB.svg";
import unionpay from "assets/images/UnionPay.svg";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { Row, Col, Button, message, Select,Typography } from "antd";
import LightboxImages from "./LightBoxImages";
import { timeStamp } from "helpers/date-formatter";
import { API } from "services";
import { useDispatch, useSelector } from "react-redux";

export function JobsForm({ onClose }) {
  const dispatch = useDispatch();
  const [imgsrc, setImgsrc] = useState();
  const [DateSevice, setDataSevice] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const { editableJob, lastUpdatedTime } = useSelector((state) => state.job);
  const [customIconStr, setCustomIconStr] = useState(editableJob?.externalComments?editableJob?.externalComments:editableJob?.externalComments === null?"NA":"NA");
  const { Option } = Select;
  const { Paragraph } = Typography;

  function ShowApplyForm(record) {
    dispatch({
      type: "APPLY_COUPONS_FORM",
      payload: record,
    });
    dispatch({
      type: "HIDE_JOB_FORM",
      payload: {},
    });
  }

  const handleAddService = (editableJob) => {
    console.log(editableJob);
    let defaultService = editableJob?.services?.map((item) => item?.id);
    let seviceSelectedTemp = selectedService?.map((item) => Number(item));
    let sevicesTemp = seviceSelectedTemp.concat(defaultService);
    let valueCopy = {
      id: editableJob?.id,
      services: sevicesTemp,
    };

    const CREDENTIALS = {
      url: "/bookings/",
      method: "put",
      data: valueCopy,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        dispatch({
          type: "USER_JOB_LAST_UPDATED_TIME",
          payload: timeStamp(),
        });
        dispatch({
          type: "HIDE_JOB_FORM",
          payload: {},
        });
        dispatch({
          type: "SHOW_JOB_FORM",
          payload: response?.data?.booking,
        });
      })
      .catch((err) => console.log(err));
  };

  const handleRemove = (id) => {
    let defaultService = editableJob?.services?.map((item) => item?.id);
    let sevicesTemp = defaultService?.filter((item) => item !== id);

    let valueCopy = {
      id: editableJob?.id,
      services: sevicesTemp,
    };

    const CREDENTIALS = {
      url: "/bookings/",
      method: "put",
      data: valueCopy,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "USER_JOB_LAST_UPDATED_TIME",
          payload: timeStamp(),
        });
        dispatch({
          type: "HIDE_JOB_FORM",
          payload: {},
        });
        dispatch({
          type: "SHOW_JOB_FORM",
          payload: response?.data?.booking,
        });
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    dispatch(getServices({ all: true, active: [0, 1] }))
      .then((res) => setDataSevice(res?.data?.marketServices))
      .catch((err) => console.log(err));
    if (editableJob?.signature) {
      const CREDENTIALS = {
        url: `/consumers/photos?key=${editableJob?.signature}&type=buffer`,
      };
      API.common(CREDENTIALS).then((response) => {
        let TYPED_ARRAY = new Uint8Array(response.data);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, "");

        let base64String = btoa(STRING_CHAR);
        setImgsrc(base64String);
      });
    }

    // if (editableJob?.photos?.length > 0) {
    //   let newDataTemp =[];
    //   editableJob.photos.map(item=> {

    //   const CREDENTIALS = {
    //     url: `/consumers/photos?key=${item?.key}&type=buffer`
    //   };
    //   API.common(CREDENTIALS).then((response) => {

    //     let TYPED_ARRAY = new Uint8Array(response.data);
    //     const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
    //       return data + String.fromCharCode(byte);
    //     }, '');

    //     let base64String = btoa(STRING_CHAR);
    //     newDataTemp.push(base64String);
    //   });
    // })
    // setImgPhotos(newDataTemp);
    // }
  }, [lastUpdatedTime]);

  let tier = "";
  if (
    editableJob?.vehicle?.vehicleType?.name === "Sedan" ||
    editableJob?.vehicle?.vehicleType?.name === "Coupe" ||
    editableJob?.vehicle?.vehicleType?.name === "Convertible" ||
    editableJob?.vehicle?.vehicleType?.name === "Hatchback" ||
    editableJob?.vehicle?.vehicleType?.name === "Wagon"
  ) {
    tier = "Tier-1";
  }
  if (
    editableJob?.vehicle?.vehicleType?.name === "SUV/Small Pick Up/Minivans" ||
    editableJob?.vehicle?.vehicleType?.name === "SUV" ||
    editableJob?.vehicle?.vehicleType?.name === "Small Pick Up" ||
    editableJob?.vehicle?.vehicleType?.name === "Minivans"
  ) {
    tier = "Tier-2";
  }
  if (
    editableJob?.vehicle?.vehicleType?.name === "Cargo Van" ||
    editableJob?.vehicle?.vehicleType?.name === "Chassis"
  ) {
    tier = "Tier-3";
  }
  if (editableJob?.vehicle?.vehicleType?.name === "Heavy Duty Truck") {
    tier = "Tier-4";
  }

  const getStatus = () => {
    switch (editableJob?.status) {
      case "REQUEST_CONFIRMED":
        return "Request Confirmed";
      case "CHECKOUT":
        return "In Progress";
      case "IN_PROGRESS":
        return "In Progress";
      case "ASSIGNED":
        return "Assigned";
      case "COMPLETED":
        return "Completed";
      case "CONSUMER_CANCELLED":
        return "Consumer Cancelled";
      case "SERVICER_CANCELLED":
        return "Admin Cancelled";
      case "MISSED_OWING_REDFLAG":
        return "Missed";
      case "PAYMENT_FAILED":
        return "Payment Failed";
      case "PENDING_REVIEW_AND_PAYMENT":
        return "Review";
      default:
        return "NA";
    }
  };

  let total = 0;
  const serviceDone = editableJob?.services?.map(
    (service) => (total = total + Number(service?.price))
  );

  console.log(selectedService);
  const time = moment
    .tz(editableJob?.timeslot?.startDateTime, editableJob?.market?.timezone)
    .format("lll");

  const newTime = `${time.substr(0, 12)} at ${time.substr(12)}`;

  const serviceTypeOne = editableJob?.services?.filter((ser) => ser?.type === 1);
  const serviceTypeTwo = editableJob?.services?.filter((ser) => ser?.type === 2);
  const categories = editableJob?.services?.map((ser) => ser.category);
  const uniqueCategories = [...new Set(categories?.map((item) => item?.name))];
  // let taxCalulation= editableJob?.payment?.tax ? ((total + editableJob?.payment?.dispatch) / 100) * editableJob?.payment?.tax:0;
  // let totalWithTax =total + editableJob?.payment?.dispatch + taxCalulation;

  let taxCalulation1 =
    editableJob?.payment?.coupon?.type === 2
      ? ((total / 100) * editableJob?.payment?.coupon?.percent).toFixed(2)
      : 0;
  let calculateForTax = total - taxCalulation1;
  let dataMy =
    editableJob?.payment?.coupon?.type === 2 ? calculateForTax : total;

  console.log(customIconStr);

  const handleEditable=(e)=>{
    let valueCopy={id:editableJob?.id, externalComments:customIconStr}
    const CREDENTIALS = {
      url: "/bookings/",
      method: "put",
      data: valueCopy,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        dispatch({
          type: "USER_JOB_LAST_UPDATED_TIME",
          payload: timeStamp(),
        });
        dispatch({
          type: "HIDE_JOB_FORM",
          payload: {},
        });
        
      })
      .catch((err) => console.log(err));
  }

  console.log(customIconStr);
  return (
    <>
      {/*Car info */}
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #ededed",
          paddingBottom: "10px",
        }}
      >
        <div style={{ marginTop: "10px" }}>
          <img src={editableJob?.vehicle?.vehicleType?.icon} width="100px" />
        </div>
        <div style={{ padding: "11px 0 0 20px" }}>
          <p style={{ lineHeight: "0.5" }}>
            {editableJob?.vehicle?.year} {editableJob?.vehicle?.make}{" "}
            {editableJob?.vehicle?.model}
          </p>
          <p style={{ lineHeight: "0.5" }}>
            Unit Number:{" "}
            {editableJob?.vehicle ? editableJob.vehicle.unitNumber : "NA"}
          </p>
          <p style={{ lineHeight: "0.5" }}>
            License Number:{" "}
            {editableJob?.vehicle
              ? editableJob.vehicle.license !== null
                ? editableJob.vehicle.license !== ""
                  ? editableJob.vehicle.license
                  : "NA"
                : "NA"
              : "NA"}
          </p>

          <p style={{ lineHeight: "0.5" }}>
            Vin Number:{" "}
            {editableJob?.vehicle
              ? editableJob.vehicle.vin !== ""
                ? editableJob.vehicle.vin
                : "NA"
              : "NA"}
          </p>
          <p style={{ lineHeight: "0.5" }}>
            Class:{" "}
            {editableJob?.vehicle
              ? editableJob?.vehicle?.vehicleType?.name
              : "NA"}
          </p>
          <p style={{ lineHeight: "0.5" }}>
            Tier: {editableJob?.vehicle ? tier : "NA"}
          </p>
          <p style={{ lineHeight: "0.5" }}>
          Odometer: {editableJob?.odometer != null?  editableJob?.odometer != "0" ?editableJob.odometer:'NA':'NA'}
          </p>
        </div>
        {/* <div>
          <Button type="primary" onClick={}>Refund</Button>
        </div> */}
      </div>
      {/*End Car Info */}
      <div
        style={{
          padding: "20px 0 15px 15px",
          borderBottom: "1px solid #ededed",
        }}
      >
        <p>Consumer Information:</p>
        <p style={{ lineHeight: "0.5" }}>Name: {editableJob?.consumer?.name}</p>
        <p style={{ lineHeight: "0.5" }}>
          Phone: {editableJob?.consumer?.countryCode}{" "}
          {editableJob?.consumer?.phone}
        </p>
        <p style={{ lineHeight: "0.5" }}>
          Email:{" "}
          {editableJob?.consumer?.email ? editableJob?.consumer?.email : "NA"}
        </p>
        <p style={{ lineHeight: "0.5" }}>
          Company Name:{" "}
          {editableJob?.consumer?.companyName
            ? editableJob?.consumer?.companyName
            : "NA"}
        </p>
        <p></p>
      </div>
      {/*Status */}
      <div
        style={{
          padding: "20px 0 15px 15px",
          borderBottom: "1px solid #ededed",
        }}
      >
        <p style={{ lineHeight: "0.5" }}>Status:</p>
        {editableJob?.status == "COMPLETED" ? (
          <p style={{ color: "green" }}>{editableJob?.status}</p>
        ) : (
          <p>{getStatus()}</p>
        )}
      </div>
      {/*End Status */}
      {/*Service By */}
      {editableJob?.status != "REQUEST_CONFIRMED" && (
        <div
          style={{
            padding: "20px 0 15px 15px",
            borderBottom: "2px solid #ededed",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "60px",
              borderRadius: "8px",
              background: "#ededed",
              textAlign: "center",
            }}
          >
            <UserOutlined style={{ fontSize: "45px", padding: "5px" }} />
          </div>
          <div style={{ padding: "10px 0 0 30px" }}>
            {editableJob?.status === "ASSIGNED" ? (
              <p style={{ lineHeight: "0.5" }}>Assigned To:</p>
            ) : (
              <p style={{ lineHeight: "0.5" }}>Serviced By:</p>
            )}

            <p>
              <b>
                {editableJob?.technician?.firstName}{" "}
                {editableJob?.technician?.lastName}
              </b>
            </p>
          </div>
        </div>
      )}

      {/*Service By */}
      <div
        style={{
          padding: "20px 0 15px 15px",
          borderBottom: "2px solid #ededed",
        }}
      >
        <h5>Booking Details</h5>
        <div>
          <div style={{ display: "flex", paddingLeft: "15px" }}>
            <div>
              <img src={Location} width="25px" />
            </div>
            <div style={{ paddingLeft: "40px" }}>
              <p style={{ lineHeight: "0.5" }}>Location</p>
              <p style={{ lineHeight: "1.5" }}>
                <b>{editableJob?.address?.full}</b>
              </p>
            </div>
          </div>
          <div
            style={{ display: "flex", paddingLeft: "15px", paddingTop: "20px" }}
          >
            <div>
              <img src={Time} width="25px" />
            </div>
            <div style={{ paddingLeft: "40px" }}>
              <p style={{ lineHeight: "0.5" }}>Date & Time</p>
              <p style={{ lineHeight: "0.5" }}>
                <b>{newTime}</b>
              </p>
            </div>
          </div>
          <div
            style={{ display: "flex", paddingLeft: "15px", paddingTop: "20px" }}
          >
            <div>
              <img src={Payments} width="25px" />
            </div>
            <div style={{ paddingLeft: "40px" }}>
              <p style={{ lineHeight: "0.5" }}>Payment</p>
              <p style={{ lineHeight: "0.5", display: "flex" }}>
                <p>
                  <img
                    src={
                      editableJob?.payment?.brand
                        ? editableJob?.payment?.brand?.toLowerCase() === "visa"
                          ? visa
                          : editableJob?.payment?.brand?.toLowerCase() === "jcb"
                          ? jcb
                          : editableJob?.payment?.brand?.toLowerCase() ===
                            "mastercard"
                          ? mastercard
                          : editableJob?.payment?.brand?.toLowerCase() ===
                            "amex"
                          ? americanexpress
                          : editableJob?.payment?.brand?.toLowerCase() ===
                            "discover"
                          ? discover
                          : editableJob?.payment?.brand?.toLowerCase() ===
                            "diners"
                          ? dinersclub
                          : editableJob?.payment?.brand?.toLowerCase() ===
                            "unionpay"
                          ? unionpay
                          : visa
                        : visa
                    }
                    width="33px"
                  />
                </p>{" "}
                <p style={{ paddingTop: "1.5px", paddingLeft: "5px" }}>
                  <strong>XXXX-XXXX-XXXX-{editableJob?.payment?.card}</strong>
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      {editableJob?.redflags?.length > 0 && (
        <div
          style={{
            padding: "20px 0 15px 15px",
            borderBottom: "2px solid #ededed",
          }}
        >
          <h5>Redflag</h5>
          <br />
          <div>
            <div style={{ display: "flex" }}>
              <div>
                {editableJob?.redflags?.map((redflag) => (
                  <div>
                    <p style={{ lineHeight: "0.5" }}>
                      <b>{redflag?.redflag?.name}</b>
                    </p>
                    <p style={{ lineHeight: "1.5" }}>{redflag?.comments}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <div style={{ padding: "20px 0 15px 15px" }}>
          <h5>Booking Summary</h5>
          <div>
            <div style={{ borderBottom: "1px solid #ededed" }}>
              {uniqueCategories?.map((category) => (
                <div
                  style={{
                    paddingTop: "5px",
                  }}
                >
                  <p>{category}</p>
                  {serviceTypeOne
                    ?.filter((ser) => ser?.category?.name === category)
                    ?.map((showSer) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          <b>{showSer?.name}</b>
                        </p>
                        <p>${showSer?.price?.toFixed(2)}</p>
                      </div>
                    ))}
                  {serviceTypeTwo
                    ?.filter((ser) => ser?.category?.name === category)
                    ?.map((showSer) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          <b>{showSer?.name}</b>
                        </p>
                        <p>${showSer?.price?.toFixed(2)}</p>
                      </div>
                    ))}
                </div>
              ))}
              {/* {editableJob?.status === "PENDING_REVIEW_AND_PAYMENT" && (
               <div style={{paddingBottom:"10px"}}>
              <Select onChange={(e)=>setSelectedService(e)} value={selectedService} style={{width:"200px", marginRight:"18px"}} allowClear={true} mode="multiple">
                <Option value={1}>Select Service</Option>
                {DateSevice?.map(item =>(
                <Option key={item?.id}>{item?.name}</Option>
                )
                )}
              </Select>
              <Button onClick={()=>handleAddService(editableJob)}>Add Service</Button>
            </div>
            )} */}
            </div>

            {/* <div>
            {editableJob?.services?.map((service) => (   
              <div>{service.type === 1 &&(
                <div style={{
                  borderBottom: "1px solid #ededed",
                  paddingTop: "10px",
                }}>
                <p>{service.category.name}</p>
                
                <div style={{display: "flex",
                  justifyContent: "space-between",}}>
                <p>
                  <b>{service.name}</b>
                </p>
                <p>${service.price}</p>
              </div>
              {serviceTypeTwo.filter(ser=> ser.category.name === service.category.name).map(showSer=>(
                  <div style={{display: "flex",
                  justifyContent: "space-between",}}>
                <p>
                  {showSer.name}
                </p>
                <p>${showSer.price}</p>
              </div>
              ))}
                </div>
                )}
              </div>
            ))}
          </div> */}
          </div>
        </div>
        <div style={{ padding: "20px 0 15px 15px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
          >
            <p>
              <b>Sub Total</b>
            </p>
            <p>${total?.toFixed(2)}</p>
          </div>
          {editableJob?.payment?.coupon?.type === 2 && (
            <div
              style={{ borderBottom: "1px solid #ededed", paddingTop: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p>
                  <b>Applied Coupon</b> -{" "}
                  {editableJob?.payment?.coupon?.type === 2
                    ? editableJob?.payment?.coupon?.percent
                    : 0}
                  %
                </p>
                <p>
                  -$
                  {editableJob?.payment?.coupon?.type === 2
                    ? (
                        (total / 100) *
                        editableJob?.payment?.coupon?.percent
                      ).toFixed(2)
                    : 0}
                </p>
              </div>
              <p>{editableJob?.payment?.coupon?.code}</p>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
          >
            <p>
              <b>Dispatch Fee</b>
            </p>
            <p>
              $
              {editableJob?.payment?.dispatch
                ? `${editableJob?.payment?.dispatch?.toFixed(2)}`
                : `0.00`}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
          >
            <p>
              <b>Tax</b> -{" "}
              {editableJob?.payment?.tax ? editableJob?.payment?.tax : 0}%
            </p>
            <p>
              $
              {editableJob?.payment?.tax
                ? (
                    ((dataMy + editableJob?.payment?.dispatch) / 100) *
                    editableJob?.payment?.tax
                  ).toFixed(2)
                : 0}
            </p>
          </div>
        </div>
        <div
          style={{
            padding: "10px 0 15px 15px",
            borderBottom: "1px solid #ededed",
          }}
        >
          {editableJob?.payment?.coupon?.type === 1 && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p>
                  <b>Applied Coupon</b>
                </p>
                <p>
                  -$
                  {editableJob?.payment?.coupon?.type === 1
                    ? editableJob?.payment?.coupon?.amount
                    : 0}
                </p>
              </div>
              <p>{editableJob?.payment?.coupon?.code}</p>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>
              <b>Total</b>
            </p>
            <p>${editableJob?.payment?.amount}</p>
          </div>
        </div>
        {editableJob?.payment?.cancellationFee !== 0 && (
          <div
            style={{
              padding: "20px 0 15px 15px",
              borderBottom: "1px solid #ededed",
            }}
          >
            <h5>Cancellation Summary</h5>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>
                <b>Cancellation Fee</b>
              </p>
              <p>${editableJob?.payment?.cancellationFee ?? 0}</p>
            </div>
          </div>
        )}
        {(editableJob?.status == "COMPLETED" ||
          editableJob?.status == "PAYMENT_FAILED" || editableJob?.status == "PENDING_REVIEW_AND_PAYMENT") &&
          editableJob?.signature !== null && (
            <div style={{ padding: "10px 0 15px 15px" }}>
              <p>signature:</p>
              {editableJob?.signature && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "15px",
                  }}
                >
                  <img src={`data:image/jpg;base64,${imgsrc}`} />
                </div>
              )}
            </div>
          )}
        {(editableJob?.status == "COMPLETED" ||
          editableJob?.status == "PAYMENT_FAILED" || editableJob?.status == "PENDING_REVIEW_AND_PAYMENT") &&
          editableJob?.signature === null && (
            <p
              style={{
                textAlign: "center",
                marginBottom: "30px",
                padding: "10px",
              }}
            >
              Customer not available for signature
            </p>
          )}
        <div
          style={{
            padding: "20px 0 15px 15px",
            borderBottom: "1px solid #ededed",
          }}
        >
          <p>
            <b>Internal Comments: </b> <p>{editableJob?.comments ?? "NA"}</p>
          </p>
        </div>
        <div
          style={{
            padding: "20px 0 15px 15px",
            borderBottom: "1px solid #ededed",
          }}
        >
            <b>External Comments: </b> 
            <p>
          <Paragraph
        editable={{
          tooltip: 'click to edit text',
          onChange: setCustomIconStr,
        }}
      >
        {customIconStr}
      </Paragraph>
          </p>
        </div>
      </div>
      <div style={{ padding: "20px 0 15px 15px", marginBottom: "38px" }}>
        <h5>Job Photos:</h5>
        <LightboxImages photos={editableJob?.photos} />
        {editableJob?.photos?.length > 0 ? (
          <div>
            {editableJob?.photos?.map((item, index) => (
              <img
                src={`https://smallfleet-np-images.s3.us-east-1.amazonaws.com/${item?.key}`}
                width="69px"
                style={{
                  marginRight: "5px",
                  marginBottom: "5px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch({
                    type: "OPEN_LIGHTBOX",
                    payload: { open: true, index: index },
                  })
                }
              />
            ))}
          </div>
        ) : (
          <p style={{ margin: "10px auto 38px auto", textAlign: "center" }}>
            No Photos Available
          </p>
        )}
      </div>
      <Row
        type="flex"
        justify="space-between"
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e8e8e8",
          padding: "10px 16px",
          textAlign: "right",
          left: 0,
          background: "#fff",
          borderRadius: "0 0 4px 4px",
          zIndex: "9999",
        }}
      >
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
         
          <Button
            data-testid="form-close-btn"
            style={{
              marginRight: 8,
            }}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            type="primary"
            style={{
              marginRight: 8,
            }}
            onClick={handleEditable}
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
}
