import React, { useEffect, useState } from "react";
import { Checkbox, Button, PageHeader, message } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { API } from "services";
import { useHistory } from "react-router";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { getParamsFromUrl, pushParamsToUrl } from "helpers/history";
import _ from 'lodash';

export default function TimeSlots() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isModalVisible } = useSelector((state) => state.marketpreference);
  const [stauses, setStatuses] = useState([]);
  let [showTimeSlot, setShowTimeSlot] = useState([]);
  let [timeslotsColor, setColor] = useState("timeslot");
  const [dateTemp, setDateTemp] = useState("hello");
  const [slot, setSlot] = useState([]);
  const [slotEnable, setSlotEnable] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [tempValue, setTemp] = useState([]);
  const [tempValue1, setTemp1] = useState([]);
  const [uncheckedList, setuncheckedList] = useState([]);
  const [uncheckedListDisable, setuncheckedListDisable] = useState([]);
  const [tempraryArray,setTempraryArray]= useState([]);
  const [disabledArray,setDisabledArrray]=useState([]);
  const [slotData,setSlotData]=useState([]);

  useEffect(() => {
    const params = getParamsFromUrl(history);
    if (
      params &&
      params.startDate === undefined &&
      params.endDate === undefined
    ) {
      pushParamsToUrl(history, {
        ...params,
        startDate: moment().startOf("week").format("YYYY-MM-DD"),
        endDate: moment().endOf('week').add(1, 'd').format('YYYY-MM-DD'),
      });
    }

    console.log();
    let tR, sD;
  tR = moment(params.startDate);
  sD = tR.startOf("week").format("YYYY-MM-DD");
  const days = [
    {
      label: `Sunday\n ${moment(sD).day(0).format("DD")}`,
      value: moment(sD).day(0).format("YYYY-MM-DD"),
    },
    {
      label: `Monday\n ${moment(sD).day(1).format("DD")}`,
      value: moment(sD).day(1).format("YYYY-MM-DD"),
    },
    {
      label: `Tuesday\n ${moment(sD).day(2).format("DD")}`,
      value: moment(sD).day(2).format("YYYY-MM-DD"),
    },
    {
      label: `Wednesday\n ${moment(sD).day(3).format("DD")}`,
      value: moment(sD).day(3).format("YYYY-MM-DD"),
    },
    {
      label: `Thursday\n ${moment(sD).day(4).format("DD")}`,
      value: moment(sD).day(4).format("YYYY-MM-DD"),
    },
    {
      label: `Friday\n ${moment(sD).day(5).format("DD")}`,
      value: moment(sD).day(5).format("YYYY-MM-DD"),
    },
    {
      label: `Saturday\n ${moment(sD).day(6).format("DD")}`,
      value: moment(sD).day(6).format("YYYY-MM-DD"),
    },
  ];

    const CREDENTIALS = {
      url: `/schedules?markets=[${isModalVisible?.id}]&startDate=${
        params?.startDate == undefined
          ? moment().startOf("week").format("YYYY-MM-DD")
          : params?.startDate
      }&endDate=${
        params?.endDate == undefined
          ? moment().endOf("week").add(1,'d').format("YYYY-MM-DD")
          : params?.endDate
      }&active=[0,1]`,
      method: "get",
    };

    API.common(CREDENTIALS)
      .then((response) => {
        setSlot(response?.data?.schedules);


        let data = [];
        if (response?.data?.schedules?.length > 0) {
          response.data.schedules.forEach((item) => {
            let newData = {};
            let label = item?.active;
            let id = item?.id;
            let value = moment
              .utc(item?.startDateTime)
              .tz(isModalVisible?.timezone)
              .format();
            let newValue =value.substring(0,10);
            newData.label = label;
            newData.value = newValue;
            newData.id =id;
            data.push(newData);
          });
        }
        
        const newData = [];

        if (data?.length > 0) {
          data.map((item) => {
            let modifiedData = {};
            let label = item?.label;
            let value = item?.value?.substring(0, 10);
            let id =item?.id;
            modifiedData.label = label;
            modifiedData.value = value;
            modifiedData.id=id;
            newData.push(modifiedData);
          });
        }
        setSlotData(newData);

        
        const unique = [...new Set(newData.map((item) => item.value))];
         //unnessary
        const uniqueStatus = newData.filter((item) => item.label == 1);
        const StatusActive = [...new Set(uniqueStatus.map((item) => item.value))];
        let StatusInactive = unique.filter(item=> !StatusActive.includes(item));
        let activeArray =[];
        if(StatusActive?.length >0){
        StatusActive.map(item=> {
          let newData = {};
          newData.label = 1;
          newData.value = item;
          activeArray.push(newData);
        })
      }
      let InactiveArray =[];
      if(StatusInactive?.length >0){
        StatusInactive.map(item=> {
        let newData = {};
        newData.label = 0;
        newData.value = item;
        InactiveArray.push(newData);
      })
    } 
        
    Array.prototype.push.apply(activeArray,InactiveArray);
    let showList =[];
  let uncheckedItem =[];
  let disabledList =[];
days.map((item,index)=> activeArray.filter(item1=> 
  { if(item1.value === item.value){
    if(item1.label === 1){
      showList[index]=1;
    }else{
      showList[index]=0;
    }
}
  }))
  
  setDisabledArrray(disabledList);
    setTempraryArray(showList);
  setTemp(unique);
  days.filter((item,index)=> {if(!unique.includes(item.value)){
    disabledList[index]=0;
  }});
  
        //unnsessary
        
        //enabled
        const CREDENTIALSENABLE = {
          url: `/schedules?markets=[${isModalVisible?.id}]&startDate=${
            params?.startDate == undefined
              ? moment().startOf("week").format("YYYY-MM-DD")
              : params?.startDate
          }&endDate=${
            params?.endDate == undefined
              ? moment().endOf("week").format("YYYY-MM-DD")
              : params?.endDate
          }&active=[0]`,
          method: "get",
        };

        API.common(CREDENTIALSENABLE)
          .then((response) => {
            setSlotEnable(response?.data?.schedules);
            let data = [];
            if (response?.data?.schedules?.length > 0) {
              response.data.schedules.forEach((item) => {
                let newData = {};
                let label = item?.active;
                let value = moment
                  .utc(item?.startDateTime)
                  .tz(isModalVisible?.timezone)
                  .format();
                newData.label = label;
                newData.value = value;
                data.push(newData);
                return data;
              });
            }

            const newData = [];

            if (data?.length > 0) {
              data.map((item) => {
                let modifiedData = {};
                let label = item?.label;
                let value = item?.value?.substring(0, 10);
                modifiedData.label = label;
                modifiedData.value = value;
                newData.push(modifiedData);
              });
            }

            const unique1 = [...new Set(newData.map((item) => item.value))];
            const uniqueModified = unique1.filter(
              (item) => !unique.includes(item)
            );
            // console.log(uniqueModified.map(item=> moment(item).format('dddd')));
            setStatuses(uniqueModified);
            setTemp1(uniqueModified);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [dateTemp]);

  // showTimeSlot.map(item=> moment(item.startDateTime).format("YYYY-MM-DD"));
  // const showData=(record)=>{
  //     setShowTimeSlot(record);
  //   }
  //console.log(showTimeSlot.map(item=> stauses.includes(item)));
  const handleOk = (data) => {
    const slots = slot.filter((item) =>
      data.includes(item.startDateTime.substring(0, 10))
    );
    const scheduleId = slots.map((item) => item.id);

    const CREDENTIALS = {
      url: `/schedules/timeslots`,
      method: "put",
      data: { schedules: scheduleId, active: 0 },
    };

    API.common(CREDENTIALS)
      .then((response) => {
        dispatch({
          type: "MARKET_MODAL",
          payload: { id: -1, timezone: "" },
        });
        history.push({ pathname: "/markets" });
      })
      .catch((err) => console.log(err));
  };

  const handleEnableTimeSlots = (data) => {
    const slots = slotEnable.filter((item) =>
      data.includes(item.startDateTime.substring(0, 10))
    );

    const scheduleId = slots.map((item) => item.id);
    const CREDENTIALS = {
      url: `/schedules/timeslots`,
      method: "put",
      data: { schedules: scheduleId, active: 1 },
    };

    API.common(CREDENTIALS)
      .then((response) => {
        dispatch({
          type: "MARKET_MODAL",
          payload: { id: -1, timezone: "" },
        });
        history.push({ pathname: "/markets" });
      })
      .catch((err) => console.log(err));
  };
  const handleCancel = () => {
    dispatch({
      type: "MARKET_MODAL",
      payload: { id: -1, timezone: "" },
    });
    history.push({ pathname: "/markets" });
  };

  const params = getParamsFromUrl(history);
  let tR, sD;
  tR = moment(params.startDate);
  sD = tR.startOf("week").format("YYYY-MM-DD");
  const days = [
    {
      label: `Sunday\n ${moment(sD).day(0).format("DD")}`,
      value: moment(sD).day(0).format("YYYY-MM-DD"),
    },
    {
      label: `Monday\n ${moment(sD).day(1).format("DD")}`,
      value: moment(sD).day(1).format("YYYY-MM-DD"),
    },
    {
      label: `Tuesday\n ${moment(sD).day(2).format("DD")}`,
      value: moment(sD).day(2).format("YYYY-MM-DD"),
    },
    {
      label: `Wednesday\n ${moment(sD).day(3).format("DD")}`,
      value: moment(sD).day(3).format("YYYY-MM-DD"),
    },
    {
      label: `Thursday\n ${moment(sD).day(4).format("DD")}`,
      value: moment(sD).day(4).format("YYYY-MM-DD"),
    },
    {
      label: `Friday\n ${moment(sD).day(5).format("DD")}`,
      value: moment(sD).day(5).format("YYYY-MM-DD"),
    },
    {
      label: `Saturday\n ${moment(sD).day(6).format("DD")}`,
      value: moment(sD).day(6).format("YYYY-MM-DD"),
    },
  ];

  
  let numberOfDays = days.map((item) => item?.value);
  const difference = numberOfDays.filter((item) => !tempValue.includes(item));

  function handleDays(checkedValues) {
    const data = difference?.filter((item) => checkedValues?.includes(item));
    
    if (data?.length > 0) {
      message.error("Schedule not available for this day");
    } else {
      showTimeSlot.filter((item) => {
        if (!checkedValues?.includes(item)) {
          setuncheckedListDisable(() => [...uncheckedListDisable, item]);
        }
      });

      setShowTimeSlot(checkedValues);
      const slots = slot?.filter(
        (item) =>
          !checkedValues?.includes(item?.startDateTime?.substring(0, 10))
      );
      const timeSlots = slots?.map((item) => item.id);
      setSelectedSlots([...selectedSlots, timeSlots]);
    }
  }

  const difference1 = numberOfDays.filter((item) => !tempValue1.includes(item));
  

  function handleEnabled(checkedValues) {
    const data = difference1?.filter((item) => checkedValues?.includes(item));
    if (data?.length > 0) {
      message.error("No Disabled Schedule available for this day");
    } else {
      stauses.filter((item) => {
        if (!checkedValues?.includes(item)) {
          setuncheckedList(() => [...uncheckedList, item]);
        }
      });

      setStatuses(checkedValues);
      const slots = slotEnable?.filter(
        (item) =>
          !checkedValues?.includes(item?.startDateTime?.substring(0, 10))
      );
      const timeSlots = slots?.map((item) => item.id);
      setSelectedSlots([...selectedSlots, timeSlots]);
    }
  }

  const addSubtract = (data) => {
    const params = getParamsFromUrl(history);
    let timeRange, startDate, endDate;
    timeRange = moment(params.startDate)[data](1, "week");
    startDate = timeRange.startOf("week").format("YYYY-MM-DD");
    endDate = timeRange.endOf("week").add(1,'d').format("YYYY-MM-DD");
    setDateTemp(`${startDate}`);
    pushParamsToUrl(history, { startDate, endDate });
  };

  const addSubtractEnable = (data) => {
    const params = getParamsFromUrl(history);
    let timeRange, startDate, endDate;
    timeRange = moment(params.startDate)[data](1, "week");
    startDate = timeRange.startOf("week").format("YYYY-MM-DD");
    endDate = timeRange.endOf("week").format("YYYY-MM-DD");
    setDateTemp(`${startDate}`);
    pushParamsToUrl(history, { startDate, endDate });
  };

  const handleChange=(e,index)=>{
   if(e === 0){
     let newData = [...tempraryArray];
     newData[index]=1;
    setTempraryArray(newData);
   }else{
     let newData = [...tempraryArray];
     newData[index] =0;
     setTempraryArray(newData);
   }
  }
  
  const handleUpdate=(data)=>{
    var filtered = data.filter(function(x) {
      return x !== undefined;
  });
    let selectedDays =[];
    if(days?.length >0){
    days.filter((item,index)=> {
      if(data[index] == 0 || data[index] == 1){
        selectedDays[index] = item.value;
      }
    }
    )
  }
  
let totalIds=[];
for(let i=0; i<=selectedDays.length-1; i++){
  let ids=[];
  const extratedValue = slotData.filter(item=> selectedDays[i] == item.value);
  for(let j=0; j<=extratedValue.length-1; j++){
    ids.push(extratedValue[j]?.id);
  }
  totalIds.push(ids);
}
let zeroIndex =[];
let oneIndex=[];
for(let i=0; i<=data?.length-1; i++){
  if(data[i] == 0){
    zeroIndex.push(i);
  }else if(data[i] == 1){
    oneIndex.push(i);
  }
}
console.log(slotData);
// console.log(zeroIndex);
let inactiveArrayList=[];
for(let i=0; i<=zeroIndex.length-1; i++){
  let newData = totalIds[zeroIndex[i]];
  for(let j=0; j<=newData.length-1; j++){
    inactiveArrayList.push(newData[j]);
  }
}
let activeArrayList=[];
for(let i=0; i<=oneIndex.length-1; i++){
  let newData = totalIds[oneIndex[i]];
  for(let j=0; j<=newData.length-1; j++){
    activeArrayList.push(newData[j]);
  }
}

console.log(inactiveArrayList);
console.log(activeArrayList);

  
  const CREDENTIALS = {
    url: `/schedules/timeslots`,
    method: "put",
    data: { schedules: activeArrayList, active: 1 },
  };

  const CREDENTIALS1 = {
    url: `/schedules/timeslots`,
    method: "put",
    data: { schedules: inactiveArrayList, active: 0 },
  };

  API.common(CREDENTIALS)
    .then((response) => {
      API.common(CREDENTIALS1)
      .then((response) => {
        dispatch({
          type: "MARKET_MODAL",
          payload: { id: -1, timezone: "" },
        });
        history.push({ pathname: "/markets" });      
      })
      .catch((err) => console.log(err));
    })
    .catch((err) => console.log(err));
  
  }

    return (
    <>
      <div className="p-20 white-bc title-box-shadow">
        <PageHeader className="mb-10" title="Manage Schedule" />
      </div>
      <div className="p-20 white-bc" style={{ margin: "20px" }}>
      <div style={{ display: "flex",justifyContent:"center" }}>
      <Button
            onClick={() => addSubtract("subtract")}
            size="large"
            icon={<LeftOutlined />}
            style={{ border: 0 }}
          />
            <p
              style={{
                border: "1px solid #d9d9d9",
                padding: "5px 15px",
                marginLeft: "10px",
              }}
            >
              {params?.startDate}
            </p>
            <p
              style={{
                border: "1px solid #d9d9d9",
                padding: "5px 15px",
                marginLeft: "10px",
              }}
            >
              {params?.endDate}
            </p>
            <Button
            onClick={() => addSubtract("add")}
            size="large"
            icon={<RightOutlined />}
            style={{ border: 0 }}
          />
          </div>
          
        <div style={{ marginBottom: "45px" }}>
          <p>
            <b>
              Deselect to Delete the Schedule for{" "}
              {isModalVisible?.alias ? isModalVisible?.alias : "NA"}:
            </b>
          </p>
          
         
          <div>
      {days?.map((day, index) => (
        <>
          <input
            type="checkbox"
            checked={tempraryArray[index] == 1? true:false}
            id={index}
            name={day.label}
            onChange={()=>handleChange(tempraryArray[index],index)}
            disabled={disabledArray[index] == 0? true:false}
          />
          <label htmlFor={index} style={{color:disabledArray[index]==0? '#d9d9d9':'#000000'}}>{days[index]?.label}</label>
        </>
      ))}
    </div>
         
        </div>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          type="primary"
          danger
          style={{ marginLeft: "5px" }}
          onClick={() => handleUpdate(tempraryArray)}
        >
          Save
        </Button>
      </div>
  
      
    </>
  );
}
