import React, { useState, useEffect } from 'react';
import { getPricings, getServices, getVehicleTypes, getMarkets,getCategories } from 'services/common';
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "hooks";
import { Table, Button, Tooltip } from "antd";
import { PageHeader, Row, Col, Form, Radio, Select, Typography } from "antd";
import { getParamsFromUrl, pushParamsToUrl } from "helpers/history";
import { useHistory, useLocation } from "react-router-dom";
import { isArray } from 'lodash';

const { Option } = Select;


export default function DTPrices() {
  const { userStatus } = useQueryParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { lastUpdatedTime } = useSelector((state) => state.services);
  const { pricings = [],productsCategories } = useSelector((state) => state?.model);
  const { markets = [] } = useSelector((state) => state?.model);
  const { Services = [] } = useSelector((state) => state?.model);
  const { vehicleTypes = [] } = useSelector((state) => state?.model);

  const [pagination, setPagination] = useState({
    all: true,
  });
  const history = useHistory();
  const params = getParamsFromUrl(history);
  const location = useLocation();


  const [selectedMarket, setSelectedMarket] = useState(
    "Select Market"
  );

  const [selectedVehicleType, setSelectedVehicleType] = useState(
    "Select Pricing Tier"
  );

  const [selectedService, setSelectedService] = useState(
    "Select Service"
  );
  const [selectedCategory, setselectedCategory] = useState("Select Category");
  const [DateSevice, setDataSevice]=useState([]);


  useEffect(() => {
    const params = getParamsFromUrl(history);
    const queryParams = { ...params };
    if (queryParams?.markets?.length > 0) {
      setSelectedMarket(parseInt(queryParams?.markets?.[0]))
    }
    if (queryParams?.services?.length > 0) {
      setSelectedService(parseInt(queryParams?.services?.[0]))
    }
    if (queryParams?.vehicleTypes?.length > 0) {
      setSelectedVehicleType(parseInt(queryParams?.vehicleTypes?.[0]))
    }
    dispatch(getVehicleTypes({ types: [1] })).then(res => res);
    dispatch(getServices({ all: true, active:[0,1] })).then(res => res);
    dispatch(getMarkets({ all: true })).then(res => res);
    dispatch(getCategories({all:true}));
    dispatch(getPricings(queryParams))
      .then((response) => {
        setPagination({
          ...pagination,
          total: response?.meta?.pagination?.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    
  }, [location]);

  useEffect(()=>{
    setDataSevice(Services);
  },[])


  function handleMarketChange(data) {
    pushParamsToUrl(history, {
      ...params,
      markets: data ? [data] : "",
      services:"",
      categories:"",
      vehicleTypes:""
    });
    setSelectedMarket(data);
    setSelectedService("Select Service");
    setSelectedVehicleType("Select Pricing Tier");
    setselectedCategory("Select Category");
    handleTableChange(data, "Select Service", "Select Pricing Tier","Select Category");
  }

  function handleServiceChange(data) {
    pushParamsToUrl(history, {
      ...params,
      services: data ? [data] : "",
      vehicleTypes:""
    });
    setSelectedService(data);
    setSelectedVehicleType("Select Pricing Tier");
    handleTableChange(selectedMarket, data, "Select Pricing Tier",setselectedCategory);
  }

  function handleVehicleTypeChange(data) {
    pushParamsToUrl(history, {
      ...params,
      vehicleTypes: data ? [data] : "",
    });
    setSelectedVehicleType(data);
    handleTableChange(selectedMarket, selectedService, data,setselectedCategory);
  }

  function handleCategory(data) {
    pushParamsToUrl(history, {
      ...params,
      categories: data ? [data] : "",
      services:"",
      vehicleTypes:""
    });
    setselectedCategory(data);
    let serviceData = Services?.filter(item=> data == item.category.id);
	setDataSevice(serviceData);
  setSelectedService("Select Service");
  setSelectedVehicleType("Select Pricing Tier");
    handleTableChange(selectedMarket, "Select Service", "Select Pricing Tier",data);
  }

  const handleTableChange = (markets, services, vehicleTypes, category) => {
    if(typeof markets != 'object'){
    pushParamsToUrl(history, {
      ...params,
      markets: markets != 'Select Market' ? [markets] : [],
      services: services != 'Select Service' ? [services] : [],
      vehicleTypes: vehicleTypes != 'Select Pricing Tier' ? [vehicleTypes] : [],
      categories:category!="Select Category"?[category]:[],
      page:1,
      limit:10
    });
    setLoading(true);
  }

  };

  //gets service type



  //get name with icon
  const getServiceName = (id, record) => {
    return record?.service?.name

  }

  const getVehicleTypeName = (id, record) => {
    let tier ='';
    if(record?.vehicleType?.name === "Sedan" || record?.vehicleType?.name === "Coupe" || record?.vehicleType?.name ==="Convertible"|| record?.vehicleType?.name ==="Hatchback"|| record?.vehicleType?.name ==="Wagon"){
			tier = 'Tier-1';
		}
		if(record?.vehicleType?.name === "SUV/Small Pick Up/Minivans" || record?.vehicleType?.name === "SUV" || record?.vehicleType?.name ==="Small Pick Up" || record?.vehicleType?.name ==="Minivans"){
			tier = 'Tier-2';
    }
		if(record?.vehicleType?.name === "Cargo Van" || record?.vehicleType?.name === "Chassis"){
			tier = 'Tier-3';
		}
		if(record?.vehicleType?.name === "Heavy Duty Truck"){
			tier = 'Tier-4';
		}
    return tier;
  }

  const getMarketName = (id, record) => {
    return record?.market?.alias !== null?  record?.market?.alias:'NA';

  }

  const getPrice = (id, record) => {
    return record?.price

  }

  
  
  function handleEdit(data) {
    dispatch({
      type: "SHOW_PRICING_FORM",
      payload: data,
    });
  }

  //table data that needs to be shown
  const columns = [
    {
      title: "SERVICE",
      dataIndex: "service.name",
      key: "id",
      render: (id, record) => getServiceName(id, record),
      width:"15%"
    },
    {
      title: "MARKET",
      dataIndex: "market.city",
      key: "id",
      render: (id, record) => getMarketName(id, record)
    },
    {
      title: "Pricing Tier",
      dataIndex: "vehicleType.name",
      key: "id",
      render: (id, record) => getVehicleTypeName(id, record)
    },
    {
      title: "PRICE",
      dataIndex: "price",
      key: "id",
      render: (id, record) => getPrice(id, record)
    },
    {
      title: "APP DISPLAY",
      dataIndex: "status",
      key: "id",
      render: (id, record) => record?.status === 1?"Show":"Hide"
    },
    {
      title: "UPDATE",
      dataIndex: "id", // to get data from api
      key: "id", // for column sorting key
      sorter: false,
      render: (id, record) => (
        <Button type="link" onClick={() => handleEdit(record)}>
          Update
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Row type="flex" align="middle">
        <Col span={5}>
          <Form.Item label="Markets" className={"search-label"}>
            <Select
              showSearch
              name="market"
              style={{ width: "100%" }}
              value={selectedMarket}
              placeholder={"Select Market"}
              allowClear={true}
              optionFilterProp="children"
              onChange={(e) => handleMarketChange(e)}
            >
              {markets.map(({ id, city,alias }) => (
                <Option key={id} value={id}>
                  {alias? alias:'NA'}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5} className="ml-20">
          <Form.Item label="Category" className={"search-label"}>
          <Select
              showSearch
              name="servicecategory"
              style={{ width: "100%" }}
              value={selectedCategory}
              placeholder={"Select Category"}
              allowClear={true}
              optionFilterProp="children"
              onChange={(e) => handleCategory(e)}
            >
              {productsCategories?.length >0 && productsCategories.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
            </Form.Item>
          </Col>
        <Col span={5} className="ml-20">
          <Form.Item label="Service" className={"search-label"}>
            <Select
              showSearch
              name="category"
              style={{ width: "100%" }}
              placeholder={"Select Service"}
              value={selectedService}
              allowClear={true}
              optionFilterProp="children"
              onChange={(e) => handleServiceChange(e)}
            >
              {DateSevice?.length >0 && DateSevice?.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} className="ml-20">
          <Form.Item label="Pricing Tier" className={"search-label"}>
            <Select
              showSearch
              name="team_members"
              style={{ width: "100%" }}
              placeholder={"Select Pricing Tier"}
              allowClear={true}
              value={selectedVehicleType}
              optionFilterProp="children"
              onChange={(e) => handleVehicleTypeChange(e)}
            >
              {vehicleTypes.map(({ id, name, tier }) => (
                <Option key={id} value={id}>
                  {`${tier}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Table
        className="pricings_table"
        dsize="small"
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={pricings}
        locale={{ emptyText: "No Pricings available" }}
        loading={loading}
        style={{ marginTop: "50px" }}
        onChange={handleTableChange}
      />
    </div>
  )
}
