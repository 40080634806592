import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Radio, Row, Typography } from "antd";
import { getSelectedJobsBasedOnTime, getSelectedJobs } from "services/common";
import { dateDisplay as DF } from "helpers/date-formatter";
import { getParamsFromUrl, pushParamsToUrl } from "helpers/history";
import { timeLineChange } from "helpers/timeLine";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = "DD MMM YYYY";

function IsCustomDateRange({ timeLineType }) {
  if (timeLineType === "custom") {
    return (
      <div className="mt-5 center-align " style={{ fontSize: "12px" }}>
        <Text type="warning">*Custom date range</Text>
      </div>
    );
  }
  return null;
}

export default function JobsTimeline() {
  let data = JSON.parse(localStorage.getItem('userInfoData'));
  let adminUserType = data?.userType?.id;
let adminMarket = [];

if(adminUserType === 4){
	adminMarket = data?.markets;
}
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [dateRange, setDateRange] = useState([]);
  const [timeLineType, setTimeLineType] = useState("week");
  const { JobBasedOnTime = [] } = useSelector((state) => state?.model);
  const [jobStatus, setJobStatus] = useState();
  let { jobkey, saveJobform,lastUpdatedTime } = useSelector((state) => state?.job);

  useEffect(() => {
    const params = getParamsFromUrl(history);
    params.categories=''
    if (
      params &&
      params.startDate === undefined &&
      params.endDate === undefined
    ) {
      pushParamsToUrl(history, {
        ...params,
        timeLine: timeLineType,
        startDate: moment().startOf("week").format("YYYY-MM-DD"),
        endDate: moment().endOf("week").format("YYYY-MM-DD"),
        
      });
    }
    if (params?.statuses?.length > 0) {
      pushParamsToUrl(history, {
        ...params,
      });
    } 
    else {
      if(adminUserType === 4){
      pushParamsToUrl(history, {
        ...params,
        statuses: ["COMPLETED"],
        markets:adminMarket
      });}else{
        pushParamsToUrl(history, {
          ...params,
          statuses: [""]
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = getParamsFromUrl(history);
    const queryParams = { ...params };
    delete queryParams?.timeLine;
    delete queryParams?.statuses;
    //queryParams.markets = adminMarket;
    queryParams.all = true;
    setTimeLineType(params.timeLine);

    setDateRangeData({
      startDate: params.startDate,
      endDate: params.endDate,
    });

    dispatch(getSelectedJobsBasedOnTime(queryParams));

    const queryParams1 = { ...params };
    delete queryParams1?.timeLine;
    queryParams1.all = true;
    if(queryParams1.statuses?.length>0){
    if(queryParams1?.statuses[0] == ""){
      delete queryParams1.statuses;
    }
  }
    if (jobkey) {
      queryParams1.statuses = [jobkey];
    }
    console.log(queryParams1,"PARams")
    dispatch(getSelectedJobs(queryParams1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, jobkey, saveJobform,lastUpdatedTime]);

  function handleTimelineChange(data) {
    let timeLineType = data.target.value;
    const dates = timeLineChange(timeLineType);
    setTimeLineType(timeLineType);
    setDateRangeData(dates);
    pushParamsToUrl(history, { ...dates, timeLine: timeLineType });
  }

  function handleDateRangePickerChange(data) {
    setDateRange(data);
    setTimeLineType("custom");
    if (data && data.length > 0) {
      let startDate = moment(data[0]).format("YYYY-MM-DD");
      let endDate = moment(data[1]).format("YYYY-MM-DD");
      pushParamsToUrl(history, {
        timeLine: "custom",
        startDate,
        endDate,
      });
    }
  }

  function setDateRangeData(dates) {
    const dateRange = [moment(DF(dates.startDate)), moment(DF(dates.endDate))];
    setDateRange(dateRange);
  }

  function addOrSubtractDatesFromSelectedDates(method) {
    /**
     *
     * @param {*subtract or add} method
     */
    const params = getParamsFromUrl(history);
    let timeLine, timeRange, startDate, endDate;

    timeLine = params.timeLine;
    /**
     * timeLine will be year, month, week or day
     */
    timeRange = moment(params.startDate)[method](1, timeLine);
    startDate = timeRange.startOf(timeLine).format("YYYY-MM-DD");
    endDate = timeRange.endOf(timeLine).format("YYYY-MM-DD");
    pushParamsToUrl(history, { startDate, endDate });
  }

  return (
    <Row type="flex" justify="space-between" align="top">
      <Col span={3}>
        <Title level={4}>Jobs</Title>
      </Col>
      <Col span={10} style={{ textAlign: "center" }}>
        {timeLineType !== "custom" && (
          <Button
            disabled={timeLineType === "custom" ? true : false}
            size="large"
            icon={<LeftOutlined />}
            style={{ border: 0 }}
            onClick={() => addOrSubtractDatesFromSelectedDates("subtract")}
          />
        )}
        <Radio.Group
          value={timeLineType}
          size="large"
          onChange={handleTimelineChange}
        >
          <Radio.Button value="year">Year</Radio.Button>
          <Radio.Button value="month">Month</Radio.Button>
          <Radio.Button value="week">Week</Radio.Button>
          <Radio.Button value="day">Today</Radio.Button>
        </Radio.Group>
        {timeLineType !== "custom" && (
          <Button
            disabled={timeLineType === "custom" ? true : false}
            size="large"
            icon={<RightOutlined />}
            style={{ border: 0 }}
            onClick={() => addOrSubtractDatesFromSelectedDates("add")}
          />
        )}
      </Col>
      <Col span={5}>
        <RangePicker
          value={dateRange}
          format={dateFormat}
          separator="to"
          onChange={handleDateRangePickerChange}
        />
        <IsCustomDateRange timeLineType={timeLineType} />
      </Col>
    </Row>
  );
}
