import _ from 'lodash';
const LOCAL_STORAGE_KEY = 'userInfoData';
const CLIENT_KEY = 'clientData';

class Session {
	constructor() {
		let userInfo = localStorage.getItem(LOCAL_STORAGE_KEY);
		if (!_.isEmpty(userInfo) && userInfo !== 'undefined') {
			const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
			this.token = data?.authToken;
			this.userId = data?._id;
			this.role = data?.userType;
			this.clientId = data?.client?._id;
		}
	}

	isLoggedIn() {
		return !!this.token;
	}

	setToken(userData) {
		let data = JSON.parse(userData);
		this.token = data?.authToken;
		this.userId = data?._id;
		this.role = data?.userType;
		this.clientId = data?.client?._id;
		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
	}

	setClient(data) {
		localStorage.setItem(CLIENT_KEY, JSON.stringify(data));
	}

	getClientData() {
		let USER_DATA = localStorage.getItem(CLIENT_KEY);
		if (USER_DATA) {
			return JSON.parse(USER_DATA);
		} else {
			return null;
		}
	}

	getUserData() {
		let USER_DATA = localStorage.getItem(LOCAL_STORAGE_KEY);
		if (USER_DATA) {
			return JSON.parse(USER_DATA);
		} else {
			return null;
		}
	}

	removeToken() {
		this.token = null;
		this.userId = null;
		this.role = null;
		this.clientId = null;
		localStorage.removeItem(LOCAL_STORAGE_KEY);
		localStorage.clear();
	}

	removeItem(key) {
		localStorage.removeItem(key);
	}

	logout() {
		this.removeToken();
	}
}

const session = new Session();
Object.freeze(session);
export { session };
