import React from "react";
import { Drawer } from "antd";
import { EditForm, EditFormStatus, JobsForm,APPLYCOUPONSFORM } from "forms/job";
import { useDispatch, useSelector } from "react-redux";
import { timeStamp } from "helpers/date-formatter";

function CUJobs() {
  const dispatch = useDispatch();
  const {
    jobFormVisible,
    editableJob,
    jobEditFormVisible,
    editableEditJob,
    jobEditFormJobStatusVisible,
    editableEditJobStatus,
    editApplyForm,
    applyForm
  } = useSelector((state) => state.job);

  
  function onClose() {
    dispatch({
      type: "HIDE_JOB_FORM",
      payload: {},
    });
    // dispatch({
    //   type: "USER_JOB_LAST_UPDATED_TIME",
    //   payload: timeStamp(),
    // });
  }

  function onCloseEdit() {
    dispatch({
      type: "HIDE_EDIT_JOB_FORM",
      payload: {},
    });
    dispatch({
      type: "USER_JOB_LAST_UPDATED_TIME",
      payload: timeStamp(),
    });
  }

  function onCloseEditStatus() {
    dispatch({
      type: "HIDE_EDIT_JOB_STATUS_FORM",
      payload: {},
    });
    dispatch({
      type: "USER_JOB_LAST_UPDATED_TIME",
      payload: timeStamp(),
    });
  }

  function onCloseAppyCoupons() {
    dispatch({
      type: "HIDE_APPLY_COUPONS",
      payload: {},
    });
    dispatch({
      type: "APPLY_COUPONS_LAST_UPDATED_TIME",
      payload: timeStamp(),
    });
  }

  //show edit job, job status, and job details from in drawer
  return (
    <div>
      <div style={{ display: "flex" }}></div>
      <div>
        {jobEditFormJobStatusVisible === true && (
          <Drawer
            title={`Job Information`}
            width={750}
            onClose={onCloseEditStatus}
            visible={jobEditFormJobStatusVisible}
            destroyOnClose={true}
            closable={false}
            maskClosable={true}
          >
            <EditFormStatus
              onClose={onCloseEditStatus}
              editableEditJob={editableEditJobStatus}
            />
          </Drawer>
        )}
        {jobFormVisible === true && (
          <Drawer
            title={`Booking ID: ${editableJob?.reference}`}
            width={550}
            onClose={onClose}
            visible={jobFormVisible}
            destroyOnClose={true}
            closable={false}
            maskClosable={true}
          >
            <JobsForm onClose={onClose} editableUser={editableJob} />
          </Drawer>
        )}
        {jobEditFormVisible === true && (
          <Drawer
            title="Job Information"
            width={750}
            onClose={onCloseEdit}
            visible={jobEditFormVisible}
            destroyOnClose={true}
            closable={false}
            maskClosable={true}
          >
            <EditForm onClose={onCloseEdit} editableUser={editableEditJob} />
          </Drawer>
        )}
        {editApplyForm === true &&(
           <Drawer
           title="Edit Booking Summary"
           width={550}
           onClose={onCloseAppyCoupons}
           visible={editApplyForm}
           destroyOnClose={true}
           closable={false}
           maskClosable={true}
         >
           <APPLYCOUPONSFORM onClose={onCloseAppyCoupons} applyForm={applyForm}/>
         </Drawer>
        )}
      </div>
    </div>
  );
}

export default CUJobs;
