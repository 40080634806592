const initialState = {
	technicianFormVisible: false,
	editableTechnician: {},
	lastUpdatedTime: ''
};

export function technician(state = initialState, action) {
	if (action.type === 'SHOW_TECHNICIAN_FORM') {
		return {
			...state,
			technicianFormVisible: true,
			editableTechnician: action.payload,
			
		};
	} else if (action.type === 'HIDE_TECHNICIAN_FORM') {
		return { ...state, technicianFormVisible: false, editableTechnician: {} };
	} else if (action.type === 'TECHNICIAN_STATUS_CHANGE_FORM') {
		let data = state.editableTechnician;
		data.status = action.payload;
		return {
			...state,
			editableTechnician: data,
		};
	} else if (action.type === 'TECHNICIAN_LAST_UPDATED_TIME') {
		return { ...state, lastUpdatedTime: action.payload };
	} else {
		return state;
	}
}
