const initialState = {
	recent: '/login',
};

export function history(state = initialState, action) {
	if (action.type === 'SET_HISTORY_RECENT') {
		return { ...state, recentlyVisitedUrl: action.payload };
	} else if(action.type === 'SET_PREVIOUS_URL'){
		return { ...state, previousVisitedUrl: action.payload };
	} else {
		return state;
	}
}
