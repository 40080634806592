import qs from 'qs';

export function pushParamsToUrl(history, params) {
	const CURRENT_PARAMS = getParamsFromUrl(history);
	const modifiedParams = qs.parse(qs.stringify(params));
	const newParams = findObjectDifference(CURRENT_PARAMS, modifiedParams);

	if (Object.keys(newParams).length > 0) {
		const MODIFIED_PARAMS = { ...CURRENT_PARAMS, ...newParams };
		history.push({
			search: qs.stringify(MODIFIED_PARAMS, { addQueryPrefix: true }),
		});
	}
}

export function getParamsFromUrl(history) {
	return qs.parse(history.location.search, {
		ignoreQueryPrefix: true,
	});
}

function findObjectDifference(old, current) {
	const newParams = {};
	Object.entries(current).forEach(([key]) => {
		if (old[key] != current[key]) {
			newParams[key] = current[key];
		}
	});
	return newParams;
}
