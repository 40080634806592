import React from "react";
import { PageHeader, Col, Row, Button } from "antd";
import {
    ToolOutlined,
    UserOutlined,
    TeamOutlined,
  } from "@ant-design/icons";
import CUDiscount from "components/pages/discount/CUDiscount";
import DTDiscount from "components/pages/discount/DTDiscount";

function Discount() {

    function changeDiscount() {
        console.log("Hello");
      }
  return (
    <div>
    <div className="p-20 white-bc title-box-shadow">
      <PageHeader className="mb-10" title="Discount" />
    </div>
   <div className="p-20 pt-0">
    <CUDiscount/>
    <DTDiscount/>
   </div>
    </div>
  )
}

export default Discount;
