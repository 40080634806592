import React from 'react';
import { PageHeader } from 'antd';
import { DTProfile} from '../components/pages/profile';
import {CUProfile} from 'components/pages/profile';

function Profile() {
    return (
        <div>
              <div className="p-20 white-bc title-box-shadow">
				<PageHeader className="mb-10" title="Profile" />
			</div>
            
            <div className="p-20 pt-0">
                {/* Edit profile page Compenent */}
                <CUProfile/>
                {/* profile page data Coemponent */}
                <DTProfile/>
			</div>
        </div>
    )
}

export default Profile;
