export function convertAddressComponents(addrComp) {
	console.log(addrComp);
	let newAddr = {};
	addrComp.forEach(el => {
		if (el.types.includes('locality')) {
			newAddr.cityName = el.long_name;
		} else if (el.types.includes('administrative_area_level_1')) {
			newAddr.stateName = el.long_name;
		} else if (el.types.includes('country')) {
			newAddr.countryName = el.long_name;
		}
	});
	return newAddr;
}
