import React, { useState, useEffect } from "react";
import { Button, PageHeader,Modal,Calendar } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { API } from "services";
import { useHistory } from "react-router";
import { timeStamp } from "helpers/date-formatter";
import { getParamsFromUrl, pushParamsToUrl } from "helpers/history";

export default function ScheduleNew() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentDate, setCurrentDate] = useState(moment());
  const { isModalVisible, lastUpdatedTime } = useSelector(
    (state) => state.marketpreference
  );
  const [slot, setSlot] = useState([]);
  const [temprarySlot, setTempSlot] = useState([]);
  const [weekend, setWeekend] = useState(false);
  const [weekday, setWeekday] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [Visible, setIsModalVisible] = useState(false);
  const [tempDates,setTempDates]=useState([]);
  const [ifOk,setIfOk]=useState(false);

  const showModal = (data,values) => {
    setTempDates(values.map(item=>item.date));
    setIsModalVisible(true);
  };

  const handleOk = () => {
    //non active data
    function getDifference(array1, array2) {
      return array1.filter(object1 => {
        return !array2.some(object2 => {
          return object1.date === object2.date;
        });
      });
    }
    let nonActive = getDifference(temprarySlot,slot);
    let tempDataBookings=[];
    let noActiveRealDate = nonActive?.map(item=>{
      tempDataBookings.push(item?.date);
      let newItem={date:item.date,active:0}
      return newItem;
    })
    
    let valueCopy1;
    
    valueCopy1 = { market: isModalVisible?.id, dates: noActiveRealDate };
    if(nonActive?.length >0){
      const CREDENTIALS1 = {
            url: `/schedules`,
            method: "put",
            data:valueCopy1
          };
    
          API.common(CREDENTIALS1)
            .then((response) => {
              dispatch({
                type: "SHOW_NEW_LAST_UPDATED_TIME",
                payload: timeStamp,
              });
              getData();
            })
            .catch((err) => {console.log(err);  getData()});
    }
  //active data
      function getUniqueListBy(arr, key) {
          return [
            ...new Map(
              arr.map((item) => [item[key], item])
            ).values(),
          ];
        }
  
        let reverseSlot = slot.reverse();
        let notActiveData = getUniqueListBy(reverseSlot,'date');
        let notActiveFilter = notActiveData?.filter(item=> !item?.hasOwnProperty('id'));
        let valueCopy = { market: isModalVisible?.id, dates: notActiveFilter };
        console.log(valueCopy);
        const CREDENTIALS1 = {
          url: `/schedules`,
          method: "put",
          data:valueCopy
        };
  
        API.common(CREDENTIALS1)
          .then((response) => {
            dispatch({
              type: "SHOW_NEW_LAST_UPDATED_TIME",
              payload: timeStamp,
            });
            getData();
            setIfOk(true);
            setIsModalVisible(false);
          })
          .catch((err) => {console.log(err);  getData();});
  
   
    
  };

  const handleCancel = () => {
   
    let tempSlotData=[...temprarySlot];
    console.log(tempSlotData,"123");
    let AvailableBookings =tempSlotData.filter((item) => item.bookings == true);
    //non active data
  function getDifference(array1, array2) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.date === object2.date;
      });
    });
  }
  let nonActive = getDifference(temprarySlot,slot);
  let tempDataBookings=[];
  let noActiveRealDate = nonActive?.map(item=>{
    tempDataBookings.push(item?.date);
    let newItem={date:item.date,active:0}
    return newItem;
  })
  
  let valueCopy1;
  
  let filterBookingsActive = AvailableBookings?.filter(item=> tempDataBookings.includes(item.date));
  let noData = AvailableBookings?.map(item=> item.date);
  let lastNoSelected = noActiveRealDate.filter(item=>!noData.includes(item.date));
    valueCopy1 = { market: isModalVisible?.id, dates: lastNoSelected };
    if(nonActive?.length >0){
      const CREDENTIALS1 = {
            url: `/schedules`,
            method: "put",
            data:valueCopy1
          };
    
          API.common(CREDENTIALS1)
            .then((response) => {
              dispatch({
                type: "SHOW_NEW_LAST_UPDATED_TIME",
                payload: timeStamp,
              });
              getData();
            })
            .catch((err) => {console.log(err);  getData()});
    }
  //active data
      function getUniqueListBy(arr, key) {
          return [
            ...new Map(
              arr.map((item) => [item[key], item])
            ).values(),
          ];
        }
  
        let reverseSlot = slot.reverse();
        let notActiveData = getUniqueListBy(reverseSlot,'date');
        let notActiveFilter = notActiveData?.filter(item=> !item?.hasOwnProperty('id'));
        let valueCopy = { market: isModalVisible?.id, dates: notActiveFilter };
        console.log(valueCopy);
        const CREDENTIALS1 = {
          url: `/schedules`,
          method: "put",
          data:valueCopy
        };
  
        API.common(CREDENTIALS1)
          .then((response) => {
            dispatch({
              type: "SHOW_NEW_LAST_UPDATED_TIME",
              payload: timeStamp,
            });
            
          })
          .catch((err) => {console.log(err);  getData();});
          setIfOk(false);
          setIsModalVisible(false);
  };

  useEffect(()=>{
    getData();
  },[])

  useEffect(() => {
    getData();
  }, [currentDate, lastUpdatedTime]);

  function getData() {
  
    const CREDENTIALS = {
      url: `/schedules?markets=[${isModalVisible?.id}]&startDate=${
        params?.startDate == undefined
          ? moment(currentDate).startOf("M").format("YYYY-MM-DD")
          : moment(currentDate).startOf("M").format("YYYY-MM-DD")
      }&endDate=${
        params?.endDate == undefined
          ? moment(currentDate).endOf("M").add(1, "day").format("YYYY-MM-DD")
          : moment(currentDate).endOf("M").add(1, "day").format("YYYY-MM-DD")
      }&active=[1]&bookingsInfo=${true}`,
      method: "get",
    };

    API.common(CREDENTIALS)
      .then((response) => {
        setSlot(response?.data?.schedules);
        setTempSlot(response?.data?.schedules);
      })
      .catch((err) => console.log(err));
  }

  const handleClear = () => {
    const CREDENTIALS = {
      url: `/schedules?markets=[${isModalVisible?.id}]&startDate=${
        params?.startDate == undefined
          ? moment(currentDate).startOf("M").format("YYYY-MM-DD")
          : moment(currentDate).startOf("M").format("YYYY-MM-DD")
      }&endDate=${
        params?.endDate == undefined
          ? moment(currentDate).endOf("M").add(1, "day").format("YYYY-MM-DD")
          : moment(currentDate).endOf("M").add(1, "day").format("YYYY-MM-DD")
      }&active=[1]&bookingsInfo=${true}`,
      method: "get",
    };

    API.common(CREDENTIALS)
      .then((response) => {
        setSlot(response?.data?.schedules);
      })
      .catch((err) => console.log(err));
    
    setWeekday(false);
    setWeekend(false);
  };

 

  //handle Submit -------------------------------------------------------------------
  const params = getParamsFromUrl(history);
  function handleSubmit() {
    let tempSlotData=[...temprarySlot];
    console.log(tempSlotData,"123");
    let AvailableBookings =tempSlotData.filter((item) => item.bookings == true);
    //non active data
  function getDifference(array1, array2) {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.date === object2.date;
      });
    });
  }
  let nonActive = getDifference(temprarySlot,slot);
  let tempDataBookings=[];
  let noActiveRealDate = nonActive?.map(item=>{
    tempDataBookings.push(item?.date);
    let newItem={date:item.date,active:0}
    return newItem;
  })
  
  let valueCopy1;
  
  let filterBookingsActive = AvailableBookings?.filter(item=> tempDataBookings.includes(item.date));
  let noData = AvailableBookings?.map(item=> item.date);
  let lastNoSelected = noActiveRealDate.filter(item=>!noData.includes(item.date));
  
  
if(filterBookingsActive?.length > 0 ){
  showModal(true,filterBookingsActive);
  return false;
}


  valueCopy1 = { market: isModalVisible?.id, dates: noActiveRealDate };
  if(nonActive?.length >0){
    const CREDENTIALS1 = {
          url: `/schedules`,
          method: "put",
          data:valueCopy1
        };
  
        API.common(CREDENTIALS1)
          .then((response) => {
            dispatch({
              type: "SHOW_NEW_LAST_UPDATED_TIME",
              payload: timeStamp,
            });
            
          })
          .catch((err) => {console.log(err);  getData()});
  }
//active data
    function getUniqueListBy(arr, key) {
        return [
          ...new Map(
            arr.map((item) => [item[key], item])
          ).values(),
        ];
      }

      let reverseSlot = slot.reverse();
      let notActiveData = getUniqueListBy(reverseSlot,'date');
      let notActiveFilter = notActiveData?.filter(item=> !item?.hasOwnProperty('id'));
      let valueCopy = { market: isModalVisible?.id, dates: notActiveFilter };
      console.log(valueCopy);
      const CREDENTIALS1 = {
        url: `/schedules`,
        method: "put",
        data:valueCopy
      };

      API.common(CREDENTIALS1)
        .then((response) => {
          dispatch({
            type: "SHOW_NEW_LAST_UPDATED_TIME",
            payload: timeStamp,
          });
          
        })
        .catch((err) => {console.log(err);  getData();});

  }


  //handle Single Select ------------------------------------------------------------
  function handleSelect(e) {
    let tempSlot = slot.filter(
      (item) => item.date == e.format("YYYY-MM-DD"));
      console.log(tempSlot);
    if (tempSlot?.length > 0) {
      let newSlot = slot.filter(
        (item) =>
          item.date != e.format("YYYY-MM-DD")
      );
      console.log(newSlot);
      setSlot(newSlot);
    } else {
      let tempMyDate = temprarySlot.filter(
        (item) =>
          item.date == e.format("YYYY-MM-DD")
      );
      if (tempMyDate?.length > 0) {
        setSlot([...slot, tempMyDate[0]]);
      } else {
        setSlot([
          ...slot,
          { date: e.format("YYYY-MM-DD"), active: 1 },
        ]);
      }
    }
  }

   //all weekdays select ----------------------------------------------
   const handleWeekdays = (e) => {
    let weekday = [];
    //all mondays
    var monday = moment(currentDate).startOf("month").day("monday");
    if (monday.date() > 7) monday.add(7, "d");
    var month = monday.month();
    while (month === monday.month()) {
      weekday.push({ date: monday.format('YYYY-MM-DD'), active: 1 });
      monday.add(7, "d");
    }
    //all tuesday
    var tuesday = moment(currentDate).startOf("month").day("tuesday");
    if (tuesday.date() > 7) tuesday.add(7, "d");
    var month = tuesday.month();
    while (month === tuesday.month()) {
      weekday.push({ date: tuesday.format('YYYY-MM-DD'), active: 1 });
      tuesday.add(7, "d");
    }
    //all wednesday
    var wednesday = moment(currentDate).startOf("month").day("wednesday");
    if (wednesday.date() > 7) wednesday.add(7, "d");
    var month = wednesday.month();
    while (month === wednesday.month()) {
      weekday.push({ date: wednesday.format('YYYY-MM-DD'), active: 1 });
      wednesday.add(7, "d");
    }
    //all thrusday
    var Thursday = moment(currentDate).startOf("month").day("thursday");
    if (Thursday.date() > 7) Thursday.add(7, "d");
    var month = Thursday.month();
    while (month === Thursday.month()) {
      weekday.push({ date: Thursday.format('YYYY-MM-DD'), active: 1 });
      Thursday.add(7, "d");
    }
    //all friday
    var friday = moment(currentDate).startOf("month").day("friday");
    if (friday.date() > 7) friday.add(7, "d");
    var month = friday.month();
    while (month === friday.month()) {
      weekday.push({ date: friday.format('YYYY-MM-DD'), active: 1 });
      friday.add(7, "d");
    }

    let newSlot = [...slot];
    for (let i = 0; i <= weekday?.length - 1; i++) {
      if (moment().month() === currentDate.month()) {
        if (moment().isAfter(weekday[i]?.date, "day") === false) {
          newSlot.push(weekday[i]);
        }
      } else if (moment().month() < currentDate.month()) {
        newSlot.push(weekday[i]);
      } else {
        newSlot = [];
      }
    }

    let tempWeekday = [];
    for (let i = 0; i <= weekday?.length - 1; i++) {
      if (moment().month() === currentDate.month()) {
        if (moment().isAfter(weekday[i]?.date, "day") === false) {
          tempWeekday.push(weekday[i]);
        }
      } else if (moment().month() < currentDate.month()) {
        tempWeekday.push(weekday[i]);
      } else {
        tempWeekday = [];
      }
    }

    if (e === true) {
      setSlot(newSlot);
      setWeekday(true);
    } else {
      let removeDataFromAdd = slot.filter(
        (ar) =>
          !tempWeekday.find(
            (rm) =>
              rm.date === ar.date
          )
      );
      setSlot(removeDataFromAdd);
      setWeekday(false);
    }
  };

  //all weekends select----------------------------------------------------------------
  const handleWeekends = (e) => {
    let weekends = [];
    var sunday = moment(currentDate).startOf("month").day("Sunday");
    if (sunday.date() > 7) sunday.add(7, "d");
    var month = sunday.month();
    while (month === sunday.month()) {
      weekends.push({ date: sunday.format('YYYY-MM-DD'), active: 1 });
      sunday.add(7, "d");
    }
    var Saturday = moment(currentDate).startOf("month").day("Saturday");
    if (Saturday.date() > 7) Saturday.add(7, "d");
    var month = Saturday.month();
    while (month === Saturday.month()) {
      weekends.push({ date: Saturday.format('YYYY-MM-DD'), active: 1 });
      Saturday.add(7, "d");
    }

    let newSlot = [...slot];
    for (let i = 0; i <= weekends?.length - 1; i++) {
      if (moment().month() === currentDate.month()) {
        if (moment().isAfter(weekends[i]?.date, "day") === false) {
          newSlot.push(weekends[i]);
        }
      } else if (moment().month() < currentDate.month()) {
        newSlot.push(weekends[i]);
      } else {
        newSlot = [];
      }
    }

    let tempweekends = [];
    for (let i = 0; i <= weekends?.length - 1; i++) {
      if (moment().month() === currentDate.month()) {
        if (moment().isAfter(weekends[i]?.date, "day") === false) {
          tempweekends.push(weekends[i]);
        }
      } else if (moment().month() < currentDate.month()) {
        tempweekends.push(weekends[i]);
      } else {
        tempweekends = [];
      }
    }
    console.log(newSlot);
    if (e === true) {
      setSlot(newSlot);
      setWeekend(true);
    } else {
      let removeDataFromAdd = slot.filter(
        (ar) =>
          !tempweekends.find(
            (rm) =>
              rm.date=== ar.date
          )
      );
      setSlot(removeDataFromAdd);
      setWeekend(false);
    }
  };

 
//disabled cells --------------------------------------------------------
  const handleDisabled = (current) => {
    let newCurrent = moment(current.format("YYYY-MM-DD"));
    let nextMonth = moment().endOf("M");

    if (moment().month() == currentDate.month()) {
      return (
        !newCurrent ||
        newCurrent?.isBefore(moment().format("YYYY-MM-DD"), "day") ||
        newCurrent?.isAfter(nextMonth.format("YYYY-MM-DD"), "day")
      );
    } else if (moment().month() > currentDate.month()) {
      return newCurrent?.isBefore(moment().format("YYYY-MM-DD"), "day");
    } else {
      return (
        newCurrent?.isBefore(
          currentDate.startOf("M").format("YYYY-MM-DD"),
          "day"
        ) ||
        newCurrent?.isAfter(currentDate.endOf("M").format("YYYY-MM-DD"), "day")
      );
    }
  };

  //data cells ---------------------------------------------------------------
  function dateCellRender(value) {
    let tempSlot = slot.map((item) => item.date);
    
    let tempData = tempSlot?.filter(
      (item) => item === value.format("YYYY-MM-DD")
    );

    return (
      <>
        {tempData?.length > 0 ? (
          <div
            style={{
              height: "105px",
              maxWidth: "100%",
              background: "#20c997",
              border: "0",
              borderTop: "2px solid rgba(0,0,0,.06)",
              borderRadius: "0",
              cursor: "pointer",
            }}
          >
            
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "10px",
              }}
            >
              {value.utc().format("DD")}
            </div>
            
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input type="checkbox" checked={true} />
              <label>Open</label>
            </div>
            </div>
        ) : (
          <div
            style={{
              height: "105px",
              maxWidth: "100%",
              border: "0",
              borderTop: "2px solid rgba(0,0,0,.06)",
              borderRadius: "0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "10px",
              }}
            >
              {value.utc().format("DD")}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input type="checkbox" />
              <label>Open</label>
            </div>
          </div>
        )}
      </>
    );
  }

  console.log(tempDates,'fffffffffffff');
  return (
    <>
    <Modal title="Warning" visible={Visible} onOk={handleOk} onCancel={handleCancel} maskClosable={false}>
        <p>There are existing bookings. Are you sure you want to delete the scheudle for {tempDates.map(item=> item).join(' , ')}?</p>
      </Modal>
      <div className="p-20 white-bc title-box-shadow">
        <PageHeader className="mb-10" title="Manage Schedule" />
      </div>
      <div style={{ padding: "20px" }}>
        <Calendar
          onSelect={handleSelect}
          value={currentDate}
          dateFullCellRender={dateCellRender}
          disabledDate={handleDisabled}
          headerRender={() => (
            <>
              <div
                style={{
                  display: "flex",
                  padding: "20px 50px 0 20px",
                }}
              >
                <h3 style={{ fontWeight: "bold", padding: "20px 20px 0 50px" }}>
                  {isModalVisible?.alias ? isModalVisible.alias : "NA"}
                </h3>
              </div>
              <div style={{display:"flex", margin:"5px 0 20px 70px"}}>
                  <div>
                    <p>WeekDays</p>
                    <Button onClick={() => handleWeekdays(true)}>
                      Include All WeekDays
                    </Button>{" "}
                    &nbsp;
                    <Button onClick={() => handleWeekdays(false)}>
                      Remove All WeekDays
                    </Button>
                  </div>
                  <div style={{marginLeft:"10px" }}>
                    <p>WeekEnds</p>
                    <Button onClick={() => handleWeekends(true)}>
                      Include All WeekEnds
                    </Button>{" "}
                    &nbsp;
                    <Button onClick={() => handleWeekends(false)}>
                      Remove All WeekEnds
                    </Button>
                  </div>
                  <div>
                    <div style={{ display: "flex",marginTop:"35px", marginLeft:"10px" }}>
                      <Button
                        style={{ marginRight: "10px" }}
                        onClick={handleClear}
                      >
                        Clear Changes
                      </Button>
                      <Button type="primary" onClick={handleSubmit}>
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "15px",
                  borderTop: "1px solid #f0f0f0",
                  borderLeft: "1px solid #f0f0f0",
                  borderRight: "1px solid #f0f0f0",
                  margin: "0 50px",
                }}
              >
                <div className="ml-20">
                  <CaretLeftOutlined
                    onClick={() => {
                      setCurrentDate(moment(currentDate).subtract(1, "M"));
                      setWeekday(false);
                      setWeekend(false);
                      handleSubmit();
                    }}
                  />
                </div>
                <div>
                  <p style={{ fontWeight: "bold" }}>
                    {currentDate?.format("MMMM")}
                  </p>
                </div>
                <div style={{ marginRight: "20px" }}>
                  <CaretRightOutlined
                    onClick={() => {
                      setCurrentDate(moment(currentDate).add(1, "M"));
                      setWeekday(false);
                      setWeekend(false);
                      handleSubmit();
                    }}
                  />
                </div>
              </div>
            </>
          )}
        />
      </div>
    </>
  );
}
