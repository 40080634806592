import { Card } from 'antd';
import { JobTitleInfo } from 'forms/job';
import React from 'react';
import { useDispatch } from 'react-redux';

const $ = window.jQuery;

export function JobCard({ editableEditJob,formtype }) {
	const dispatch = useDispatch();

	function removeJob(job) {
		if(formtype === "edit"){
		if (job) {
			dispatch({
				type: 'REMOVE_SELECTED_JOBS',
				payload: job,
			});
		}
	}else{
		if (job) {
			dispatch({
				type: 'REMOVE_SELECTED_JOBS_FROM_STATUS',
				payload: job,
			});
		}
	}

		/* Need to change */
		let row = $('#jobsDataTable tbody').find(`.${job._id}`).closest('tr');

		console.log(row);
		$(row).removeClass('selected');
		$(row).find('input[type="checkbox"]').prop('checked', false);
	}

	if (editableEditJob.length < 1) {
		return <div>Please select jobs</div>;
	}

	return (
		<div className="selected-jobs-list mt-10">
			{editableEditJob.length >0 && editableEditJob?.map((job, key) => {
				return (
					<Card key={key}>
						{editableEditJob?.length > 1 && (
							<span
								className="remove"
								onClick={() => removeJob(job)}
							>
								X
							</span>
						)}
						<JobTitleInfo title={'BOOKING ID :'} data={job?.reference} />
						<JobTitleInfo
							title={'VIN :'}
							data={
								job.vehicle
									? job.vehicle.vin
									: 'NA'
							}
						/>
						<JobTitleInfo
							title={'Unit Number :'}
							data={
								job.vehicle
									? job.vehicle.unitNumber
									: 'NA'
							}
						/>
					</Card>
				);
			})}
		</div>
	);
}
