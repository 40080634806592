import React, { useState, useEffect } from "react";
import { Form, Input, Select } from "formik-antd";
import { Col, Row, message, Checkbox, Radio } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { FormActionButtons } from "../FormActionButtons";
import { getCategories } from "services/common";
import * as Yup from "yup";
import { API } from "services";
import { leftToFilterOptions } from "helpers";

export function ServiceForm({ onClose, editMode, editableServices }) {
  let data = JSON.parse(localStorage.getItem('userInfoData'));
  let adminUserType = data?.userType?.id;
let adminMarket = {};

if(adminUserType === 4){
	adminMarket = data?.markets;
}
  const dispatch = useDispatch();
  const [markets, setMarkets] = useState([]);
  let { productsCategories } = useSelector((state) => state?.model);
  const [accessData, setAccess] = useState();
  console.log(editableServices);
  const initialValues = {
    name: editMode ? editableServices?.name : undefined,
    category: editMode
      ? editableServices?.category === null
        ? undefined
        : editableServices?.category?.id
      : undefined,
    type: editMode ? editableServices?.type : undefined,
    description: editMode
      ? editableServices?.description === null
        ? undefined
        : editableServices?.description
      : undefined,
    icon: editMode
      ? editableServices?.icon
      : "https://dfs-assets-np.s3.amazonaws.com/categories/icons/battery.png",
    markets:
      editMode &&
      editableServices.markets &&
      editableServices.markets.length > 0
        ? editableServices.markets.map((obj) => obj.id)
        : undefined,
    access: editMode ? editableServices?.access : undefined,
  };
  console.log(editableServices, "--------------------------");
  const handleSubmit = (values, { setErrors, resetForm, setSubmitting }) => {
    const valuesCopy = { ...values };

    if (editMode) {
      valuesCopy.id = editableServices?.id;
    }
    const CREDENTIALS = {
      url: `/services/`,
      method: editMode ? "put" : "post",
      data: valuesCopy,
      setErrors,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        showSuccessMessage(valuesCopy);
        resetForm();
        onClose();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  function showSuccessMessage(values) {
    let text = editMode ? "updated" : "added";
    message.success(`${values.name} service ${text} successfully`);
  }

  let ServiceSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    icon: Yup.string().required("Icon is required"),
    category: Yup.string().required("Category is required").nullable(),
    type: Yup.string().required("Type is required").nullable(),
    markets: Yup.string().required("market is required").nullable(),
    access: Yup.string().required("access is required"),
  });

  if (editMode) {
    ServiceSchema = Yup.object({
      name: Yup.string().required("Name is required"),
      icon: Yup.string().required("Icon is required"),
      category: Yup.string().required("Category is required").nullable(),
      type: Yup.string().required("Type is required").nullable(),
      access: Yup.string().required("access is required"),
    });
  }
  const { Option } = Select;

  useEffect(() => {
    dispatch(getCategories());
    const CREDENTIALS = {
      url: `/markets`,
      method: "get",
      queryParams: {
        all: true,
      },
    };

    API.common(CREDENTIALS)
      .then((response) => {
        if(adminUserType === 4){
          data = response?.data?.markets?.filter(item=> adminMarket?.includes(item?.id));
         }else{
          data = response?.data?.markets;
         }
          setMarkets(data);
      })
      .catch((err) => console.log(err));

    if (editMode) {
      setAccess(editableServices?.access);
    }
  }, []);

  const accessOption = [
    { label: "All", value: 1 },
    { label: "Only Consumer", value: 2 },
    { label: "Only Technician", value: 3 },
    { label: "Consumer & Technician", value: 4 },
  ];

  const handleAccess = (e, setFieldValue) => {
    setFieldValue("access", e.target.value);
    setAccess(e.target.value);
  };

  console.log(accessData);
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={ServiceSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={8}>
              <Col span={24} className="service_form">
                <Form.Item
                  name="name"
                  label="Service Name"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="name" placeholder="Service name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24} className="service_form">
                <Form.Item
                  name="icon"
                  label="Service Icon"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="icon" placeholder="Service Icon (url)" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24} className="service_form">
                <Form.Item
                  name="type"
                  label="Service Type"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    showSearch
                    name="type"
                    style={{ width: "100%" }}
                    optionFilterProp="children"
                    placeholder="Select Service Type"
                    allowClear={true}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={1} value={1}>
                      Primary Service
                    </Option>
                    <Option key={2} value={2}>
                      Add On Service
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="service_form">
                <Form.Item
                  name="category"
                  label="Category"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    showSearch
                    name="category"
                    style={{ width: "100%" }}
                    placeholder={"Select category"}
                    allowClear={true}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={() => console.log(values)}
                  >
                    {productsCategories &&
                      productsCategories.length > 0 &&
                      productsCategories.map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="service_form">
                <Form.Item
                  name="markets"
                  label="Markets"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Select
                    showSearch
                    name="markets"
                    style={{ width: "100%" }}
                    placeholder={"Select markets"}
                    allowClear={true}
                    mode="multiple"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      leftToFilterOptions(input, option)
                    }
                  >
                    {markets &&
                      markets.length > 0 &&
                      markets.map(({ id, city, alias }) => (
                        <Option key={id} value={id}>
                          {alias ? alias : "NA"}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="service_form">
                <Form.Item
                  name="description"
                  label="Description"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input.TextArea
                    name="description"
                    placeholder="Description"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="service_form" style={{marginBottom:"50px"}}>
                <Form.Item
                  name="access"
                  label="Access"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  {/* {editMode && editableServices?.access ?  <Checkbox.Group options={accessOption} defaultValue={[editableServices?.access]} onChange={handleAccess} value={accessData}/>: <Checkbox.Group options={accessOption} onChange={handleAccess} value={accessData}/>} */}

                  <Radio.Group
                    value={accessData}
                    onChange={(e) => handleAccess(e, setFieldValue)}
                    disabled={editMode && adminUserType === 4 ? true:false}
                  >
                    <Radio value={1}>All</Radio>
                    <Radio value={2}>Only Consumer</Radio>
                    <Radio value={3}>Only Technician</Radio>
                    <Radio value={4}>Consumer & Technician</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <FormActionButtons onClose={onClose} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </div>
  );
}
