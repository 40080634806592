import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { UsersForm } from "../../../forms/users";
import { useDispatch, useSelector } from "react-redux";
import { timeStamp } from "helpers/date-formatter";

export default function CUUser() {
  const dispatch = useDispatch();

  const { userFormVisible, editableUser } = useSelector((state) => state.users);

  const editMode = "id" in editableUser ? true : false;
  const formHeaderText = editMode ? "Edit User " : "Create User";

  function onClose() {
    dispatch({
      type: "HIDE_USER_FORM",
      payload: {},
    });
    dispatch({
      type: "USER_LAST_UPDATED_TIME",
      payload: timeStamp(),
    });
  }

  function showDrawer() {
    dispatch({
      type: "SHOW_USER_FORM",
      payload: {},
    });
  }

  //to show users add and edit form
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="primary" onClick={showDrawer}>
          <PlusOutlined /> Add New User
        </Button>
      </div>
      <Drawer
        title={formHeaderText}
        width={750}
        onClose={onClose}
        visible={userFormVisible}
        destroyOnClose={true}
        closable={false}
        maskClosable={true}
      >
        <UsersForm
          onClose={onClose}
          editMode={editMode}
          editableUser={editableUser}
        />
      </Drawer>
    </div>
  );
}
