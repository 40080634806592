import React, { useEffect } from "react";
import { getConsumers } from "services/common";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import moment from "moment-timezone";

export default function DTConsumers() {
  const dispatch = useDispatch();
  const { consumers = [] } = useSelector((state) => state?.model);

  useEffect(() => {
    //gets consumer
    dispatch(getConsumers());
  }, []);

  function getPhoneNumber(data) {
    //show the phone number by adding country code
    let code = data.countryCode
      ? data.countryCode?.indexOf("+") === 0 ||1
        ? data.countryCode
        : "+" + data.countryCode
      : "";
    let phone = data.phone ? data.phone : "NA";
    return code + " " + phone;
  }

  //data to be shown in consumer table
  const columns = [
    {
      title: "NAME",
      key: "id",
      render:(record)=> `${record?.firstName} ${record?.lastName}`
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "id",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "id",
      sorter: false,
      render: (id, record) => getPhoneNumber(record),
    },
    {
      title: "COMPANY NAME",
      dataIndex: "companyName",
      key: "id",
      render: (id, record) => (record.companyName ? record.companyName : "NA"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "id",
    },
    {
      title:"DATE OF SIGN UP",
      render:(record)=> moment.utc(record?.createdAt).local().format("MM/DD/YYYY")
    }
  ];

  //table where data  is shown
  return (
    <div>
      <Table
        dsize="small"
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={consumers}
        locale={{ emptyText: "No employee available" }}
        style={{ marginTop: "50px" }}
      />
    </div>
  );
}
