import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button,Drawer} from 'antd';
import { ServiceForm } from '../../../forms/services';
import { useDispatch, useSelector } from 'react-redux';
import { timeStamp } from 'helpers/date-formatter';

export default function CUServices() {
    const dispatch = useDispatch();
    const { servicesFormVisible, editableServices } = useSelector(
		(state) => state.services
	);
	const editMode = 'id' in editableServices ? true : false;
	const formHeaderText = editMode
		? 'Edit Service':'Create Service';
    
    function onClose() {
		dispatch({
			type: 'HIDE_SERVICES_FORM',
			payload: {},
		});
		dispatch({
			type: 'SERVICES_LAST_UPDATED_TIME',
			payload: timeStamp(),
		});
	}

    function showDrawer(){
        dispatch({
            type: 'SHOW_SERVICES_FORM',
            payload: {},
        });
    }
 
    return (
        <div>
			<div style={{display:"flex", justifyContent:"flex-end"}}>
            <Button type="primary" onClick={showDrawer}>
				<PlusOutlined /> Add Service
			</Button>
			</div>
            <div>
            <Drawer
				title={formHeaderText}
				width={550}
				onClose={onClose}
				visible={servicesFormVisible}
				destroyOnClose={true}
				closable={false}
				maskClosable={true}
			>
				<ServiceForm
					onClose={onClose}
					editMode={editMode}
					editableServices={editableServices}
				/>
			</Drawer>
            </div>
        </div>
    )
}


