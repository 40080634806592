import React, { useEffect } from "react";
import { Col, message, Row, Typography } from "antd";
import { Formik } from "formik";
import { DatePicker, Form, Select } from "formik-antd";
import { useSelector, useDispatch } from "react-redux";
import { JobCard } from "./JobCard";
import { getTechnicians } from "services/common";
import { FormActionButtons } from "forms";
import * as Yup from "yup";
import { timeStamp } from "helpers/date-formatter";
import {API} from "services";

const { Option } = Select;
const {Text}=Typography;



export function EditForm({ onClose }) {
  const dispatch = useDispatch();
  const { editableEditJob } = useSelector((state) => state.job);
  let { technicians = [] } = useSelector((state) => state?.model);
  
  
  useEffect(() => {
    dispatch(getTechnicians({markets:editableEditJob.map(mkt=> mkt.market.id),all: true, active:[1]}));
  }, []);

  console.log(technicians);
  const initialValues={
    field_technician: undefined,
}

const EditFormSchema= Yup.object({
    field_technician: Yup.string().required('Field Technician is required')
    })

    const saveData=()=>{
      dispatch({
        type: "SAVE_JOB_FORM",
        payload: timeStamp,
      });
    }
const handleSubmit=(values,{ setErrors, resetForm, setSubmitting})=>{
  const tempData=[];
  editableEditJob.forEach(data=> {
    const valuesCopy ={};
    data.consumer = data?.consumer?.id;
    data.timeslot= data?.timeslot?.id;
    data.vehicle = data?.vehicle?.id;
    data.location = data?.location;
    data.market = data?.market?.id;
    data.services = data?.services?.map(service=> service?.id);
    data.paymentMethod= data?.payment?.paymentMethod;
    delete data?.status;
    delete data?.technician;
    delete data?.payment;
    delete data?.active;
    delete data?.createdAt;
    delete data?.reference;
    delete data?.updatedAt;
    valuesCopy.status = "ASSIGNED";
    valuesCopy.technician=values.field_technician;
    valuesCopy.id = data?.id;
    tempData.push(valuesCopy);
  });

  const CREDENTIALS = {
    url: `/bookings`,
    method: 'put',
    data: tempData
  };

  API.common(CREDENTIALS)
    .then((response) => {
      showSuccessMessage();
    resetForm();
    onClose();
    saveData();
    })
    .finally(() => {
      setSubmitting(false);
    });
}

function showSuccessMessage() {
  message.success(`job updated successfully`);
}

  return (
    <Formik
        initialValues={initialValues}
        validationSchema={EditFormSchema}
        onSubmit={handleSubmit}
    >
      {({values, isSubmitting, setFieldValue,setFieldTouched}) => (
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={8} className="mb-20">
            <div>
              {editableEditJob.length} Job
              {editableEditJob.length > 1 ? "s" : ""} Selected
            </div>
            <JobCard editableEditJob={editableEditJob} formtype="edit"/>
          </Row>
          <Text style={{fontWeight:"bolder"}}>Assign Field Technician</Text>
          <Row gutter={8} className="m-2"> 
            <Col span={12}>
              <Form.Item
                name="field_technician"
                label="Field Technician"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Select
                  showSearch
                  name="field_technician"
                  style={{ width: "100%" }}
                  placeholder={"Select Field Technician"}
                  allowClear={true}
                  optionFilterProp="children"
                >
                  {technicians.map(
                    ({
                      id,

                      firstName,
                      lastName,
                    }) => (
                      <Option key={id} value={id}>
                        {firstName} {lastName}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <FormActionButtons
						onClose={onClose}
						isSubmitting={isSubmitting}
					/>
        </Form>
      )}
    </Formik>
  );
}
