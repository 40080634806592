import { getParamsFromUrl } from 'helpers/history';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function useQueryParams() {
	const history = useHistory();
	const location = useLocation();
	const urlParams = getParamsFromUrl(history);
	const [params, setParams] = useState(urlParams || {});

	console.log(urlParams);

	useEffect(() => {
		let data = getParamsFromUrl(history);
		//console.log('useQueryParams', data);
		setParams(data);
	}, [location, history]);

	return { ...params, history, location };
}
