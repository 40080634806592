//all job status
export const jobsStatusTypes = [
  {id:'27', name:"ALL", key:""},
  { id: "19", name: "Requested", key: "REQUEST_CONFIRMED" },
  { id: "1", name: "Assigned", key: "ASSIGNED" },
  { id: "2", name: "Inprogress", key: "IN_PROGRESS" },
  { id: "3", name: "Completed", key: "COMPLETED" },
  { id: "12", name: "Pending", key: "PENDING" },
  { id: "13", name: "Not Completed", key: "IN_COMPLETE" },
  { id: "10", name: "Blocker", key: "blocker" },
  { id: "15", name: "Consumer Cancelled", key: "CONSUMER_CANCELLED" },
  { id: "23", name: "Admin Cancelled", key: "SERVICER_CANCELLED" },
  { id: "24", name: "Missed", key: "MISSED_OWING_REDFLAG" },
  { id: "16", name: "No Show", key: "consumerNoShow" },
  { id: "18", name: "No Show", key: "servicerNoShow" },
  { id: "20", name: "Payment Failed", key: "PAYMENT_FAILED" },
  { id: "21", name: "Refunded", key: "REFUNDED" },
  { id: "22", name: "Refund Failed", key: "REFUND_FAILED" },
  {id:'26',name:"Review",key:"PENDING_REVIEW_AND_PAYMENT"},
  
];

export const findStatusCode = (status) => {
  return status
    ? jobsStatusTypes.find((type) => type.id == status)?.name || "NA"
    : "N/A";
};

export function findJobStatusTotal(key, jobsTypes) {
console.log(typeof key);
  /**
   * Jobs types buttons list based on our `jobsStatusTypes` array,
   * it will get total from server data based on key match between both data
   */
  const jobType = jobsTypes.filter((job) => job.status == key);
  const jobType1 =  jobsTypes.filter((job) => job.status == "IN_PROGRESS" || job.status == "CHECKOUT");
  console.log(jobType,'GHFFF')
  if(key=="IN_PROGRESS"){
    if (jobType1.length >= 0) {
      return jobType1.length;
    } else {
      return 0;
    }
  }else{
    if (jobType.length > 0) {
      return jobType.length;
    }else if(key ==""){
      return jobsTypes.length;
    }
    else {
      return 0;
    }
  }
  
}
