import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { PlusOutlined,CloudDownloadOutlined } from "@ant-design/icons";
import { getSelectedDownload } from "services/common";
import moment from "moment-timezone";
import { getCategories } from "services/common";
import { useSelector, useDispatch } from "react-redux";
import { getParamsFromUrl, pushParamsToUrl } from "helpers/history";
import {useHistory} from "react-router-dom";

function DTJobs({ jobsStatusType }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = getParamsFromUrl(history);
 
  const { SelectedJobs } = useSelector((state) => state.model);
  const { selectedJobsKey, saveJobform,lastUpdatedTime } = useSelector((state) => state.job);

  const [selectedRowKeys, setRowKeys] = useState([]);
  const [selectionEnabled, setSelectionEnabled] = useState(false);
  const [selectionType] = useState("checkbox");
  const [showEdit, setShowEdit] = useState(false);
  const [EnableEditButtons, setEnabledEditButton] = useState(true);
  const [showEditJobStatus, setEditJobStatus] = useState(false);
  const [showBookingStatus, setBookingStatus] = useState(false);
  const [hideColumn,setHideColumn]=useState(true);
  const [jobsForm,setJobsForm]=useState("jobs_table");

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    //not to show checkbox on these statuses for job status and edit jobs
    if (
      jobsStatusType == "3" ||
      jobsStatusType == "23" ||
      jobsStatusType == "17" ||
      jobsStatusType == "20" ||
      jobsStatusType == "27"
    ) {
      setSelectionEnabled(false);
    } else {
      setSelectionEnabled(true);
    }

    //to show edit job only on these status types
    if (jobsStatusType == "19" || jobsStatusType == "1" || jobsStatusType =="12" || jobsStatusType == "13") {
      setShowEdit(true);
    } else {
      setShowEdit(false);
    }


    //to show Edit Booking Summary
    if(jobsStatusType == "26"){
      setShowEdit(false);
      setBookingStatus(true);
    }else{
      setBookingStatus(false);
    }
    
     //dont show multi slect
     if(jobsStatusType ==="26"){
      setJobsForm('jobs_form_with_multi');
    }else{
      setJobsForm("jobs_table");
    }

    //to show jobs status only on these status types

    if (saveJobform != undefined) {
      setRowKeys([]);
      setEnabledEditButton(true);
    }
    // if(jobsStatusType == "26"){
    //   setHideColumn(false);
    // }else{
    //   setHideColumn(true);
    // }
  }, [jobsStatusType, saveJobform,lastUpdatedTime]);


  //gets the data from selected row and saves in redux for forms
  const showForm = (data) => {
    const newData = SelectedJobs.filter(
      (selected) => data?.id === selected?.id
    );

    dispatch({
      type: "SHOW_JOB_FORM",
      payload: newData[0],
    });
  };

 
//for showing checkbox and multi select
  const onRowSelect = (selectedRowKeys, selectedRows, rows) => {
    if(selectedRowKeys?.length ==1) {
      setEditJobStatus(true)
    }else{
      setEditJobStatus(false)
    }
    setRowKeys(selectedRowKeys);
    if (selectedRowKeys.length > 0) {
      setEnabledEditButton(false);
      dispatch({
        type: "ADD_EDIT_JOB",
        payload: selectedRows,
      });
      dispatch({
        type: "ADD_EDIT_JOB_STATUS",
        payload: selectedRows,
      });
      dispatch({
        type: "ADD_COUPONS_FORM",
        payload: selectedRows,
      });
      dispatch({
        type: "REMOVE_COUPONS_DATA_TEMP",
        payload: selectedRows[0]?.reference,
      });
      dispatch({
        type: "ADD_COUPONS_DATA_TEMP",
        payload: selectedRows[0]?.market?.id,
      });
      dispatch({
        type: "ADD_ID",
        payload: selectedRows[0]?.id,
      });
      dispatch({
        type: "TEMP_DATA",
        payload: selectedRows,
      });
    } else {
      setEnabledEditButton(true);
    }
  };

  //config for checkbox and multiselect
  let rowSelection = {
    selectedRowKeys,
    onChange: onRowSelect,
  };

  //shows edit form in a drawer
  function showEditForm() {
    dispatch({
      type: "SHOW_EDIT_JOB_FORM",
      payload: {},
    });
  }

  //shows edit status form in a drawer
  function showEditFormStatus() {
    dispatch({
      type: "SHOW_EDIT_JOB_STATUS_FORM",
      payload: {},
    });
  }

   //shows edit status form in a drawer
   function showBooking(record) {
     console.log(record);
    dispatch({
      type: "APPLY_COUPONS_FORM",
      payload: {},
    });
  }
  
  //data that needs to be shown in table
  const columns = [
    {
      title: "BOOKING ID",
      dataIndex: "id",
      key: "id",
      width:"4%",
      render: (id, record) => (
        <Button onClick={() => showForm(record)} type="text">
          {record?.reference}
        </Button>
      ),
    },
    {
      title: "VIN",
      dataIndex: "vehicle",
      key: "id",
      width:"4%",
      render: (id, record) => (
        <Button onClick={() => showForm(record)} type="text">
          {record?.vehicle?.vin ? record.vehicle.vin : "NA"}
        </Button>
      ),
    },
    {
      title: "UNIT NUMBER",
      dataIndex: "vehicle",
      width:"5%",
      key: "id",
      render: (id, record) => (
        <Button onClick={() => showForm(record)} type="text">
          {record?.vehicle?.unitNumber ? record.vehicle.unitNumber : "NA"}
        </Button>
      ),
    },
    {
      title: "LOCATION",
      dataIndex: "location",
      key: "id",
      width:"8%",
      render: (id, record) => (
        <Button onClick={() => showForm(record)} type="text">
          {record?.address?.full
            ? `${record?.address?.full.substring(
                0,
                18
              )}\n ${record?.address?.full.substring(
                18,
                38
              )}\n ${record?.address?.full.substring(
                38,
                58
              )}\n ${record?.address?.full.substring(
                58,
                78
              )}\n${record?.address?.full?.substring(
                78,
                98)}\n${record?.address?.full?.substring(
                  98,118)}`
            : "NA"}
        </Button>
      ),
    },
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      width:"7%",
      render: (id, record) => (
        <Button onClick={() => showForm(record)} type="text">
          {moment
            .tz(record?.timeslot?.startDateTime, record?.market?.timezone)
            .format("lll")}
        </Button>
      ),
    },
    {
      title: "SCHEDULE \nLEAD",
      dataIndex: "technician",
      width:"8%",
      key: "id",
      render: (id, record) => (
        <Button onClick={() => showForm(record)} type="text">
          {record?.technician
            ? `${record.technician.firstName} ${record.technician.lastName}`
            : "NA"}
        </Button>
      ),
    },
    {
      title: "SERVICES",
      dataIndex: "services",
      width:"10%",
      key: "id",
      render: (id, record) => (
        
        <Button onClick={() => showForm(record)} type="text">
          {record?.services?.length > 0
            ? record.services.map((item) => item.name).join("\n")
            : "NA"}
        </Button>
      ),
    }
  ].filter(item => !item.hidden);

  //table
  return (
    <div style={{ marginTop: "20px" }}>
      {showEdit && (
        <Button type="link" disabled={EnableEditButtons} onClick={showEditForm}>
          <PlusOutlined /> ASSIGN JOB
        </Button>
      )}
      {showEditJobStatus && showBookingStatus ===false &&(
        <Button
          type="link"
          disabled={EnableEditButtons}
          onClick={showEditFormStatus}
        >
          <PlusOutlined /> EDIT JOB STATUS
        </Button>
      )}
       {showBookingStatus && (
        <Button
          type="link"
          disabled={EnableEditButtons}
          onClick={()=>showBooking(SelectedJobs)}
        >
          <PlusOutlined /> EDIT BOOKING SUMMARY
        </Button>
      )}
      {SelectedJobs.length > 0 &&(
      <div style={{display:"flex", justifyContent:"flex-end"}}>
      <Button type="link" style={{paddingRight:"50px"}} onClick={()=> {pushParamsToUrl(history, {
        ...params
      })
      dispatch(getSelectedDownload(params));
      }} >
      
    <CloudDownloadOutlined style={{color:"#1890ff",fontSize:"20px",cursor:"pointer"}}/>
    <span style={{fontSize:"16px"}}>Download </span>
    </Button>
    </div>
    )}
      <Table
        style={{ whiteSpace: "pre" }}
        className={jobsForm}
        dataSource={SelectedJobs}
        columns={columns}
        rowSelection={
          selectionEnabled ? { type: selectionType, ...rowSelection } : null
        }
        rowKey={(record) => record?.id}
        onRow={(record, id) => {
          return {
            onClick: (event) => {
              const newData = SelectedJobs.filter(
                (selected) => record?.id === selected?.id
              );
              

              dispatch({
                type: "SHOW_JOB_FORM",
                payload: newData[0],
              });
            },
          };
        }}
      />
    </div>
  );
}

export default DTJobs;
