import { combineReducers } from 'redux';

import { api } from './api.reducer';
import { history } from './history.reducer';
// import { timeline } from './timeline.reducer';
import { model } from './model.reducer';
import { job } from './job.reducer';
import {users} from './users.reducer';
import {profile} from './profile.reducer';
import {technician} from './technician.reducer';
import {marketpreference} from './marketpreference.reducer';
import {services} from './services.reducer';
import {pricings} from './pricing.reducer';
import {discount} from './discount.reducer';

const combinedReducer = combineReducers({
	api,
	history,
	// timeline,
	model,
	job,
	users,profile,technician,marketpreference,services,pricings,discount
});

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT') {
		state = undefined;
		localStorage.clear(); // to reset all localStorage date
	}
	return combinedReducer(state, action);
};

export default rootReducer;

// https://alligator.io/redux/reset-state-redux/

// https://www.freecodecamp.org/news/understanding-redux-the-worlds-easiest-guide-to-beginning-redux-c695f45546f6/
