import moment from 'moment';

export function dateFormatter(date) {
	if (date) {
		return moment(date, 'YYYY-MM-DD').format('MMM DD YYYY');
	}
	return date;
}

export function dateFormated(date) {
	if (date) {
		return moment(date).format('MMM DD, YYYY hh:mm A');
	}
	return date;
}

export function timeZoneToMoment(date, timeZone) {
	return moment.utc(date).clone().tz(timeZone);
}


export function dateDisplay(date) {
	if (date) {
		return moment(date, 'YYYY-MM-DD h:mm').format('DD MMM YYYY');
	}
	return date;
}
export function timeStamp() {
	return moment().format('dddd, MMMM Do YYYY, h:mm:ss a');
}

export function dateTimeFormatter(date) {
	if (date) {
		return `${moment(date).format('YYYY-MM-DD')}`;
	}
	return date;
}

export function dateSubtractDisplay(date, withTime = false) {
	if (date && !withTime) {
		return `${moment(date).subtract(1, 'days').format('DD-MMM-YYYY')}`;
	} else if (date && withTime) {
		return `${moment(date).subtract(1, 'days').format('YYYY-MM-DD hh:mm')}`;
	}
	return date;
}

export function toLocalTime(date, timeZone) {
	return moment.utc(date).clone().tz(timeZone).format('lll');
}

export function timeZoneConversion(
	date,
	timeZone,
	view = false,
	Format = 'YYYY-MM-DD HH:mm'
) {
	if (view) {
		let localTime = toLocalTime(date, timeZone);
		return moment(localTime).format(Format);
	} else {
		return moment
			.tz(moment(date).format(Format), timeZone)
			.utc()
			.format(Format);
	}
}
