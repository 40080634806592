import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import { MarketForm, Schedule, ServiceCopy } from "forms/marketpreference";
import { useDispatch, useSelector } from "react-redux";
import { timeStamp } from "helpers/date-formatter";


export default function CUMarket() {
  const dispatch = useDispatch();

  const { marketpreferenceFormVisible, editablemarketpreference, showService, showSchedule,scheduleNew, showSchedulesNewData } = useSelector(
    (state) => state.marketpreference
  );

  const editMode = "id" in editablemarketpreference ? true : false;
  const formHeaderText = editMode
    ? "Edit Market "
    : "Create Market";

  function onClose() {
    dispatch({
      type: "HIDE_MARKET_PREFERENCE_FORM",
      payload: {},
    });
    dispatch({
      type: "MARKET_PREFERENCE_LAST_UPDATED_TIME",
      payload: timeStamp(),
    });
  }

  function showDrawer() {
    dispatch({
      type: "SHOW_MARKET_PREFERENCE_FORM",
      payload: {},
    });
  }

  //show create and edit market form in drawer
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="primary" onClick={showDrawer}>
          <PlusOutlined /> Add Market
        </Button>
      </div>
      {marketpreferenceFormVisible &&(
      <Drawer
        title={formHeaderText}
        width={750}
        onClose={onClose}
        visible={marketpreferenceFormVisible}
        destroyOnClose={true}
        closable={false}
        maskClosable={true}
      >
        <MarketForm
          onClose={onClose}
          editMode={editMode}
          editableMarket={editablemarketpreference}
        />
      </Drawer>
      )}
  {showService &&(
    <Drawer
    title="Copy Service"
    width={550}
    onClose={onClose}
    visible={showService}
    destroyOnClose={true}
    closable={false}
    maskClosable={true}
  >
    <ServiceCopy
      onClose={onClose}
    />
  </Drawer>
  )}
{showSchedule &&(
    <Drawer
    title="Add Schedule"
    width={450}
    onClose={onClose}
    visible={showSchedule}
    destroyOnClose={true}
    closable={false}
    maskClosable={true}
  >
    <Schedule
      onClose={onClose}
    />
  </Drawer>
  )}
  {scheduleNew &&(
    <Drawer
    title="Add Schedule"
    width={450}
    onClose={onClose}
    visible={scheduleNew}
    destroyOnClose={true}
    closable={false}
    maskClosable={true}
  >
    <Schedule
      onClose={onClose}
    />
  </Drawer>
  )}
    </div>
  );
}
