import * as Yup from "yup";
import _ from "lodash";
import moment from "moment";


export function getInitialValues(editMode, editableMarket, maketValue) {
  let startTime = '';
  let endTime = '';
  if (editMode) {
    let date = moment.utc().format('YYYY-MM-DD');
    let newDate = moment.utc(`${date} ${editableMarket?.marketPreference?.shiftStartTime}`, 'YYYY-MM-DD hh:mm:ss').clone().tz(editableMarket?.timezone).format("hh:mm A");

    if (newDate === "Invalid date") {
      startTime = moment(`00:00`, 'hh:mm A').format("hh:mm A");
    } else {
      startTime = moment(`${newDate}`, 'hh:mm A').format("hh:mm A");
    }

    let newDateEnd = moment.utc(`${date} ${editableMarket?.marketPreference?.shiftEndTime}`, 'YYYY-MM-DD hh:mm:ss').clone().tz(editableMarket?.timezone).format("hh:mm A");

    if (newDateEnd === "Invalid date") {
      endTime = moment(`00:00`, 'hh:mm A').format("hh:mm A");
    } else {
      endTime = moment(`${newDateEnd}`, 'hh:mm A').format("hh:mm A");
    }

  }

  let newServiceZone = {
    latitude: undefined, longitude: undefined, miles: undefined, zipcode: undefined
  }

  return {
    cityName: editMode ? editableMarket?.address?.city : undefined,
    stateName: editMode ? editableMarket?.address?.state : undefined,
    countryName: editMode ? editableMarket?.address?.country : undefined,
    tax: editMode ? parseFloat(editableMarket?.tax) : undefined,
    latitude: editMode ? editableMarket?.address?.latitude : undefined,
    longitude: editMode ? editableMarket?.address?.longitude : undefined,
    timezone: editMode ? editableMarket?.timezone : undefined,
    servicesPerTimeslot: editMode
      ? editableMarket?.marketPreference?.servicesPerTimeslot
      : undefined,
    cancellationOffsetTime: editMode
      ? editableMarket?.marketPreference?.cancellationOffsetMinutes ?? undefined
      : undefined,

    cancellationFee: editMode
      ? editableMarket?.marketPreference?.cancellationFee ?? undefined
      : undefined,
    frequency: editMode
      ? editableMarket?.marketPreference?.frequency
      : undefined,
    shiftStartTime: editMode
      ? editableMarket?.marketPreference?.shiftStartTime ? startTime : undefined
      : undefined,
    shiftEndTime: editMode
      ? editableMarket?.marketPreference?.shiftEndTime ? endTime : undefined
      : undefined,
    dispatch: editMode ? editableMarket?.marketPreference?.dispatch ? editableMarket?.marketPreference?.dispatch : undefined : undefined,
    serviceZones: editMode ? editableMarket?.serviceZones : [newServiceZone],
  };

}

export function getGroupedMarketInitialValues(
  editMode,
  editableMarket,
  regions
) {
  let region = regions?.find((region) =>
    _.map(region?.markets, "_id").includes(editableMarket?.markets?.[0]?._id)
  );
  return {
    alias: editMode ? editableMarket.alias : undefined,
    region: editMode ? region?._id : undefined,
    markets:
      editMode && editableMarket?.markets?.length > 0
        ? editableMarket?.markets?.map((market) => market._id)
        : undefined,
  };
}
const isAfter = (startTime, endTime) => {
  console.log(startTime, endTime);
  var startT = moment(startTime, "HH:mm A");
  var endT = moment(endTime, "HH:mm A");

  var duration = moment.duration(endT.diff(startT));
  var minutes = parseInt(duration.asMinutes());
  console.log(minutes);
  return minutes <= 12 * 60 && minutes >= 30;
};

export function getValidationSchema(editMode) {
  const ErrorText = "Tax allows from 0 to 99";

  const yupTaxValidation = Yup.number()
    .required("Tax required")
    .min(0, ErrorText)
    .max(99, ErrorText);

  let MarketSchema = {
    cityName: Yup.string().required("Place Required"),
    bookingOffsetHours: Yup.string().required("Booking offset hours Required"),
    tax: yupTaxValidation,
    timezone: Yup.string().required("Timezone required"),
    servicesPerTimeslot: Yup.number().positive('Number of Service per TimeSlot should be positive value').required(
      "Number of Service per TimeSlot required"
    ),
    alias:Yup.string().required('Market is Required').nullable(),
    cancellationFee: Yup.string().required("Cancellation Fee required").nullable(),
    frequency: Yup.number().positive('Frequency should be positive value').required("Frequency required").nullable(),
    dispatch: Yup.number().positive('Dispatch should be positive value').required("Dispatch is required"),
    shiftStartTime: Yup.string().required("Start Time is required"),
    shiftEndTime: Yup.string().required("end time is required")
      .test(
        'end_time_test',
        "Difference in duration must be minimum of 30 mins and can't be more than 12 hours",
        function (value) {
          const { shiftStartTime } = this.parent;
          return isAfter(shiftStartTime, value);
        }
      ),
    serviceZones: Yup.array()
      .of(
        Yup.object().shape({
          latitude: Yup.string().required('latitude required'),
          longitude: Yup.string().required('longitude required'),
          miles: Yup.string().required('Miles required'),
          zipcode: Yup.string().required('Zipcode required')
        })
      )
      .required('Must have serviceZones') // these constraints are shown if and only if inner constraints are satisfied
      .min(1, 'Minimum of 1 serviceZones')
  };

  if (editMode) {
    MarketSchema = {
      tax: yupTaxValidation,
      servicesPerTimeslot: Yup.number().positive('Number of Service per TimeSlot should be positive value').required(
        "Number of Service per TimeSlot required"
      ),
      frequency: Yup.number().positive('Frequency should be positive value').required("Frequency required").nullable(),
      bookingOffsetHours: Yup.string().required("Booking offset hours Required"),
      dispatch: Yup.number().positive('Dispatch should be positive value').required("Dispatch is required"),
      cancellationFee: Yup.string().required("Cancellation Fee required").nullable(),
      serviceZones: Yup.array()
        .of(
          Yup.object().shape({
            latitude: Yup.string().required('latitude required'),
            longitude: Yup.string().required('longitude required'),
            miles: Yup.string().required('Miles required'),
            zipcode: Yup.string().required('Zipcode required'),
          })
        )
        .required('Must have serviceZones') // these constraints are shown if and only if inner constraints are satisfied
        .min(1, 'Minimum of 1 serviceZones'),
      shiftStartTime: Yup.string().required("Start Time is required"),
      alias:Yup.string().required('Market is Required').nullable(),
      shiftEndTime: Yup.string().required("End time is required")
        .test(
          'end_time_test',
          "Difference in duration must be minimum of 30 mins and can't be more than 12 hours",
          function (value) {
            const { shiftStartTime } = this.parent;
            return isAfter(shiftStartTime, value);
          }
        ),
    }
  };


  return Yup.object().shape(MarketSchema);
}

export function getGroupedMarketsValidationSchema(editMode) {
  let GroupedMarketSchema = {
    alias: Yup.string().required("Place Required"),
    region: Yup.string(),
    markets: Yup.array()
      .required("Market required")
      .min(1, "Min one market required"),
  };

  if (editMode) {
    GroupedMarketSchema = {
      markets: Yup.array()
        .required("Market required")
        .min(1, "Min one market required"),
    };
  }

  return Yup.object().shape(GroupedMarketSchema);
}
