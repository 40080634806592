import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { getServices} from "services/common";
import { API } from "services";
import { Row, Col, Form, Select, Button,Tooltip } from "antd";
import { services } from "store/reducers/services.reducer";
const { Option } = Select;


function ServiceCopy({ onClose }) {
  const [markets, setMarkets] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("Select Market");
  const [pricings, setPricings] = useState([]);
  const { showServiceData } = useSelector((state) => state.marketpreference);
  const { Services = [] } = useSelector((state) => state?.model);
  const [price, setPrice] = useState([]);
  const [service, setService] = useState([]);
  const [tier, setTier] = useState([]);
  const [tempPrice, setTempPricing] = useState([]);
  const [marketPricing,setMarketPricing]=useState([]);
  const dispatch = useDispatch();
  let data = JSON.parse(localStorage.getItem('userInfoData'));
  let adminUserType = data?.userType?.id;
let adminMarket = {};

if(adminUserType === 4){
	adminMarket = data?.markets;
}


  useEffect(() => {
    //gets market data
    const CREDENTIALS = {
      url: `/markets`,
      method: "get",
      queryParams: {
        all: true,
      },
    };

    API.common(CREDENTIALS)
      .then((response) => {
        if(adminUserType === 4){
          data = response?.data?.markets?.filter(item=> adminMarket?.includes(item?.id));
         }else{
          data = response?.data?.markets;
         }
          setMarkets(data);
      })
      .catch((err) => console.log(err));

      let queryParams = {
        all: true,
        markets: [showServiceData?.['id']],
      };
      
        let CREDENTIALS1 = {
          url: `/services/pricings`,
          queryParams,
        };
  
        API.common(CREDENTIALS1).then((response) => {
          const data = response.data.pricings;
          setMarketPricing(data);
        }).catch((err) => console.log(err));
        dispatch(getServices({ all: true })).then((res) => console.log(res));
  }, []);

  function handleMarketChange(data) {
    setSelectedMarket(data);
  }

  function handleService() {
    let queryParams = {
      all: true,
      markets: [selectedMarket],
    };
    if (selectedMarket !== undefined || selectedMarket !== "Select Market") {
      let CREDENTIALS = {
        url: `/services/pricings`,
        queryParams,
      };

      return API.common(CREDENTIALS).then((response) => {
        const data = response.data.pricings;
        setTempPricing(data);
        let newData = data.reduce((accumulator, current) => {
          if (!accumulator.some((x) => x.service.id === current.service.id)) {
            accumulator.push(current);
          }
          return accumulator;
        }, []);
        

        let result = newData.filter(o1 => !marketPricing?.some(o2 => o1?.service?.id === o2?.service?.id));
        
        setPricings(result);
      });
    }
  }

  //console.log(marketPricing);
  
  const handleSave = () => {
    
    let markets = showServiceData?.id;
    let dataService1=Services.filter(item=> service?.includes(item?.id));
    
   let servicePostData=[];

    for(let k=0; k<=dataService1?.length-1; k++){
      let serviceApiData={};
      serviceApiData.access= dataService1[k]?.access;
      serviceApiData.category=dataService1[k]?.category?.id;
      serviceApiData.description=dataService1[k]?.description;
      serviceApiData.icon=dataService1[k]?.icon;
      serviceApiData.id=dataService1[k]?.id;
      serviceApiData.name=dataService1[k]?.name;
      serviceApiData.type=dataService1[k]?.type;
      let mktids =dataService1[k]?.markets?.map(item=> item?.id);
      mktids.push(showServiceData?.id);
      serviceApiData.markets =mktids;
      servicePostData.push(serviceApiData);
    }

    
  servicePostData.map((item,index)=>{
    const CREDENTIALS = {
      url: `/services/`,
      method: "put",
      data: item
    };

    API.common(CREDENTIALS)
      .then((response) => {
      }).catch(err=> console.log(err))
  })

  console.log(service);
    for(let i = 0; i <= price?.length - 1; i++) {
      let tempData= tempPrice.filter(item=> service[i] === item?.service?.id);
      let prices = tempData.map(item=> item.price);
      let vehicleTypes = tempData.map(item=> item.vehicleType.id);
    for(let j=0; j<=tempData.length-1; j++){
      let valuesCopy = {
        market: markets,
        price: prices[j],
        service: service[i],
        vehicleType: vehicleTypes[j],
      };
      
      const CREDENTIALS = {
        url: `/services/pricings`,
        method: "put",
        data: valuesCopy,
      };

      API.common(CREDENTIALS).then((response) => {
        onClose();
      });
    }
  }
  };

  const handlePricing = (e, item) => {
    setPrice([...price, item?.price]);
    setService([...service, item?.service?.id]);
    setTier([...tier, item?.vehicleType?.id]);
  };

  return (
    <div>
      <Row type="flex" align="middle">
        <Col span={16}>
          <span style={{ color: "#8e8e8e" }}>Copy Services to:</span>{" "}
          {showServiceData?.alias == null ? "NA" : showServiceData?.alias}
        </Col>
      </Row>
      <Row type="flex" align="middle">
        <Col span={16}>
          <Form.Item
            label="Copy Services From"
            className={"search-label"}
          >
            <Select
              showSearch
              name="market"
              style={{ width: "100%" }}
              value={selectedMarket}
              placeholder={"Copy Services From"}
              allowClear={true}
              optionFilterProp="children"
              onChange={(e) => handleMarketChange(e)}
            >
              {markets.map(({ id, city, alias }) => (
                <Option key={id} value={id}>
                  {alias ? alias : "NA"}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4} style={{ margin: "7px 10px 0 10px" }}>
          <Button type="primary" onClick={handleService}>
            Show Services
          </Button>
        </Col>
      </Row>
      {pricings?.length > 0 ? (
        <Row type="flex" align="middle">
          <Col span={24}>
            <p style={{ color: "#8e8e8e" }}>Services:</p>
            {pricings?.map((item, index) => (
              <div key={index}>
                <input
                  type="checkbox"
                  id={index}
                  onChange={(e) => handlePricing(e, item)}
                />
                <label htmlFor={index}>{`${item?.service?.name?.substring(0, 60)}`}{item?.service?.name?.length >60 && <Tooltip placement="rightTop" title={item?.service?.name}>
            <span style={{ color: "#1890ff", cursor: "pointer" }}>
              {" "}
              Read More...
            </span>
          </Tooltip>}</label>
              </div>
            ))}
          </Col>
        </Row>
      ) : (
        <p>No Service Available for Selected Market</p>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          position: "absolute",
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e8e8e8",
          padding: "10px 16px",
          textAlign: "right",
          left: 0,
          background: "#fff",
          borderRadius: "0 0 4px 4px",
          zIndex: "9999",
        }}
      >
        <div style={{ marginTop: "10px",width:"100%",display:"flex",justifyContent:"flex-end" }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="primary"
            style={{ marginLeft: "10px" }}
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export { ServiceCopy };
