import React, { useState, useEffect } from "react";
import { Col, Row, message, Button, Select } from "antd";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { FormActionButtons } from "forms";
import { useDispatch, useSelector } from "react-redux";
import { timeStamp } from "helpers/date-formatter";
import * as Yup from "yup";
import { API } from "services";
import { getCategories} from "services/common";

export function APPLYCOUPONSFORM({ onClose,applyForm }) {
  console.log(applyForm,'hhhhh');
  const { Option,OptGroup } = Select;
  const dispatch = useDispatch();
  const [DateSevice, setDataSevice] = useState([]);
  const [TempService,setTempService]=useState([]);
  const [selectedService, setSelectedService] = useState();
  const [selectedCat, setSelectedCat] = useState();
  const [selectedCoupon, setSelectedCoupon] = useState();
  const { lastUpdatedTime,tempVar,newTempVar,addId,tempDataBooking,btnDisable } = useSelector((state) => state.job);
  const {productsCategories} = useSelector((state)=> state?.model)
  const [singleBooking,setSingleBooking]=useState([]);
  const [discount, setDiscount] = useState([]);


  const initialValues = {
   
  };

  const EditFormSchema = Yup.object({
    coupon: Yup.string().required("Coupon is Required"),
  });

  useEffect(()=>{
    dispatch(getCategories({all:true}));
  },[])
  useEffect(() => {
    const CREDENTIALS = {
      url: `/services?accesses=[1,3,4]&type=[1]&markets=[${newTempVar}]&all=${true}`,
      method: "get"
    };

    API.common(CREDENTIALS)
      .then((response) => {
        setDataSevice(response?.data?.services);
        setTempService(response?.data?.services);
      })
      .catch((err) => console.log(err));

      const CREDENTIALS1 = {
        url: `/coupons`,
        method: "get",
        queryParams: {
          all: true,
          active:[1]
        },
      };
  
      API.common(CREDENTIALS1)
        .then((response) => {
          setDiscount(response?.data?.coupons);
        })
        .catch((err) => console.log(err));

        const CREDENTIALS3 = {
          url: `/bookings?references=[\"${tempVar}"\]`,
          method: "get",
        };
    
        API.common(CREDENTIALS3)
          .then((response) => {
            setSingleBooking(response.data.bookings);
          })
          .catch((err) => console.log(err));
          if(tempDataBooking[0]?.services?.length === applyForm[0]?.services?.length && tempDataBooking[0]?.payment?.coupon === null ){
            dispatch({
              type: "DISABLE_BTN",
              payload: true,
            });
          }else{
            dispatch({
              type: "DISABLE_BTN",
              payload: false,
            });
          }
  
  }, [lastUpdatedTime,btnDisable]);

  let total = 0;

  const serviceDone = applyForm[0]?.services?.map(
    (service) => (total = total + Number(service?.price))
  )
  let tempTotal = total;
  total = total?.toFixed(2);
  
  const handleSubmit = (values, { setErrors, resetForm, setSubmitting }) => {
    const CREDENTIALS = {
      url: `/bookings/${applyForm[0]?.id}/coupons`,
      method: "patch",
      data: values,
      setErrors,
    };

    API.common(CREDENTIALS)
      .then(() => {
        showSuccessMessage();
        onClose();
        resetForm();
        dispatch({
          type: "USER_JOB_LAST_UPDATED_TIME",
          payload: timeStamp(),
        });
      })
      .catch((e) => {})
      .finally(() => {
        setSubmitting(false);
      });
  };

  function showSuccessMessage() {
    message.success(`coupon applied successfully`);
  }

  const handleAddCoupon = (applyForm, isEmpty) => {
    let data;
    
    if(isEmpty){
      const CREDENTIALS3 = {
        url: `/bookings?references=[\"${tempVar}"\]`,
        method: "get",
      };
  
      API.common(CREDENTIALS3)
        .then((response) => {
          console.log(response,"Hello");
        })
        .catch((err) => console.log(err));
    const CREDENTIALS = {
        url: `/bookings/${applyForm?.id}/remove-coupon`,
        method: "patch",
      };
  
      API.common(CREDENTIALS)
        .then((res) => {
          const CREDENTIALS3 = {
            url: `/bookings?references=[\"${tempVar}"\]`,
            method: "get",
          };
      
          API.common(CREDENTIALS3)
            .then((response) => {
              let tempRes=[];
        tempRes[0]= response?.data?.bookings;
              dispatch({
                type: "USER_JOB_LAST_UPDATED_TIME",
                payload: timeStamp(),
              });
              dispatch({
                type: "HIDE_APPLY_COUPONS",
                payload: {},
              });
              dispatch({
                type: "APPLY_COUPONS_FORM",
                payload: {},
              });
              dispatch({
                type: "ADD_COUPONS_FORM",
                payload: response.data.bookings,
              });
            })
            .catch((err) => console.log(err));
      //     let tempRes=[];
      // tempRes[0]= res?.data?.bookings;
      //       dispatch({
      //         type: "USER_JOB_LAST_UPDATED_TIME",
      //         payload: timeStamp(),
      //       });
      //       dispatch({
      //         type: "HIDE_APPLY_COUPONS",
      //         payload: {},
      //       });
      //       dispatch({
      //         type: "APPLY_COUPONS_FORM",
      //         payload: tempRes,
      //       });
        })
        .catch((err) => console.log(err));
     
    }else{
      let coupon =discount?.filter(item=>item.id == selectedCoupon);
      data = {coupon: coupon[0]?.code}

      const CREDENTIALS3 = {
        url: `/bookings?references=[\"${tempVar}"\]`,
        method: "get",
      };
  
      API.common(CREDENTIALS3)
        .then((response) => {
         
          dispatch({
            type: "GET_TEMP_PAYMENT",
            payload: response?.data?.bookings[0]?.payment,
          });
        })
        .catch((err) => console.log(err));
      const CREDENTIALS1 = {
        url: `/bookings/${applyForm?.id}/coupons`,
        method: "patch",
        data: data
      };
  
      API.common(CREDENTIALS1)
        .then((res) => {
          const CREDENTIALS3 = {
            url: `/bookings?references=[\"${tempVar}"\]`,
            method: "get",
          };
      
          API.common(CREDENTIALS3)
            .then((response) => {
              let tempRes=[];
        tempRes[0]= response?.data?.bookings;
              dispatch({
                type: "USER_JOB_LAST_UPDATED_TIME",
                payload: timeStamp(),
              });
              dispatch({
                type: "HIDE_APPLY_COUPONS",
                payload: {},
              });
              dispatch({
                type: "APPLY_COUPONS_FORM",
                payload: {},
              });
              dispatch({
                type: "ADD_COUPONS_FORM",
                payload: response.data.bookings,
              });
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
  
    }
  };

  
  

  const handleAddService = (applyForm1,category) => {
    let defaultService = applyForm1?.services?.map((item) => item?.id);
    let seviceSelectedTemp = selectedService?.map((item) => Number(item));
    let sevicesTemp = seviceSelectedTemp.concat(defaultService);

    let data = TempService.filter(item=>{
      if(sevicesTemp?.indexOf(item?.id) > -1){
        console.log(category);
        if(item?.category?.id == Number(category)){
          return item;
        }
      }
    })

    
    if(data?.length == 1){
      let findMe = data?.map(item=> Number(item?.type) == 2);
      if(findMe[0] == true){
        sevicesTemp = sevicesTemp.splice(1, sevicesTemp?.indexOf(data[0]?.id));
        message.error("Add on Service can't be added, if there is no Primary Service")
      }
    }

    let valueCopy = {
      id: applyForm1?.id,
      services: sevicesTemp,
    };

    const CREDENTIALS = {
      url: "/bookings/",
      method: "put",
      data: valueCopy,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        let tempRes=[];
        tempRes[0]= response?.data?.booking;
        
        dispatch({
          type: "USER_JOB_LAST_UPDATED_TIME",
          payload: timeStamp(),
        });
        dispatch({
          type: "HIDE_APPLY_COUPONS",
          payload: {},
        });
        dispatch({
          type: "APPLY_COUPONS_FORM",
          payload: {},
        });
        dispatch({
          type: "ADD_COUPONS_FORM",
          payload: tempRes,
        });
      })
      .catch((err) => console.log(err));
  };

  const handleRemove = (id) => {
    let defaultService = applyForm[0]?.services?.map((item) => item?.id);
    let sevicesTemp = [];
    if(defaultService?.length == 1){
      let data = TempService?.filter(item=> defaultService?.includes(item?.id));
      if(Number(data[0]?.type) == 1){
        message.error("Atleast one service should be there.")
      }else{
        sevicesTemp= defaultService?.filter((item) => item !== id);
      }
    }else{
      sevicesTemp= defaultService?.filter((item) => item !== id);
      let valueCopy = {
        id: applyForm[0]?.id,
        services: sevicesTemp,
      };
  
      const CREDENTIALS = {
        url: "/bookings/",
        method: "put",
        data: valueCopy,
      };
  
      API.common(CREDENTIALS)
        .then((response) => {
          let tempRes=[];
          tempRes[0]= response?.data?.booking;
          dispatch({
            type: "USER_JOB_LAST_UPDATED_TIME",
            payload: timeStamp(),
          });
          dispatch({
            type: "HIDE_APPLY_COUPONS",
            payload: {},
          });
          dispatch({
            type: "APPLY_COUPONS_FORM",
            payload: {},
          });
          dispatch({
            type: "ADD_COUPONS_FORM",
            payload: tempRes,
          });
          
        })
        .catch((err) => console.log(err));
    }
   

    
  };

let serviceTypeOne;
let serviceTypeTwo;
let categories;
let uniqueCategories;
  serviceTypeOne = applyForm[0]?.services?.filter(
    (ser) => ser?.type === 1
  );
  serviceTypeTwo = applyForm[0]?.services?.filter(
    (ser) => ser?.type === 2
  );
  categories = applyForm[0]?.services?.map((ser) => ser.category);
  uniqueCategories = [...new Set(categories?.map((item) => item?.name))];
  

  let taxCalulation1 =
    applyForm[0]?.payment?.coupon?.type === 2
      ? ((tempTotal / 100) * applyForm[0]?.payment?.coupon?.percent).toFixed(2)
      : 0;
  let calculateForTax = tempTotal - taxCalulation1;
  let dataMy =
    applyForm[0]?.payment?.coupon?.type === 2 ? calculateForTax : tempTotal;

const handleCategory=(e)=>{
  setSelectedCat(e);
  const newData = TempService?.filter(item=> item?.category?.id == e);
  setDataSevice(newData);
}
  
  return (
    <Formik
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
        <Form layout="vertical" hideRequiredMark>
          {/* <Row gutter={8} className="m-2">
            <Col span={24}>
              <Form.Item
                name="coupon"
                label="Coupon"
                hasFeedback={true}
                showValidateSuccess={true}
              >
                <Input
                  name="coupon"
                  placeholder="Add Coupon"
                  disabled={applyForm?.payment?.coupon?.code ? true : false}
                />
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={8} className="m-2">
            <Col span={24}>
            <div
                style={{
                  padding: "20px 0 15px 15px"
                }}
              >
              <p><b>Services</b></p>
              {uniqueCategories?.map((category) => (
                <div
                  style={{
                    paddingTop: "5px",
                  }}
                >
                  <p><b>{category}</b></p>
                  {serviceTypeOne?.filter((ser) => ser?.category?.name === category)?.length >0 &&  <p><b style={{color:"#d9d9d9",lineHeight:0}}>Primary Service</b></p>}
                 
                  {serviceTypeOne
                    ?.filter((ser) => ser?.category?.name === category)
                    ?.map((showSer) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ display: "flex" }}>
                          <span style={{ width: "350px", display: "block",marginTop:"5px" }}>
                            {showSer?.name}
                          </span>
                          <Button
                            type="link"
                            onClick={() => handleRemove(showSer?.id)}
                          >
                            Remove
                          </Button>
                        </p>
                        <p style={{marginTop:"5px"}}>${showSer?.price?.toFixed(2)}</p>
                      </div>
                    ))}
                    {serviceTypeTwo?.filter((ser) => ser?.category?.name === category)?.length >0 && <p><b style={{color:"#d9d9d9"}}>Add on Service</b></p>}
                  {serviceTypeTwo
                    ?.filter((ser) => ser?.category?.name === category)
                    ?.map((showSer) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p style={{ display: "flex", justifyContent:"space-between" }}>
                          <span style={{ width: "350px", display: "block",marginTop:"5px" }}>
                            {showSer?.name}
                          </span>
                          <Button
                            type="link"
                            onClick={() => handleRemove(showSer?.id)}
                          >
                            Remove
                          </Button>
                        </p>
                        <p style={{marginTop:"5px"}}>${showSer?.price?.toFixed(2)}</p>
                      </div>
                    ))}
                </div>
              ))}
             </div>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12} style={{marginLeft:"10px"}}>
                <p><b>Select Category</b></p>
                <Select
                  onChange={(e) => handleCategory(e)}
                  value={selectedCat}
                  style={{ width: "100%"}}
                  allowClear={true}
                  mode="single"
                  placeholder="Select Category"
                >
                  {productsCategories?.map((item) => (
                    <Option key={item?.id}>{item?.name}</Option>
                  ))}
                </Select>
              </Col>
              <Col span={11}>
                <p><b>Add Service</b></p>
                <Select
                  onChange={(e) => setSelectedService(e)}
                  value={selectedService}
                  style={{ width: "100%"}}
                  allowClear={true}
                  mode="multiple"
                  placeholder="Select Service"
                >
                  <OptGroup label="Primary Service">
                  {DateSevice?.filter(item=> item.type === 1).map((item) => (
                    <Option key={item?.id}>{item?.name}</Option>
                  ))}
                  </OptGroup>
                  <OptGroup label="Add on Service">
                  {DateSevice?.filter(item=> item.type === 2).map((item) => (
                    <Option key={item?.id}>{item?.name}</Option>
                  ))}
                  </OptGroup>
                  
                </Select>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24} style={{display:"flex", justifyContent:"flex-end"}}>
            <Button onClick={() => handleAddService(applyForm[0],selectedCat)} style={{marginTop:"10px", marginBottom:"10px", marginRight:"10px"}}>
                  Add Service
                </Button>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <div
                style={{
                  padding: "20px 0 15px 15px",
                  borderTop: "1px solid #d9d9d9",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                >
                  <p>
                    <b>Sub Total</b>
                  </p>
                  <p>${total}</p>
                </div>
                {applyForm[0]?.payment?.coupon?.type === 2 && (
                  <div
                    style={{
                      paddingTop: "10px"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        
                      }}
                    >
                      <p style={{width:"350px"}}>
                        <b>Applied Coupon</b> -
                        {applyForm[0]?.payment?.coupon?.type === 2
                          ? applyForm[0]?.payment?.coupon?.percent
                          : 0}
                        %
                      </p>
                      <Button type="link" onClick={()=>handleAddCoupon(applyForm[0],true)}>Remove</Button>
                      <p style={{marginTop:"5px"}}>
                        -$
                        {applyForm[0]?.payment?.coupon?.type === 2
                          ? (
                              (total / 100) *
                              applyForm[0]?.payment?.coupon?.percent
                            ).toFixed(2)
                          : 0}
                      </p>
                    </div>
                    <p>{applyForm[0]?.payment?.coupon?.code}</p>
                  </div>
                )}
              
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                >
                  <p>
                    <b>Dispatch Fee</b>
                  </p>
                  <p>
                    $
                    {applyForm[0]?.payment?.dispatch
                      ? `${applyForm[0]?.payment?.dispatch?.toFixed(2)}`
                      : `0.00`}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                >
                  <p>
                    <b>Tax</b> -{" "}
                    {applyForm[0]?.payment?.tax
                      ? applyForm[0]?.payment?.tax
                      : 0}
                    %
                  </p>
                  <p>
                    $
                    {applyForm[0]?.payment?.tax
                      ? (
                          ((dataMy + applyForm[0]?.payment?.dispatch) / 100) *
                          applyForm[0]?.payment?.tax
                        ).toFixed(2)
                      : 0}
                  </p>
                </div>
                <div style={{borderTop:"1px solid #d9e9e9" }}>
                {applyForm[0]?.payment?.coupon?.type === 1 && (
            <div>
        <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p style={{width:"350px"}}><b>Applied Coupon</b></p>
            <Button type="link" onClick={()=>handleAddCoupon(applyForm[0],true)}>Remove</Button>
            <p style={{marginTop:"5px"}}>-${applyForm[0]?.payment?.coupon?.type ===1? applyForm[0]?.payment?.coupon?.amount?.toFixed(2):0}</p>
          </div>
          <p>{applyForm[0]?.payment?.coupon?.code}</p>
          </div>
        )}
        <div style={{display: "flex",
              justifyContent: "space-between", marginBottom:applyForm[0]?.payment?.coupon !==null? "80px":''}}>
            <p>
              <b>Total</b>
            </p>
            <p>${applyForm[0]?.payment?.amount}</p>
          </div>
          </div>
              </div>
              
            </Col>
          </Row>
          {applyForm[0]?.payment?.coupon ===null && (
          <Row gutter={8} className="m-2" style={{marginBottom:"80px"}}>
            <Col span={24}>
            <div style={{padding: "20px 0 15px 15px", }}>
            <p style={{fontWeight:"bold"}}>Add Coupon</p>
                <Select
                  onChange={(e) => setSelectedCoupon(e)}
                  value={selectedCoupon}
                  style={{ width: "200px", marginRight: "18px" }}
                  allowClear={true}
                  placeholder="Select Coupon"
                >
                  {discount?.map((item) => (
                    <Option key={item?.id}>{item?.code}</Option>
                  ))}
                </Select>
                <Button onClick={() => handleAddCoupon(applyForm[0],false)}>
                  Add Coupon
                </Button>
              </div>
            </Col>
          </Row>
          )}
          <FormActionButtons onClose={onClose} isSubmitting={true} reset={false} finalize={true} id={addId} newSubmit={btnDisable} noNeedSaveBtn={false}/>
        </Form>
      )}
    </Formik>
  );
}
