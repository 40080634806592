const initialState = {
	marketpreferenceFormVisible: false,
	editablemarketpreference: {},
	lastUpdatedTime: '',
	isModalVisible:{},
	showService:false,
	showSchedule:false,
	showServiceData:{},
	showScheduleData:{},
	scheduleNew:false,
	showSchedulesNewData:{}
};

export function marketpreference(state = initialState, action) {
	if (action.type === 'SHOW_MARKET_PREFERENCE_FORM') {
		return {
			...state,
			marketpreferenceFormVisible: true,
			editablemarketpreference: action.payload,
			
		};
	} else if (action.type === 'SHOW_SERVICE') {
		return { ...state, showService: true, showServiceData:action.payload};
	}
	else if (action.type === 'SHOW_SCHEDULE') {
		return { ...state, showSchedule: true,showScheduleData:action.payload};
	}else if(action.type === 'SHOW_NEW_SCHEDULE'){
		return { ...state, scheduleNew: true,showSchedulesNewData:action.payload};
	}
	else if (action.type === 'SHOW_HIDE_NEW_MARKET_PREFERENCE_FORM') {
		return { ...state, scheduleNew: false,showSchedulesNewData:{} };
	}else if (action.type === 'SHOW_NEW_LAST_UPDATED_TIME') {
		return { ...state, lastUpdatedTime: action.payload };
	}
	else if (action.type === 'HIDE_MARKET_PREFERENCE_FORM') {
		return { ...state, marketpreferenceFormVisible: false, editablemarketpreference: {},showService:false,showSchedule:false,showServiceData:{},showScheduleData:{} };
	} else if (action.type === 'MARKET_PREFERENCE_STATUS_CHANGE_FORM') {
		let data = state.editablemarketpreference;
		data.status = action.payload;
		return {
			...state,
			editablemarketpreference: data,
		};
	} else if (action.type === 'MARKET_PREFERENCE_LAST_UPDATED_TIME') {
		return { ...state, lastUpdatedTime: action.payload };
	}else if (action.type === 'MARKET_MODAL') {
		return { ...state, isModalVisible: action.payload };
	} 
	else {
		return state;
	}
}
