import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Field } from "formik-antd";
import { Col, Row, message, Checkbox, Radio } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import { FormActionButtons } from "../FormActionButtons";
import { getCategories } from "services/common";
import * as Yup from "yup";
import { API } from "services";
import { leftToFilterOptions } from "helpers";

export function DiscountForm({ onClose, editMode, editableDiscount }) {
  const [accessData, setAccess] = useState(1);
  const [showPercentage, setShowPercentage] = useState(false);

  const initialValues = {
    code: editMode ? editableDiscount?.code : undefined,
    percent: editMode ? editableDiscount?.percent : undefined,
    amount:editMode?editableDiscount?.amount:undefined,
    status: editMode
      ? editableDiscount
        ? editableDiscount?.active === 1
          ? true
          : false
        : false
      : false,
    type: editMode ? editableDiscount?.type : 2,
  };

  useEffect(()=>{
    if(editMode){
      if (editableDiscount.type === 2) {
        setShowPercentage(false);
      } else {
        setShowPercentage(true);
      }
    }
  },[])
  const handleSubmit = (values, { setErrors, resetForm, setSubmitting }) => {
    const valuesCopy = { ...values };
console.log(valuesCopy);
    if (valuesCopy.status === true) {
      valuesCopy.available = 1;
    } else {
      valuesCopy.available = 0;
    }
    delete valuesCopy?.status;

    if (editMode) {
      valuesCopy.id = editableDiscount?.id;

      // if(editableDiscount?.active)
    }

    if(valuesCopy?.type === 1){
      valuesCopy.percent = null;
    }else{
      valuesCopy.amount = null;
    }

    
    const CREDENTIALS = {
      url: `/coupons/`,
      method: editMode ? "put" : "post",
      data: valuesCopy,
      setErrors,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        showSuccessMessage(valuesCopy);
        resetForm();
        onClose();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  function showSuccessMessage(values) {
    let text = editMode ? "updated" : "added";
    message.success(`${values.code} coupon ${text} successfully`);
  }

  let ServiceSchema;

  if(!editMode){
  ServiceSchema= Yup.object({
    code: Yup.string().required("Coupon Name is required"),
    type: Yup.number(),
    percent: Yup.string().when("type", {
      is: (type) => type === 2? true:false,
      then: Yup.string().required("Percentage is required").nullable(),
    }),
    amount: Yup.string().when("type", {
      is: (type) => type === 2?false:true,
      then: Yup.string().required("Amount is required").nullable(),
    }),
  });
}
console.log(accessData);

if(editMode && editableDiscount?.type === 2){
  ServiceSchema= Yup.object({
    code: Yup.string().required("Coupon Name is required"),
    type: Yup.number(),
    percent: Yup.string().when("type", {
      is: (type) => type === 2? true:false,
      then: Yup.string().required("Percentage is required").nullable(),
    })
  });
}
else{
  ServiceSchema= Yup.object({
    code: Yup.string().required("Coupon Name is required"),
    type: Yup.number(),
    amount: Yup.string().when("type", {
      is: (type) => type === 2?false:true,
      then: Yup.string().required("Amount is required").nullable(),
    }),
    percent: Yup.string().when("type", {
      is: (type) => type === 2? true:false,
      then: Yup.string().required("Percentage is required").nullable(),
    })
  });
}
  
  
  const handleAccess = (e, setFieldValue) => {
    console.log(e);
    setFieldValue("type", e.target.value);

    setAccess(e.target.value);

    if (e.target.value === 2) {
      setShowPercentage(false);
    } else {
      setShowPercentage(true);
    }
  };

  const handleChange=(e,values)=>{
    console.log(e,'-------',values);
  }
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={ServiceSchema}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={8}>
              <Col span={24} className="discount_form">
                <Form.Item
                  name="code"
                  label="Coupon Name"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="code" placeholder="Enter Coupon Name" />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={8}>
              <Col span={24} className="discount_form">
                <Form.Item
                  name="code"
                  label="Coupon Code"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="code" placeholder="Enter Coupon Name" />
                </Form.Item>
              </Col>
            </Row> */}
            {/* <Row>
              <Col span={24} className="discount_form">
                <Form.Item
                  name="type"
                  label="Discount Type"
                  hasFeedback={true}
                  showValidateSuccess={true}
                  className="discount"
                >
                   <Radio.Group
                    value={values?.type}
                    name="type"
                    onChange={(e) => handleAccess(e, setFieldValue)}
                  >
                    <Radio value={1}>Flat Price</Radio>
                    <Radio value={2}>Percentage</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              </Row> */}
              {!editMode &&(
            <Row>
              <Col span={24} className="discount_form">
                <Form.Item
                  name="type"
                  label="Discount Type"
                  hasFeedback={true}
                  showValidateSuccess={true}
                  className="discount"
                >
                  <Radio.Group
                    value={values?.type}
                    name="type"
                    onChange={(e) => handleAccess(e, setFieldValue)}
                  >
                    <Radio value={2}>Percentage</Radio>
                    <Radio value={1}>Flat Price</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            )}
            {editMode &&(
            <Row>
              <Col span={24} className="discount_form">
                <Form.Item
                  name="type"
                  label="Discount Type"
                  hasFeedback={true}
                  showValidateSuccess={true}
                  className="discount"
                >
                  <Radio.Group
                    value={values?.type}
                    name="type"
                    onChange={(e) => handleAccess(e, setFieldValue)}
                  >
                    {/* <Radio value={2} disabled={editableDiscount?.type === 1?true:false}>Percentage</Radio>
                    <Radio value={1} disabled={editableDiscount?.type === 2?true:false}>Flat Price</Radio> */}
                    <Radio value={2}>Percentage</Radio>
                    <Radio value={1}>Flat Price</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
              )}
            <Row gutter={8}>
              <Col span={24} className="discount_form">
                <Form.Item
                  name={showPercentage === false ? "percent" : "amount"}
                  label={
                    showPercentage === false
                      ? "Discount Percentage"
                      : "Discount Amount"
                  }
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  {showPercentage === false ? (
                    <InputNumber
                      name="percent"
                      data-testid="tax-field"
                      min={0}
                      max={100}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      // defaultValue={0}

                      step={1}
                      placeholder="Tax"
                      style={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    <Input
                      name="amount"
                      placeholder="Enter Discount Amount"
                      type="number"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={8}>
              <Col span={24} className="discount_form">
                <Form.Item
                  name="percent"
                  label="Discount Percentage"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <InputNumber
                    name="percent"
                    data-testid="tax-field"
                    min={0}
                    max={100}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace("%", "")}
                    // defaultValue={0}
                    step={1}
                    placeholder="Discount Percentage"
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row> */}
            {/* <Row gutter={8}>
              <Col span={24} className="discount_form">
                <Form.Item
                  name="maxRedeems"
                  label="Max Redeems"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input name="maxRedeems" placeholder="Enter Max Redeems Value" type="number"/>
                </Form.Item>
              </Col>
            </Row> */}
            {/* <Row>
              <Col span={24} className="service_form">
                <Form.Item
                  name="description"
                  label="Description"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <Input.TextArea
                    name="description"
                    placeholder="Description"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col span={24} className="discount_form">
                <Form.Item
                  name="status"
                  label="Status"
                  hasFeedback={true}
                  showValidateSuccess={true}
                >
                  <label>
                    <Field type="checkbox" name="status" /> Active
                  </label>
                </Form.Item>
              </Col>
            </Row>

            <FormActionButtons onClose={onClose} isSubmitting={isSubmitting}/>
          </Form>
        )}
      </Formik>
    </div>
  );
}
