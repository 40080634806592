import { Layout } from 'antd';
import { Loader, PrivateRoute, Sidebar } from 'components/common';
import { getUserData } from 'helpers/data-parser';
import * as Pages from 'pages';
import TimeSlots from 'components/pages/marketpreference/TimeSlots';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { getUserACL } from 'services/common';
import ScheduleNew from 'components/pages/marketpreference/ScheduleNew';

const { Content } = Layout;

export default function Router() {
	return (
		<BrowserRouter>
			<Layout style={{ minHeight: '100vh' }}>
				<Loader />
				<Sidebar />
				<Layout>
					<Content>
						<Switch>
							<Redirect
								exact
								from="/"
								to={{
									pathname:'/users'
								}}
							/>
							{/* authenticated pages starts*/}
							<PrivateRoute exact path="/users">
								<Pages.Users />
							</PrivateRoute>
							<PrivateRoute exact path="/profile">
								<Pages.Profile />
							</PrivateRoute>
							<PrivateRoute exact path="/jobs">
								<Pages.Jobs />
							</PrivateRoute>
							<PrivateRoute exact path="/technician">
								<Pages.FieldTechnician />
							</PrivateRoute>
							<PrivateRoute exact path="/consumers">
								<Pages.Consumers/>
							</PrivateRoute>
							<PrivateRoute exact path="/markets/timeslots">
								<TimeSlots/>
							</PrivateRoute>
							<PrivateRoute exact path="/markets/schedule">
								<ScheduleNew/>
							</PrivateRoute>
							<PrivateRoute exact path="/markets">
								<Pages.Markets/>
							</PrivateRoute>
							
							<PrivateRoute exact path="/services">
								<Pages.Services/>
							</PrivateRoute>
							<PrivateRoute exact path="/pricings">
								<Pages.Pricings/>
							</PrivateRoute>
							<PrivateRoute exact path="/discount">
								<Pages.Discount/>
							</PrivateRoute>
							{/* authenticated pages ends*/}
							<Route path="*">
								<Pages.NotFound />
							</Route>
						</Switch>
					</Content>
				</Layout>
			</Layout>
		</BrowserRouter>
	);
}
