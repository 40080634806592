import React,{useState} from 'react';
import { Form,Input} from 'formik-antd';
import { Col, Row,DatePicker,Switch,message } from 'antd';
import { Formik } from 'formik';
import { FormActionButtons } from '../FormActionButtons';
import * as Yup from 'yup'
import { useSelector } from 'react-redux';
import moment from 'moment';
import {API} from "services";


function Schedule({onClose}) {
    const { RangePicker } = DatePicker;
    const [weekend, setWeekend]=useState(false);
    const { showScheduleData } = useSelector((state) => state.marketpreference);

    const initialValues = {
        date: undefined,
        numberOfDays: undefined,
        weekend:false
      };

    
      let ScheduleSchema= Yup.object({
		date: Yup.string().required('Date is required'),
        numberOfDays: Yup.string().required('Number of Days is required'),
		})

    
        const handleSubmit=(values,{ setErrors, resetForm, setSubmitting})=>{
            const valuesCopy ={...values};

            valuesCopy.date = moment(valuesCopy.date).format("YYYY-MM-DD");
            valuesCopy.market = showScheduleData?.id;
            valuesCopy.isWeekend = valuesCopy?.weekend;

            delete valuesCopy.weekend;

                const CREDENTIALS = {
                    url: `/schedules/generate`,
                    method: 'post',
                    data: valuesCopy
                };
        
                API.common(CREDENTIALS)
                    .then((response) => {
                        showSuccessMessage(valuesCopy);
                    resetForm();
                    onClose();
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
        }

        function showSuccessMessage(values) {
            let text = 'added';
            message.success(`Schedule ${text} successfully`);
        }
    
    
    const handleWeekend=(e,setFieldValue,values)=>{
        setWeekend(e);
        setFieldValue('weekend',e);
    }

    const handleDateChange=(e,setFieldValue)=>{
        setFieldValue('date',e);
    }
    return (
<div>
			<Formik
				initialValues={initialValues}
				validationSchema={ScheduleSchema}
				onSubmit={handleSubmit}
			>
				{({ values, isSubmitting, setFieldValue, setFieldTouched }) => (
					<Form layout="vertical" hideRequiredMark>
                        <p>Current Market: {showScheduleData?.alias == null? 'NA':showScheduleData?.alias}</p>
						<Row>
							<Col span={24}>
								<Form.Item
									name="date"
									label="Start Date:"
									hasFeedback={true}
									showValidateSuccess={true}
								>
									 <DatePicker name="date" style={{width:"100%"}} onChange={(e)=>handleDateChange(e,setFieldValue)}
                                     disabledDate={(current) => {
                                        return moment().add(-1, 'days')  >= current
                                        }}/>
								</Form.Item>
							</Col>
						</Row>
                        <Row>
                        <Col span={24}>
								<Form.Item
									name="numberOfDays"
									label="Number of Days:"
									hasFeedback={true}
									showValidateSuccess={true}
								>
									   <Input
                                        name="numberOfDays"
                                        placeholder="Number of Days"
                                        type="number"
                                      />
								</Form.Item>
							</Col>
                        </Row>
                        <Row>
							<Col span={24}>
								<Form.Item
									name="weekend"
									label="weekend"
									hasFeedback={true}
									showValidateSuccess={true}
								>
									<Switch onChange={(e)=>handleWeekend(e,setFieldValue,values)} value={weekend}/>
								</Form.Item>
							</Col>
						</Row>

						<FormActionButtons
							onClose={onClose}
							isSubmitting={isSubmitting}
                            reset={false}
						/>
					</Form>
				)}
			</Formik>
		</div>
    )
}

export { Schedule };
