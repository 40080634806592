import { ToolOutlined } from '@ant-design/icons';
import { Col, message as toaster, Modal, Row, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import jsSha512 from 'js-sha512';
import React, { useState } from 'react';
import { API } from 'services';
import * as Yup from 'yup';

const { Title } = Typography;

const MIN = 8,
	MAX = 15;

const ChangePasswordSchema = Yup.object({
	oldPassword: Yup.string().required('Old password required'),
	newPassword: Yup.string()
		.required('Please enter your new password')
		.min(MIN, `Minimum ${MIN} characters needed`)
		.max(MAX, `Maximum ${MAX} characters only allowed`)
		.matches(
			/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
			'Must Contain One Uppercase, One Lowercase, One Number and one special case Character'
		)
		.notOneOf(
			[Yup.ref('oldPassword'), null],
			'New password should not same as old password'
		),
	confirmPassword: Yup.string()
		.required('Confirm new password required')
		.oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
		.label('Confirm password'),
});

const initialValues = {
	oldPassword: undefined,
	newPassword: undefined,
	confirmPassword: undefined,
};

export function ChangePassword() {
	const [showChangePassword, setShowChangePassword] = useState(false);

	function handleShowChangePassword() {
		setShowChangePassword(true);
	}

	function hideChangePassword() {
		setShowChangePassword(false);
	}

	function changePassword(values, { setSubmitting, resetForm }) {
		const newVal = {
			oldPassword: values.oldPassword,
			newPassword: values.newPassword,
		};
		const userId = JSON.parse(localStorage.getItem('userInfoData'))?.id;

		const CREDENTIALS = {
			url: `/users/${userId}/change-password`,
			method: 'put',
			data: newVal,
		};

		API.common(CREDENTIALS)
			.then(() => {
				handleChangePasswordSuccess();
				resetForm(values={});
			})
			.finally(() => {
				setSubmitting(false);
			});
	}

	function handleChangePasswordSuccess() {
		toaster.success('Password changed successfully');
		setTimeout(() => {
			hideChangePassword();
		}, 1000);
	}

	return (
		<div>
			<div>
				<ToolOutlined />
				<span onClick={handleShowChangePassword}>Change password</span>
			</div>
			<Modal
				visible={showChangePassword}
				footer={null}
				closable={true}
				mask={true}
				maskClosable={false}
				onCancel={hideChangePassword}
			>
				<Row justify="center" align="middle">
					<Col span={24}>
						<Title className="center-align" level={2}>
							Change password
						</Title>
						<p
							className="center-align"
							style={{ marginBottom: '30px' }}
						>
							Enter your details below.
						</p>
						<Formik
							initialValues={initialValues}
							validationSchema={ChangePasswordSchema}
							onSubmit={changePassword}
						>
							{({ isSubmitting, isValid }) => (
								<Form className="change-password-form">
									<Row gutter={8}>
										<Col span={24}>
											<Form.Item
												name="oldPassword"
												hasFeedback={true}
												showValidateSuccess={true}
											>
												<Input.Password
													name="oldPassword"
													placeholder="Type your old password"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={8}>
										<Col span={24}>
											<Form.Item
												name="newPassword"
												hasFeedback={true}
												showValidateSuccess={true}
											>
												<Input.Password
													name="newPassword"
													placeholder="Type your new password"
												/>
											</Form.Item>
										</Col>
									</Row>
									<Row gutter={8}>
										<Col span={24}>
											<Form.Item
												name="confirmPassword"
												hasFeedback={true}
												showValidateSuccess={true}
											>
												<Input.Password
													name="confirmPassword"
													placeholder="Confirm new password"
												/>
											</Form.Item>
										</Col>
									</Row>

									<SubmitButton
										data-testid="form-save-btn"
										type="primary"
										disabled={isSubmitting || !isValid}
										className="login-btn"
									>
										Change password
									</SubmitButton>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Modal>
		</div>
	);
}
