const initialState = {
	pricingFormVisible: false,
	editablePricing: {},
	lastUpdatedTime: ''
};

export function pricings(state = initialState, action) {
	if (action.type === 'SHOW_PRICING_FORM') {
		return {
			...state,
			pricingFormVisible: true,
			editablePricing: action.payload,
			
		};
	} else if (action.type === 'HIDE_PRICING_FORM') {
		return { ...state, pricingFormVisible: false, editablePricing: {} };
	} else if (action.type === 'PRICING_STATUS_CHANGE_FORM') {
		let data = state.editablePricing;
		data.status = action.payload;
		return {
			...state,
			editablePricing: data,
		};
	} else if (action.type === 'PRICING_LAST_UPDATED_TIME') {
		return { ...state, lastUpdatedTime: action.payload };
	} else {
		return state;
	}
}
