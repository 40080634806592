import { message as toaster } from 'antd';
import { isArray, isObject } from 'lodash';
import qs from 'qs';
import axios from 'services/api.config';
import { handle401Error } from 'services/authentication';
import { apiActions } from 'store/actions';
import store from 'store/configureStore';


class ApiRequestClass {
	// constructor() {
	// 	console.log('instance', ApiRequestClass.instance, this);
	// }
	/**
	 *
	 * @param {string} 
	 * @param {object} data
	 * @param {boolean} showLoader
	 * @param {string} method - must be a lowercase, it's axios get, post, delete, patch methods
	 * @param {object} queryParams
	 */
	async common({
		url,
		data = {},
		showLoading = true,
		method = 'get',
		queryParams = {},
		setErrors,
		config = {},
		...props
	}) {
		if (showLoading) {
			this.handleShowLoader();
		}

		try {
			let URL = this.addQueryParamsWithUrl(url, queryParams);
			const response = await axios[method](URL, data, config);
			return Promise.resolve(response.data);
		} catch (error) {
			this.handleErrors(error, setErrors, url,queryParams);
			return Promise.reject(error);
		} finally {
			this.handleHideLoader();
		}
	}

	addQueryParamsWithUrl(url, queryParams) {
		
		Object.keys(queryParams).forEach(
			(key) =>
				(queryParams[key] === null || queryParams[key] === '') &&
				delete queryParams[key]
		);
		return url + qs.stringify(queryParams, { addQueryPrefix: true });
	}

	handleErrors(error, setErrors, url,queryParams) {
		if (error) {
			try {
				const data = error.response.data;
				const status = error.response.status;
				let checkNetworkError = JSON.stringify(error);
				const NetworkError = 'Network Error';

				if (checkNetworkError.includes(NetworkError)) {
					toaster.error(NetworkError);
					return false;
				}
				if (status === 500) {
					toaster.error(error.message);
				} else if (status === 401) {
				
				const errors =error.response.data.errors.messages;
				toaster.error(errors[0]);
					handle401Error(errors[0]);
				} else if (status === 422) {
					/**
					 * 422 error is form validation error
					 */
				    console.log('422 error', error);
					this.handle422Error(error, setErrors);
					this.handleCommonErrors(data,url,queryParams);
				} else {
					this.handleCommonErrors(data,url,queryParams);
				}
			} catch (e) {
				console.log('e 123', e);
				let errorText = error.toString();
				if (errorText.includes('timeout')) {
					toaster.error('API Timeout');
				} else if (errorText.includes('no access')) {
					toaster.error(
						"You don't have permission to do this action"
					);
				} else {
					toaster.error('Something went wrong please try again');
					console.log('Unhandled error', e);
				}
			}
		}
	}

	handleCommonErrors(data,url,queryParams) {
		const error = data?.errors?.message;
		const errors = data?.errors?.messages;
	
		if (error) {
			if (isArray(error)) {
				toaster.error(error[0]);
			} else if (!isArray(error)) {
				toaster.error(error);
			}
		} else if (errors) {
			if (isArray(errors)) {
				
				if(isObject(errors[0].email)){

				}else{
					if(errors[0] == "Code not valid at this time."){
						toaster.error('Coupon Code does not exist');
					}else{
					toaster.error(errors[0]);
					}
				}
			} else if (!isArray(errors)) {
				toaster.error(errors);
			}
		}
	}

	handleShowLoader() {
		store.dispatch(apiActions.showLoader());
		store.dispatch({
			type: 'INCREMENT_LOADER_COUNTER',
		});
	}

	handleHideLoader() {
		store.dispatch(apiActions.hideLoader());
		store.dispatch({
			type: 'DECREMENT_LOADER_COUNTER',
		});
	}

	handle422Error(error, setErrors) {
		let serverErrors = error.response.data.errors.message
			? error.response.data.errors.message
			: error.response.data.errors.messages;
		if (serverErrors && serverErrors.length > 0) {
			serverErrors = serverErrors[0];
			let errorKeys = {};
			for (let key of Object.keys(serverErrors)) {
				errorKeys[key] = serverErrors[key][0];
			}
			if (setErrors) {
				setErrors(errorKeys);
			} else {
				if (isObject(serverErrors)) {
					let err = 'error';
					try {
						console.log('serverErrors 111xxx', serverErrors);
						err = serverErrors[Object.keys(serverErrors)[0]];
						console.log('err', err);
						console.log('err 0', err[0].toString());
						toaster(err[0].toString()); //returns 'someVal'
					} catch (er) {
						console.log('er 1111', er);
						toaster(err[0].toString()); //returns 'someVal'
					}
				} else {
					toaster.error(serverErrors);
				}
			}
		} else {
			toaster.error(error.response.data.errors.messages[0]);
		}
	}
}

export const API = new ApiRequestClass();
