import React,{useState,useEffect} from 'react'
import { Form, Input, Select } from 'formik-antd'
import {  Col, Row,message } from 'antd'
import { Formik } from 'formik'
import { FormActionButtons } from '../FormActionButtons'
import * as Yup from 'yup'
import {leftToFilterOptions} from "helpers";
import {API} from "services";


export function ProfileForm({onClose,editMode,editableProfile}) {
    const { Option } = Select;
    const [markets,setMarkets]=useState([]);
    useEffect(()=>{
		const CREDENTIALS = {
			url: `/markets`,
			method: 'get',
      queryParams:{
          all:true
      }
		};

		API.common(CREDENTIALS)
			.then((response) => {
				console.log(response.data.markets);
				setMarkets(response.data.markets);
			}).catch(err=>console.log(err))
	},[])

    const initialValues={
		firstName:
			editMode?editableProfile.firstName
				: undefined,
		lastName:
			editMode? editableProfile.lastName
				: undefined,
		email: editMode ? editableProfile.email : undefined,
		countryCode: editMode ? Number(editableProfile.countryCode): 1,
		phone: editMode ? editableProfile.phone : undefined,
	}

	const UserSchema= Yup.object({
		firstName: Yup.string()
			.matches(/^[a-zA-Z ]*$/, 'Is not in correct format')
			.required('First Name required'),
		lastName: Yup.string()
			.matches(/^[a-zA-Z ]*$/, 'Is not in correct format')
			.required('Last name required'),
		email: Yup.string()
			.required('Email required')
			.email('Invalid email format'),
		countryCode: Yup.number().required('Country code required'),
		phone: Yup.string()
			.required('Phone number required')
			.matches(/^[1-9]\d{9}$/, {
				message: 'Please enter valid phone number.',
				excludeEmptyString: false,
			}),
		})

        const handleSubmit=(values,{ setErrors,setSubmitting, resetForm })=>{
        const valuesCopy ={...values};
		valuesCopy.phone = String(valuesCopy.phone);
		valuesCopy.countryCode = String(valuesCopy.countryCode);
        valuesCopy.id =editableProfile.id;
        valuesCopy.markets = markets.map(mkt=> mkt.id);
        const CREDENTIALS = {
            url: `/users`,
            method: 'put',
            data: valuesCopy,setErrors
        };

        API.common(CREDENTIALS)
            .then((response) => {
                console.log(response);
            resetForm();
            onClose();
            })
            .finally(() => {
                setSubmitting(false);
            });
        }
    return (
        <Formik
        initialValues={initialValues}
        validationSchema={UserSchema}
        onSubmit={handleSubmit}
        
    >
        {({ values, isSubmitting, setFieldValue,setFieldTouched }) => (
            <Form layout="vertical" hideRequiredMark>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="firstName"
                            label="First name"
                            hasFeedback={true}
                            showValidateSuccess={true}
                        >
                            <Input
                                name="firstName"
                                placeholder="First name"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="lastName"
                            label="Last name"
                            hasFeedback={true}
                            showValidateSuccess={true}
                        >
                            <Input
                                name="lastName"
                                placeholder="Last name"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="email"
                            label="Email"
                            hasFeedback={true}
                            showValidateSuccess={true}
                        >
                            <Input name="email" placeholder="Email"  disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="phone"
                            label="Phone number"
                            hasFeedback={true}
                            showValidateSuccess={true}
                        >
                            <Input
                                addonBefore={
                                    <Select
                                        showSearch
                                        name="countryCode"
                                        style={{ width: 120 }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(
                                                    input.toLowerCase()
                                                ) >= 0
                                        }
                                    >
                                        <Option key={1} value={1}>
                                            +1 - USA
                                        </Option>
                                        <Option key={91} value={91}>
                                            +91 - India
                                        </Option>
                                    </Select>
                                }
                                type="number"
                                step="1"
                                name="phone"
                                placeholder="Phone number"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
              
                <FormActionButtons
                    onClose={onClose}
                    isSubmitting={isSubmitting}
                />
            </Form>
        )}
    </Formik>
    )
}


