import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import {API} from "services";
import { getTechnicians } from "services/common";
import { useQueryParams } from "hooks";
import { Popconfirm } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { timeStamp } from 'helpers/date-formatter';

export default function DTTechnician() {
  const dispatch = useDispatch();

  const { userStatus } = useQueryParams();
  const [loading, setLoading] = useState(false);
  const { lastUpdatedTime } = useSelector((state) => state.technician);
  const { technicians = [] } = useSelector((state) => state?.model);
  
  const [pagination, setPagination] = useState({
    all: true,
    page:1
  });

  useEffect(() => {
    //refresh table when data is changed or new data is added
    handleTableChange();
  }, [lastUpdatedTime, userStatus]);


  const handleTableChange = (pagi) => {
    //let status = userStatus === "false" ? 0 : 1;
    let newFilters = {
      ...pagination
    };
    if (pagi) {
      newFilters = {
        ...newFilters,
      };
    }
    console.log(newFilters);
    setLoading(true);
    dispatch(getTechnicians(newFilters))
      .then((response) => {
        setPagination({
          ...pagination,
          total: response?.meta?.pagination?.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //to show phone number with country code
  function getPhoneNumber(data) {
    let code =
      data?.countryCode?.indexOf("+") === 0
        ? data.countryCode
        : "+" + data.countryCode;
    let phone = data.phone ? data.phone : "NA";
    return code + " " + phone;
  }


  function getTechnicianNumber(data) {
    if (data?.technicianCode) {
      return data.technicianCode;
    } else {
      return "NA";
    }
  }

  function handleEdit(data) {
    dispatch({
      type: "SHOW_TECHNICIAN_FORM",
      payload: data,
    });
  }

  //get firstname and lastname from record and show in as name
  function getName(record) {
    return `${record.firstName} ${record.lastName}`;
  }


  function getUserType(record) {
    if (record.technicianCode) {
      return "Field Technician";
    } else {
      return "Admin";
    }
  }

  function getMarkets(record) {
    return record.markets.map((mkt) => mkt.alias !=null ? mkt.alias: "NA").join(", ");
  }

  function confirm(e,record) {
    let valuesCopy ={};
    valuesCopy.id = record?.id;
    valuesCopy.active = e;
    const CREDENTIALS = {
      url: `/technicians`,
      method: 'put',
      data: valuesCopy
    };

    API.common(CREDENTIALS)
      .then((response) => {
        dispatch({
          type: 'TECHNICIAN_LAST_UPDATED_TIME',
          payload: timeStamp(),
        });
      }).catch(err=>console.log(err))
  }
  
  function cancel(e) {
    console.log(e);
  }

  
  //table data that needs to be shown
  const columns = [
    {
      title: "NAME",
      dataIndex: "firstName",
      key: "id",
      render: (id, record) => getName(record),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "id",
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "id",
      sorter: false,
      render: (id, record) => getPhoneNumber(record),
    },
    {
      title: "TECHNICIAN NUMBER",
      dataIndex: "technicianCode",
      key: "key",
      render: (id, record) => getTechnicianNumber(record),
    },
    {
      title: "USER TYPE",
      dataIndex: "technicianCode",
      key: "id",
      render: (id, record) => getUserType(record),
    },
    {
      title: "MARKETS",
      dataIndex: "markets",
      key: "id",
      render: (id, record) => getMarkets(record),
    },
    {
      title: "UPDATE",
      dataIndex: "id", // to get data from api
      key: "id", // for column sorting key
      sorter: false,
      render: (id, record) => (
        <Button type="link" onClick={() => handleEdit(record)}>
          Update
        </Button>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "id", // to get data from api
      key: "id", // for column sorting key
      sorter: false,
      render: (id, record) => (
        <Popconfirm
    title={record.active ===1? "Click to Deactivate the Technician":"Click to Activate the Technician"}
    onConfirm={()=>confirm(record?.active ===1?0:1, record)}
    onCancel={()=>cancel("close")}
    okText="Yes"
    cancelText="No"
  >
    {
      record?.active === 1? <Button type="link">Active</Button>:<Button type="link">Inactive</Button>
    }
  </Popconfirm>
      ),
    },
  ];

  //table data
  return (
    <div style={{ marginTop: "20px" }}>
      <Table
        dsize="small"
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={technicians}
        locale={{ emptyText: "No employee available" }}
        loading={loading}
        style={{ marginTop: "50px" }}
        onChange={handleTableChange}
      />
    </div>
  );
}
