import _ from 'lodash';
const initialState = {
	technicians:[],
	users:[],
	consumers:[],
	markets:[],
	bookings:[],
	SelectedJobs:[],
	JobBasedOnTime:[],
	Services:[],
	ServicesShow:[],
	vehicleTypes:[],
	pricings:[],
	productsCategories:[],
	address:[],
	companyName:[]
};

const getVehicals=(data)=>{
	const newData = data;
	let vehicalData =[];
	newData.filter(item=>{
		if(item.name === "Sedan" || item.name === "Coupe" || item.name ==="Convertible" || item.name ==="Hatchback" || item.name ==="Wagon"){
			item.tier = 'Tier-1';
			vehicalData = [...vehicalData, item];
		}
		if(item.name === "SUV/Small Pick Up/Minivans"){
			item.tier = 'Tier-2';
			vehicalData = [...vehicalData, item];
		}
		if(item.name === "Cargo Van" || item.name === "Chassis"){
			item.tier = 'Tier-3';
			vehicalData = [...vehicalData, item];
		}
		if(item.name === "Heavy Duty Truck"){
			item.tier = 'Tier-4';
			vehicalData = [...vehicalData, item];
		}
		
	})
	
	return vehicalData;
}

const modifyService=(data)=>{
	let newData = [...data];
	console.log(data);
	const unique = [...new Set(newData.map(item => item?.service?.id))];
	let modifiedArray=[];
	newData.forEach((item,index)=> {
		let internalData={};
		let modifiedData = newData?.filter(item1=> unique[index] == item1?.service?.id);
		
		for(let i=0; i<=modifiedData?.length-1; i++){
			let markets=[];
		
			for(let j=0; j<=modifiedData?.length-1; j++){
				markets.push(modifiedData[j]?.market);
			}
			internalData.service = modifiedData[0]?.service;
			internalData.market=markets;
			internalData.active =modifiedData[0]?.active;
			internalData.id=modifiedData[0]?.id;
		}
		if(!_.isEmpty(internalData)){
		modifiedArray.push(internalData);
		}
	})
	console.log(modifiedArray);
	return data;
}

export function model(state = initialState, action) {
	if (action.type === 'API_MARKETS_LIST') {
		return { ...state, markets: action.payload };
	} else if (action.type === 'API_TECHNICIANS_LIST'){
		return {...state, technicians:action.payload}
	}else if (action.type === 'API_USERS_LIST'){
		return {...state, users:action.payload}
	}else if(action.type === 'API_CONSUMERS_LIST'){
		return {...state, consumers:action.payload}
	}
	else if(action.type === 'API_MARKETS_LIST'){
		return {...state, markets:action.payload}
	}else if(action.type === 'API_BOOKINGS_LIST'){
		return {...state, bookings:action.payload}
	}else if(action.type === 'API_SELECTED_BOOKINGS_LIST'){
		return {...state, SelectedJobs:action.payload}
	}else if(action.type === 'API_SELECTED_BOOKINGS_TIME_LIST'){
		return {...state, JobBasedOnTime:action.payload}
	}
	else if(action.type === 'API_SERVICES_LIST'){
		return {...state, Services:action.payload}
	}
	else if(action.type === 'API_SERVICES_LIST1'){
		return {...state, ServicesShow:modifyService(action.payload)}
	}
	else if(action.type === 'API_PRICINGS_LIST'){
		return {...state, pricings:action.payload}
	}
	else if(action.type === 'API_ADDRESS_LIST'){
		return {...state, address:action.payload}
	}
	else if(action.type === 'API_COMPANY_NAME_LIST'){
		return {...state, companyName:action.payload}
	}
	else if(action.type === 'API_VEHICLE_TYPES_LIST'){
		return {...state, vehicleTypes:getVehicals(action.payload)}
	}
	else if(action.type === 'API_PRODUCTS_CATEGORIES_LIST'){
		return {...state, productsCategories:action.payload}
	}
	 else {
		return state;
	}
}
