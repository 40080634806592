import React, { useState, useEffect } from "react";
import { Button, Table } from "antd";
import { getUserData } from "helpers/data-parser";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { API } from "services";
import ActiveInactive from "./ActiveInactive";
import { timeStamp } from 'helpers/date-formatter';

export default function DTProfile() {
  let data1 = JSON.parse(localStorage.getItem("userInfoData"));
  let adminUserType = data1?.userType?.id;
  let adminMarket = {};

  if (adminUserType === 4) {
    adminMarket = data1?.markets;
  }
  console.log(data1?.id);
  const [markets, setMarkets] = useState([]);
  const { lastUpdatedTime } = useSelector((state) => state.profile);
  let [data, setData] = useState([]);
  const dispatch = useDispatch();

  //filtering current user from users list
  const { id } = getUserData();
  const newData = data.filter((user) => user.id === id);

  //show edit profile form
  function handleEdit(id) {
    const value = newData[0];
    dispatch({
      type: "SHOW_PROFILE_FORM",
      payload: value,
    });
  }

  //gets users data
  useEffect(() => {
    const CREDENTIALS = {
      url: `/users/${data1?.id}/markets`,
      method: "get",
      queryParams: {
        all: true,
      },
    };

    API.common(CREDENTIALS)
      .then((response) => {
        console.log(response);
        let data = [];
        if (adminUserType === 4) {
          data = response?.data?.userMarkets?.filter((item) =>
            adminMarket?.includes(item?.market?.id)
          );
        } else {
          data = response?.data?.userMarkets;
        }
        setMarkets(data);
      })
      .catch((err) => console.log(err));

    const CREDENTIALS1 = {
      url: `/users`,
      method: "get",
      queryParams: {
        all: true,
      },
    };

    API.common(CREDENTIALS1)
      .then((response) => {
        console.log(response);
        setData(response.data.users);
      })
      .catch((err) => console.log(err));
  }, [lastUpdatedTime]);

  const columns = [
    {
      title: "Market",
      dataIndex: "market",
      key: "market",
      render:(record)=> record?.alias === null?'NA':record?.alias
    },
    {
      title: "Email Notification",
      key: "active",
      render:(record)=><ActiveInactive record={record} tempData={data1?.id}/>
    },
  ];

  
  //showing user data
  return (
    <div style={{ marginTop: "20px" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="primary" onClick={() => handleEdit(id)}>
          <PlusOutlined /> Edit Profile
        </Button>
      </div>
      <div
        style={{
          background: "#fefefe",
          marginTop: "10px",
          paddingBottom:"40px"
        }}
      >
        {newData.map((m) => (
          <>
            <div style={{ padding: "30px 0 0 90px" }}>
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "77%", marginRight: "20px" }}>
                  <p>First Name</p>
                  <p
                    style={{
                      border: "1px solid #ededed",
                      boxShadow: "0px -2px 2px #ededed",
                      padding: "10px",
                    }}
                  >
                    {m.firstName}
                  </p>
                </div>
                <div style={{ width: "77%" }}>
                  <p>Last Name </p>
                  <p
                    style={{
                      border: "1px solid #ededed",
                      padding: "10px",
                      boxShadow: "0px -2px 2px #ededed",
                    }}
                  >
                    {m.lastName}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "50%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "77%", marginRight: "20px" }}>
                  <p>Email</p>
                  <p
                    style={{
                      border: "1px solid #ededed",
                      padding: "10px",
                      boxShadow: "0px -2px 2px #ededed",
                    }}
                  >
                    {m.email}
                  </p>
                </div>
                <div style={{ width: "77%" }}>
                  <p>Phone</p>
                  <p
                    style={{
                      border: "1px solid #ededed",
                      padding: "10px",
                      boxShadow: "0px -2px 2px #ededed",
                    }}
                  >
                    {m.countryCode
                      ? m.countryCode.charAt(0) === "+"
                        ? m.countryCode
                        : "+" + m.countryCode
                      : ""}{" "}
                    {m.phone}
                  </p>
                </div>
              </div>
            </div>
          </>
        ))}
        <div style={{ padding: "30px 0 0 90px" }}>
          <p>
            <b>Email Preference:</b>
          </p>
          <div style={{ width: "50%" }}>
            <Table dataSource={markets} columns={columns} scroll={{ y: 400 }}/>
          </div>
        </div>
      </div>
    </div>
  );
}
