import React, { useState, useEffect } from "react";
import {
  getPricings,
  getServices,
  getVehicleTypes,
  getMarkets,
  getCategories,
} from "services/common";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "hooks";
import { Table, Button, Popconfirm, message } from "antd";
import { PageHeader, Row, Col, Form, Radio, Select, Typography } from "antd";
import { getParamsFromUrl, pushParamsToUrl } from "helpers/history";
import { useHistory, useLocation } from "react-router-dom";
import { isArray } from "lodash";
import { API } from "services";
import { timeStamp } from "helpers/date-formatter";

export default function DTDiscount() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { lastUpdatedTime } = useSelector((state) => state.discount);
  const [discount, setDiscount] = useState([]);
  const [pagination, setPagination] = useState({
    all: true,
    pageSize: 10,
  });

  useEffect(() => {
    //api call
    const CREDENTIALS = {
      url: `/coupons`,
      method: "get",
      queryParams: {
        available: [0, 1],
        ...pagination,
      },
    };

    API.common(CREDENTIALS)
      .then((response) => {
        setDiscount(response?.data?.coupons);
      })
      .catch((err) => console.log(err));
  }, [lastUpdatedTime]);

  function confirm(e, record) {
    const valuesCopy = { ...record };
    console.log(e);
    let data = {available:e};
    let couponId = valuesCopy?.id;
    console.log(couponId);
    const CREDENTIALS = {
      url: `coupons/${couponId}/active`,
      method: "patch",
      data:data,
    };

    API.common(CREDENTIALS)
      .then((response) => {
        dispatch({
          type: "DISCOUNT_LAST_UPDATED_TIME",
          payload: timeStamp(),
        });
      })
      .catch((err) => console.log(err));
  }

  function cancel(e) {}

  function handleEdit(data) {
    dispatch({
      type: "SHOW_DISCOUNT_FORM",
      payload: data,
    });
  }
  const columns = [
    {
      title: "Coupon Name",
      dataIndex: "code",
      key: "code",
      render: (record) => record,
    },
    {
      title: "Discount Type",
      dataIndex: "type",
      key: "type",
      render:(record)=> record === 1? "Flat Price":"Percentage"
    },
    {
      title: "Discount Amount",
      dataIndex: "amount",
      key: "amount",
      render: (record) => (record != null ? `$ ${record}` : `NA`),
    },
    {
      title: "Discount Percentage",
      dataIndex: "percent",
      key: "percent",
      render: (record) => (record != null ? `${record}%` : "NA"),
    },
    {
      title: "Update",
      key: "id",
      render: (record) => (
        <Button type="link" onClick={() => handleEdit(record)}>
          Update
        </Button>
      ),
    },
    {
      title: "Status",
      key: "available",
      render: (record) => {
        return (
          <>
            {record?.available == 1 ? (
              <Popconfirm
                title="Are you sure to Deactive Coupons?"
                onConfirm={() => confirm(0, record)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                Active
                </Button>
              </Popconfirm>
            ) : (
              <Popconfirm
                title="Are you sure to Activate Coupons?"
                onConfirm={() => confirm(1, record)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                  InActive
                </Button>
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];
  return (
    <div>
      <Table dataSource={discount} columns={columns} pagination={pagination} />
    </div>
  );
}
