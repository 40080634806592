import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

export function JobTitleInfo({ title, data }) {
	return (
		<div>
			<Text type="secondary">{title}</Text>{' '}
			<Text strong>{data ? data : 'NA'}</Text>
		</div>
	);
}
