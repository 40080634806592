const initialState = {
	servicesFormVisible: false,
	editableServices: {},
	lastUpdatedTime: ''
};

export function services(state = initialState, action) {
	if (action.type === 'SHOW_SERVICES_FORM') {
		return {
			...state,
			servicesFormVisible: true,
			editableServices: action.payload,
			
		};
	} else if (action.type === 'HIDE_SERVICES_FORM') {
		return { ...state, servicesFormVisible: false, editableServices: {} };
	} else if (action.type === 'SERVICES_STATUS_CHANGE_FORM') {
		let data = state.editableServices;
		data.status = action.payload;
		return {
			...state,
			editableServices: data,
		};
	} else if (action.type === 'SERVICES_LAST_UPDATED_TIME') {
		return { ...state, lastUpdatedTime: action.payload };
	} else {
		return state;
	}
}
