const initialState = {
	profileFormVisible: false,
	editableProfile: {},
	lastUpdatedTime: ''
};

export function profile(state = initialState, action) {
	if (action.type === 'SHOW_PROFILE_FORM') {
		return {
			...state,
			profileFormVisible: true,
			editableProfile: action.payload,
			
		};
	} else if (action.type === 'HIDE_PROFILE_FORM') {
		return { ...state, profileFormVisible: false, editableProfile: {} };
	} else if (action.type === 'PROFILE_STATUS_CHANGE_FORM') {
		let data = state.editableProfile;
		data.status = action.payload;
		return {
			...state,
			editableProfile: data,
		};
	} else if (action.type === 'PROFILE_LAST_UPDATED_TIME') {
		return { ...state, lastUpdatedTime: action.payload };
	} else {
		return state;
	}
}
