import { API } from 'services';

let data = JSON.parse(localStorage?.getItem('userInfoData'));
let adminUserType = data?.userType?.id;
let adminMarket=[];

if(adminUserType === 4){
	adminMarket = data?.markets;
}
export const getCategories=()=>(dispatch)=>{
	const CREDENTIALS = {
		url: `/categories`,
		queryParams: {
			all: true,
		},
	};
	API.common(CREDENTIALS).then((response) => {
		const data = response.data.categories;
		dispatch({
			type: 'API_PRODUCTS_CATEGORIES_LIST',
			payload: data,
		});
	});
}



export const getTechnicians=(queryParams = {})=>(dispatch)=>{
	if(adminUserType === 4){
	queryParams.markets= queryParams.markets?queryParams.markets:adminMarket;
	}

	queryParams.active =queryParams.active?queryParams.active:[0,1];
	queryParams.sort="lastName|ASC"
	let CREDENTIALS = {
		url: `/technicians`,
		queryParams: {
			...queryParams,
		},
	};

	return API.common(CREDENTIALS).then((response) => {
		const data = response.data.technicians;

		dispatch({
			type: 'API_TECHNICIANS_LIST',
			payload: data,
		});
		return response;
	});

}

export const getUsers=(queryParams = {})=>(dispatch)=>{
	console.log(adminMarket);
	queryParams.sort="lastName|ASC"
	let CREDENTIALS = {
		url: `/users`,
		queryParams: {
			...queryParams,
		},
	};

	return API.common(CREDENTIALS).then((response) => {
		const data = response.data.users;
		
		let newData=[];
		if(adminUserType ===4){
		let myData = data.forEach(element => {
				
				let markets = element?.markets?.filter(item => adminMarket?.includes(item.market.id));
				if(markets?.length >0){
				element.markets = markets;
				newData.push(element);
				}
			
		});
	}else{
		newData=data;
	}
		console.log(newData);
		dispatch({
			type: 'API_USERS_LIST',
			payload: newData,
		});

		// newData.sort((a, b) => a.lastName.localeCompare(b.lastName));
		return newData;
	});
}


export const getConsumers=()=>(dispatch)=>{
	let CREDENTIALS = {
		url: `/consumers`,
		queryParams: {
			all:true,
			sort:"lastName|ASC"
		},
	};

	return API.common(CREDENTIALS).then((response) => {
		const data =response.data.consumers;
		dispatch({
			type:"API_CONSUMERS_LIST",
			payload:data,
		});
		return response;
	});

}


export const getAddress=()=>(dispatch)=>{
	let CREDENTIALS = {
		url: `/addresses`,
		queryParams: {
			all:true,
			unique:true,
		},
	};

	return API.common(CREDENTIALS).then((response) => {
	const data =response.data.addresses;
		

		function compare( a, b ) {
			if ( a.full < b.full ){
			  return -1;
			}
			if ( a.full > b.full ){
			  return 1;
			}
			return 0;
		  }
		  
		  let newdata =response.data.addresses.sort( compare );
		  dispatch({
			type:"API_ADDRESS_LIST",
			payload:newdata,
		});
		return newdata;
	});
}

export const getCompanyName=()=>(dispatch)=>{
	let CREDENTIALS = {
		url: `/consumers/company-names`,
		queryParams: {
			all:true
		},
	};

	return API.common(CREDENTIALS).then((response) => {
	const data = response.companyNames;
	dispatch({
		type:"API_COMPANY_NAME_LIST",
		payload:data
	});
	});
}


export const getJobs=()=>(dispatch)=>{
	let CREDENTIALS = {
		url: `/bookings`,
		queryParams:{
			all:true
		}
	};

	return API.common(CREDENTIALS).then((response) => {
		const data= response.data.bookings;
		dispatch({
			type:"API_BOOKINGS_LIST",
			payload:data,
		});
		return response;
	});

}

export const getSelectedJobs=(queryParams)=>(dispatch)=>{
	delete queryParams.download;
	let CREDENTIALS = {
		url: `/bookings`,
		queryParams
	};

	return API.common(CREDENTIALS).then((response) => {
		const data= response.data.bookings;
		console.log(data);
		dispatch({
			type:"API_SELECTED_BOOKINGS_LIST",
			payload:data,
		});
		return response;
	});

}

export const getSelectedJobsBasedOnTime=(queryParams)=>(dispatch)=>{
	console.log(queryParams);
	delete queryParams.download;
	let CREDENTIALS = {
		url: `/bookings`,
		queryParams
	};

	return API.common(CREDENTIALS).then((response) => {
		const data= response.data.bookings;
		
		dispatch({
			type:"API_SELECTED_BOOKINGS_TIME_LIST",
			payload:data,
		});
		return response;
	});

}

const downloadFile = (data, filename) => {
	const blob = new Blob([data],{
		type:'text/csv;charset=utf-8;'
	});
	if(navigator.msSaveBlob){
		window.navigator.msSaveBlob(blob, filename);
	}else {
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
};

export const getSelectedDownload=(queryParams)=>(dispatch)=>{
	if(queryParams.statuses[0] == ''){
		delete queryParams.statuses;
	}
	queryParams.download=true;
	queryParams.all=true;
	let CREDENTIALS = {
		url: `/bookings`,
		queryParams
	};

	return API.common(CREDENTIALS).then((response) => {
		downloadFile(response, 'report.csv');
	});

}

export const getMarkets=(queryParams)=>(dispatch)=>{
	let CREDENTIALS = {
		url: `/markets`,
		queryParams
	};

	return API.common(CREDENTIALS).then((response) => {
		let data=[];
		if(adminUserType === 4){
			data = response?.data?.markets?.filter(item=> adminMarket?.includes(item?.id));
		   }else{
			data = response?.data?.markets;
		   }
		
		dispatch({
			type:"API_MARKETS_LIST",
			payload:data,
		});
		return data;
	});

}

export const getVehicleTypes=(queryParams)=>(dispatch)=>{
	let CREDENTIALS = {
		url: `/vehicle-types`,
		queryParams
	};

	return API.common(CREDENTIALS).then((response) => {
		const data =response.data.vehicletypes;
		dispatch({
			type:"API_VEHICLE_TYPES_LIST",
			payload:data,
		});
		return response;
	});

}

export const getServices=(queryParams)=>(dispatch)=>{
	if(adminUserType === 4){
	queryParams.markets= queryParams.markets?queryParams.markets:adminMarket;
	}
	queryParams.active = queryParams.active?queryParams.active:[0,1];
	let CREDENTIALS = {
		url: `/markets/services`,
		queryParams
	};

	return API.common(CREDENTIALS).then((response) => {
		console.log(response);
		const data = response.data.marketServices;

		dispatch({
			type:"API_SERVICES_LIST",
			payload:data,
		});
		return response;
	});

}

export const showServices=(queryParams)=>(dispatch)=>{
	if(adminUserType === 4){
		queryParams.markets= queryParams.markets?queryParams.markets:adminMarket;
		}
	queryParams.active = queryParams.active?queryParams.active:[0,1];
	let CREDENTIALS = {
		url: `/markets/services`,
		queryParams
	};

	return API.common(CREDENTIALS).then((response) => {
		const data = response.data.marketServices;
		
		dispatch({
			type:"API_SERVICES_LIST1",
			payload:data,
		});
		return response;
	});

}

export const getPricings = (queryParams)=>(dispatch)=>{
	if(adminUserType ===4){
	queryParams.markets= queryParams.markets?queryParams.markets:adminMarket;
	}
	let CREDENTIALS = {
		url: `/services/pricings`,
		queryParams
	};

	return API.common(CREDENTIALS).then((response) => {
		console.log(response);
		const data = response.data.pricings;

		dispatch({
			type:"API_PRICINGS_LIST",
			payload:data,
		});
		return response;
	});

}




