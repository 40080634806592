import React, { lazy, Suspense } from "react";
import { PageHeader } from "antd";
import { CUPrices} from "../components/pages/pricing";
const DTPrices = lazy(() =>import("components/pages/pricing/DTPrices"));

function Pricings() {
  return (
    <div>
      <div className="p-20 white-bc title-box-shadow">
        <PageHeader className="mb-10" title="Pricings" />
      </div>

      <div className="p-20 pt-0">
        <CUPrices/>

        <Suspense fallback={<p>Loading...</p>}>
          <DTPrices />
        </Suspense>
      </div>
    </div>
  );
}

export default Pricings;
