import React, { useState, useEffect } from "react";
import { getServices, getMarkets, showServices,getCategories } from "services/common";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParams } from "hooks";
import _ from 'lodash';
import {
  Table,
  Button,
  Tooltip,
  Row,
  Col,
  Form,
  Select,
  Popconfirm,
  message,
  Popover
} from "antd";
import { getParamsFromUrl, pushParamsToUrl } from "helpers/history";
import { useHistory, useLocation } from "react-router-dom";
import { API } from "services";
import { timeStamp } from "helpers/date-formatter";
import Checkbox from "antd/lib/checkbox/Checkbox";
const { Option } = Select;

export default function DTServices() {
  const { userStatus } = useQueryParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const params = getParamsFromUrl(history);
  const { lastUpdatedTime } = useSelector((state) => state.services);
  const { Services = [],productsCategories } = useSelector((state) => state?.model);
  const { ServicesShow = [] } = useSelector((state) => state?.model);
  const { markets = [] } = useSelector((state) => state?.model);
  const [selectedRows, setSelectedRowKey] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState();
  const [DateSevice, setDataSevice]=useState([]);
  const [selectedMarket, setSelectedMarket] = useState("Select Market");
  const [selectedCategory, setselectedCategory] = useState("Select Category");
  const [accessData, setAccessData] =useState([]);
  //const [allService, setAllService]=useState([]);
  const [selectedService, setSelectedService] = useState("Select Service");
  const [pagination, setPagination] = useState({
    all: true,
    pageSize: 10,
  });
 

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    
    setSelectedRowKey(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
    //console.log(selectedRows.map((item) => item?.service?.id));
  };
  const modifyService=(data)=>{
    let newData = [...data];
    
    const unique = [...new Set(newData.map(item => item?.service?.id))];
    let modifiedArray=[];
    newData.forEach((item,index)=> {
      let internalData={};
      let modifiedData = newData?.filter(item1=> unique[index] == item1?.service?.id);
      
      for(let i=0; i<=modifiedData?.length-1; i++){
        let markets=[];
      
        for(let j=0; j<=modifiedData?.length-1; j++){
          markets.push(modifiedData[j]?.market);
        }
        internalData.service = modifiedData[0]?.service;
        internalData.market=markets;
        internalData.active =modifiedData[0]?.active;
        internalData.id=modifiedData[0]?.id;
      }
      if(!_.isEmpty(internalData) && internalData?.service != null){
      modifiedArray.push(internalData);
      }
    })
    
    return modifiedArray;
  }

  useEffect(() => {
    const params = getParamsFromUrl(history);
    const queryParams = { ...params, ...pagination };
    if (queryParams?.markets?.length > 0) {
      setSelectedMarket(parseInt(queryParams?.markets?.[0]));
    }
    if (queryParams?.services?.length > 0) {
      setSelectedService(parseInt(queryParams?.services?.[0]));
    }
    queryParams.active = [0, 1];
    dispatch(showServices(queryParams))
      .then((response) => {
        // let myData = modifyService(response.data.marketServices);
        // setAllService(myData);
        setPagination({
          ...pagination,
          total: response?.meta?.pagination?.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    dispatch(getMarkets({ all: true })).then((res) => res);
    dispatch(getServices({ all: true, active:[0,1] })).then((res) => console.log(res));
    dispatch(getCategories({all:true}));
  }, [lastUpdatedTime, userStatus, location]);

  useEffect(()=>{
    setDataSevice(Services);
  },[])

  function handleMarketChange(data) {
    pushParamsToUrl(history, {
      ...params,
      markets: data ? [data] : "",
      services:"",
      categories:""
    });
    setSelectedMarket(data);
    setselectedCategory('Select Category');
    setSelectedService('Select Service');
    handleTableChange(data, 'Select Service');
  }

  function handleCategory(data) {
    pushParamsToUrl(history, {
      ...params,
      categories: data ? [data] : "",
      services:""
    });
    setselectedCategory(data);
    let serviceData = Services?.filter(item=> data == item.category.id);
	setDataSevice(serviceData);
  setSelectedService('Select Service');
    handleTableChange(selectedMarket, 'Select Service', data);
  }

  function handleServiceChange(data) {
    pushParamsToUrl(history, {
      ...params,
      services: data ? [data] : "",
    });
   
    setSelectedService(data);
    handleTableChange(selectedMarket, data,selectedCategory);
  }

  const handleTableChange = (markets, services,category) => {
    if (typeof markets != "object") {
      pushParamsToUrl(history, {
        ...params,
        markets: markets != "Select Market" ? [markets] : [],
        services: services != "Select Service" ? [services] : [],
        categories:category!="Select Category"?[category]:[]
      });

      // let status = userStatus === "false" ? 0 : 1;
      // let newFilters = {
      //   ...pagination,
      //   status,
      // };

      // if (pagi) {
      //   newFilters = {
      //     ...newFilters, ...params
      //   };
      // }

      setLoading(true);
    }
  };

  //gets service type

  const getServiceType = (id, record) => {
    return record?.type === 1 ? "Primary Service" : "Add on Service";
  };

  //get name with icon
  const getName = (id, record) => {
    if (record?.type === 1) {
      return (
        <span className="dt-icon">
          <img
            src={record?.icon}
            alt="service"
            className="dt-custom-icon"
          />
          &nbsp;&nbsp;
          <span>{`${record?.name?.substring(0,15)}`}{record?.name?.length >15 && <Tooltip placement="rightTop" title={record?.name}>
            <span style={{ color: "#1890ff", cursor: "pointer" }}>...</span>
          </Tooltip>}</span>
        </span>
      );
    } else {
      if (
        record?.icon ===
        "https://dfs-assets-np.s3.amazonaws.com/categories/icons/battery.png"
      ) {
        return (
          <span className="dt-icon">
            <i className={`dt-custom-icon-font service-icon icon-addon_icon`} />
            &nbsp;&nbsp;
            <span>{`${record?.name?.substring(0,15)}`}{record?.name?.length >15 && <Tooltip placement="rightTop" title={record?.name}>
            <span style={{ color: "#1890ff", cursor: "pointer" }}>...</span>
          </Tooltip>}</span>
          </span>
        );
      } else {
        return (
          <span className="dt-icon">
            <img
              src={record?.icon}
              alt="service"
              className="dt-custom-icon"
            />
            &nbsp;&nbsp;
            <span>{`${record?.name?.substring(0,15)}`}{record?.name?.length >15 && <Tooltip placement="rightTop" title={record?.name}>
            <span style={{ color: "#1890ff", cursor: "pointer" }}>...</span>
          </Tooltip>}</span>
          </span>
        );
      }
    }
  };

  function findCategoriesType(id, record) {
    return (
      <span className="dt-icon services">
        <img
          src={record?.category?.icon}
          alt="service"
          className="dt-custom-icon"
        />
        &nbsp;&nbsp;
        <span>
          {record?.category === null
            ? "NA"
            : `${record?.category?.name}`}
            &nbsp;&nbsp;
        </span>
      </span>
    );
  }

  function handleEdit(data) {
    dispatch({
      type: "SHOW_SERVICES_FORM",
      payload: data,
    });
  }

  function getDescription(id, record) {
    
    const desc = (
      <div>
        {record?.description?.substring(0, 12)}
        {record?.description?.length > 12 && (
          <Tooltip placement="rightTop" title={record?.description}>
            <span style={{ color: "#1890ff", cursor: "pointer" }}>
              ...
            </span>
          </Tooltip>
        )}
      </div>
    );

    return desc;
  }

  function confirm(e, activeData, market,status) {
    console.log(e,'----',activeData,'----------',market,'-------',status)
    let data;
    if (activeData == 1) {
      data = 0;
    } else if (activeData == undefined) {
      if(status == "active"){
        data = 1;
      }else{
        data = 0;
      }
    } else {
      data = 1;
    }

    let services = [];
    if (typeof e == "number") {
      e = e;
    } else {
      services = e.map((item) => item?.id);
      market = e[0]?.market?.id;
    }

    console.log(data);
    const CREDENTIALS = {
      url: `/markets/${market}/services`,
      method: "put",
      data: { active: data, services: typeof e == "number" ? [e] : services },
    };

    API.common(CREDENTIALS).then((response) => {
      dispatch({
        type: "SERVICES_LAST_UPDATED_TIME",
        payload: timeStamp(),
      });
      setSelectedRowKey([]);
      setSelectedRowKeys([]);
    });
  }

  function cancel(e) {
    console.log(e);
  }
  function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  const showMarkets=(record)=>{
    let data =record?.markets?.map(item=> item.alias? item.alias:"NA");
    let marketsData = data?.join(' , ');
    let twoIndex =getPosition(marketsData,',',2);
      let fourIndex =getPosition(marketsData,',',4);
      let sixIndex = getPosition(marketsData,',',6);
      let eightIndex =  getPosition(marketsData,',',8);
      let tenIndex =  getPosition(marketsData,',',10);
    if(marketsData.length>0 && marketsData.length<21){
      return marketsData.substring(0,20)
    }
    else if(marketsData.length >20 && marketsData.length <41){
      return `${marketsData.substring(0,twoIndex)}\n${marketsData.substring(twoIndex,fourIndex)}\n${marketsData.substring(fourIndex,sixIndex)}`
    }
    else if(marketsData.length >40 && marketsData.length < 61){
      return `${marketsData.substring(0,twoIndex)}\n${marketsData.substring(twoIndex,fourIndex)}\n${marketsData.substring(fourIndex,sixIndex)}\n${marketsData.substring(sixIndex,eightIndex)}`
    }
    else if(marketsData.length >60 && marketsData.length < 81){
      return `${marketsData.substring(0,twoIndex)}\n${marketsData.substring(twoIndex,fourIndex)}\n${marketsData.substring(fourIndex,sixIndex)}\n${marketsData.substring(sixIndex,eightIndex)}\n${marketsData.substring(eightIndex)}`
    }
    else if(marketsData.length >80){
      return `${marketsData.substring(0,twoIndex)}\n${marketsData.substring(twoIndex,fourIndex)}\n${marketsData.substring(fourIndex,sixIndex)}\n${marketsData.substring(sixIndex,eightIndex)}\n${marketsData.substring(eightIndex,tenIndex)}\n${marketsData.substring(tenIndex)}`
    }
    else{
      return 'No Market'
    }
  }

  const handleAccess=(e)=>{
    setAccessData(e.target.value);
  }

  const content=(record)=>{

    console.log(record);
    return(
    <div>
      {record?.markets?.map(item=> (
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <p>{item?.alias? item?.alias:'NA'}</p> 
          {item?.active ===1? <Button type="link" onClick={()=>confirm(record?.id, 1, item?.id)}>Active</Button>:<Button type="link" onClick={()=>confirm(record?.id, 0, item?.id)}>Inactive</Button>}
        </div>
      ))}
    </div>
  )}

  //table data that needs to be shown
  const columns = [
    {
      title: "SERVICE NAME",
      dataIndex: "service",
      key: "service",
      width:"5%",
      render: (id, record) => getName(id, record),
    },
    {
      title: "CATEGORY",
      dataIndex: "service",
      key: "service",
      render: (id, record) => findCategoriesType(id, record),
      width:'12%'
    },
    {
      title: "SERVICE TYPE",
      dataIndex: "service",
      key: "service",
      render: (id, record) => getServiceType(id, record),
      width:'5%'
    },
    {
      title: "MARKET",
      dataIndex: "market",
      key: "market",
      width:"8%",
      render: (id, record) => showMarkets(record),
    },
    {
      title: "DESCRIPTION",
      dataIndex: "service",
      key: "service",
      render: (id, record) => getDescription(id,record),
      width:"10%"
    },
    {
      title: "UPDATE",
      dataIndex: "id", // to get data from api
      key: "id", // for column sorting key
      sorter: false,
      width:"5%",
      render: (id, record) => (
        <Button type="link" onClick={() => handleEdit(record)}>
          Update
        </Button>
      ),
    },
    {
      title: "ACTION",
      key: "id", // for column sorting key
      sorter: false,
      width:"5%",
      render: (record) => {
        let market = record?.markets?.map(item=> item?.active == 1);
        let Truechecker =market.every(v => v === true);
        let FalseChecker =market.every(v => v === false);
        
        return (
          // <Popconfirm
          //   title={record?.active == 1 ?"Are you sure to want to Deactivate Service?":"Are you sure to want to Activate Service?"}
          //   onConfirm={() =>
          //     confirm(record?.id, record?.active, record?.markets)
          //   }
          //   onCancel={cancel}
          //   okText="Yes"
          //   cancelText="No"
          //   placement="topLeft"
          // >
          //   <Tooltip placement="topLeft" title={record?.active == 1? "click to Inactivate":"click to Activate"}>
          //   <Button
          //     type="link"
          //     style={{ color: record?.active == 1 ? "#59981A" : "#FF0000" }}
          //   >
          //     {record?.active == 1 ? "Active" : "Inactive"}
          //   </Button>
          //   </Tooltip>
          // </Popconfirm>
          
           <Popover placement="topLeft" title={"Select Market to Activate/Deactivate Services"} 
           content={()=>content(record)} trigger="click">
           <Tooltip placement="topLeft" title={record?.active == 1? "click to Inactivate":"click to Activate"}>
           {Truechecker && record?.markets?.length >0 &&
            <Button
              type="link"
            >
               Active
            </Button>
      }
       {FalseChecker && record?.markets?.length >0 &&
            <Button
              type="link"
            >
               InActive
            </Button>
      }
      
      {Truechecker == false && FalseChecker == false && 
        <Button
        type="link"
      >
        Partially<br/>Active
      </Button>
      
      }
            </Tooltip>
            {record?.markets?.length === 0 && 
        <Button
        type="link"
      >
        Partially<br/>Active
      </Button>
      
      }
         </Popover>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRows,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Row type="flex" align="middle">
        <Col span={5}>
          <Form.Item label="Markets" className={"search-label"}>
            <Select
              showSearch
              name="market"
              style={{ width: "100%" }}
              value={selectedMarket}
              placeholder={"Select Market"}
              allowClear={true}
              optionFilterProp="children"
              onChange={(e) => handleMarketChange(e)}
            >
              {markets.map(({ id, city, alias }) => (
                <Option key={id} value={id}>
                  {alias ? alias : "NA"}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
          <Col span={5} className="ml-20">
          <Form.Item label="Category" className={"search-label"}>
          <Select
              showSearch
              name="servicecategory"
              style={{ width: "100%" }}
              value={selectedCategory}
              placeholder={"Select Category"}
              allowClear={true}
              optionFilterProp="children"
              onChange={(e) => handleCategory(e)}
            >
              {productsCategories?.length >0 && productsCategories.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
            </Form.Item>
          </Col>
        <Col span={5} className="ml-20">
          <Form.Item label="Service" className={"search-label"}>
            <Select
              showSearch
              name="category"
              style={{ width: "100%" }}
              placeholder={"Select Service"}
              value={selectedService}
              allowClear={true}
              optionFilterProp="children"
              onChange={(e) => handleServiceChange(e)}
            >
              {DateSevice.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* {selectedRows?.length > 0 && (
        <Popconfirm
          title="Are you sure to want to Deactivate Services?"
          onConfirm={() => confirm(selectedRows,undefined,undefined,"deactive")}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
          placement="topLeft"
        >
          <Button style={{background:"#FF0000", color:"#FFF"}}>Deactivate</Button>
        </Popconfirm>
      )}
      {selectedRows?.length > 0 && (
        <Popconfirm
          title="Are you sure to want to Activate Service?"
          onConfirm={() => confirm(selectedRows,undefined,undefined,"active")}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
          placement="topLeft"
        >
          <Button style={{background:"rgb(89, 152, 26)", color:"#FFF", marginLeft:"10px"}}>Activate</Button>
        </Popconfirm>
      )} */}
      <Table
        className="services_table"
        dsize="small"
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={ServicesShow}
        locale={{ emptyText: "No Services available" }}
        loading={loading}
        style={{ marginTop: "50px" }}
        onChange={handleTableChange}
        // rowSelection={selectedMarket != "Select Market"?rowSelection:null}
      />
    </div>
  );
}
