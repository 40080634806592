import { Button, Col, Row,message } from 'antd';
import { ResetButton, SubmitButton } from 'formik-antd';
import { timeStamp } from "helpers/date-formatter";
import { API } from "services";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import React from 'react';
function FormActionButtons({ onClose, isSubmitting, showDebug = true, reset=true, finalize=false, id, newSubmit=true, noNeedSaveBtn=true,close=true }) {
	const dispatch = useDispatch();
	const history = useHistory();

	function completeSubmit() {
		let status={status:"COMPLETED"};
		const CREDENTIALS = {
		  url: `/bookings/${id}/status`,
		  method: "patch",
		  data: status
		};
	
		API.common(CREDENTIALS)
		.then(() => {
		  showSuccessMessage();
		  dispatch({
			type: "HIDE_APPLY_COUPONS",
			payload: {},
		  });
		 dispatch({
		  type: "USER_JOB_LAST_UPDATED_TIME",
		  payload: timeStamp(),
		});
		})
		.catch(e => console.log(e))
	  }

	  function completeSubmit(data) {
		let status={status:"COMPLETED"};
		const CREDENTIALS = {
		  url: `/bookings/${id}/status`,
		  method: "patch",
		  data: status
		};
	
		API.common(CREDENTIALS)
		.then(() => {
		  showSuccessMessage();
		  dispatch({
			type: "HIDE_APPLY_COUPONS",
			payload: {},
		  });
		 dispatch({
		  type: "USER_JOB_LAST_UPDATED_TIME",
		  payload: timeStamp(),
		});
		if(data == "close"){
			dispatch({
				type: "HIDE_EDIT_JOB_STATUS_FORM",
				payload: {},
			  });
		}
		})
		.catch(e => console.log(e))
	  }

	  function showSuccessMessage() {
		message.success(`Job completed successfully`);
	  }
	return (
		<>
			<Row
				data-testid="FormActionButtons"
				type="flex"
				justify="space-between"
				style={{
					position: 'absolute',
					bottom: 0,
					width: '100%',
					borderTop: '1px solid #e8e8e8',
					padding: '10px 16px',
					textAlign: 'right',
					left: 0,
					background: '#fff',
					borderRadius: '0 0 4px 4px',
					zIndex: '9999',
				}}
			>
				<Col
					span={10}
					style={{
						textAlign: 'left',
					}}
				>
					{reset ?(
					<ResetButton data-testid="form-reset-btn" type="danger">
						Reset fields
					</ResetButton>
					):null}
				</Col>

				<Col
					span={14}
					style={{
						textAlign: 'right',
					}}
				>
					{close &&(
					<Button
						data-testid="form-close-btn"
						style={{
							marginRight: 8,
						}}
						onClick={onClose}
					>
						Close
					</Button>
					)}
					{noNeedSaveBtn && (
					<SubmitButton
						data-testid="form-save-btn"
						type="primary"
						disabled={isSubmitting}
					>
						Save
					</SubmitButton>
					)}
					{finalize && close &&(
					<Button onClick={completeSubmit} type="primary">
					Finalize and get payment
					</Button>
					)}
					{finalize && close == false &&(
					<Button onClick={()=>completeSubmit("close")} type="primary">
					Finalize and get payment
					</Button>
					)}
				</Col>
			</Row>
		</>
	);
}

FormActionButtons.propTypes = {
	isSubmitting: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export { FormActionButtons };
