import { getUserData } from 'helpers/data-parser';
import React, { useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Errorpopup404 } from 'services';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function PrivateRoute({ children, history, location, ...rest }) {
	const { set401Err,erro401ModelVisible} = useSelector(
		(state) => state?.job
	);
	const dispatch = useDispatch();
	const pathData = useLocation();
	const { pathname } = location;
	
	const userData = getUserData();
	const query = useQuery();

	useEffect(() => {
		return () => {
			/**
			 * to set last visited page url in redux store
			 */
			dispatch({
				type: 'SET_HISTORY_RECENT',
				payload: pathname,
			});
		};
	}, [pathname]);

	if (query.has('userInfoData')) {
		return (
			<Redirect
				to={{
					pathname: pathData.pathname,
					search: '?userStatus=true&search=all',
				}}
			/>
		);
	}

	if (userData && userData.id) {
		return <Route {...rest}>{children}</Route>;
	} else {
		window.location = process.env.REACT_APP_LOGIN_URL;
		return false;
	}
}

export default PrivateRoute;
