import React, { lazy, Suspense } from "react";
import { PageHeader } from "antd";
import { CUMarket} from "../components/pages/marketpreference";
const DTMarket = lazy(() =>import("components/pages/marketpreference/DTMarket"));

function Markets() {
  return (
    <div>
      <div className="p-20 white-bc title-box-shadow">
        <PageHeader className="mb-10" title="Markets" />
      </div>

      <div className="p-20 pt-0">
        <CUMarket/>

        <Suspense fallback={<p>Loading...</p>}>
          <DTMarket />
        </Suspense>
      </div>
    </div>
  );
}

export default Markets;
