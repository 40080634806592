import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button,Drawer} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { timeStamp } from 'helpers/date-formatter';
import { DiscountForm } from 'forms/discount/Discount.form';

export default function CUDiscount() {
    const dispatch = useDispatch();
    const { discountFormVisible, editableDiscount } = useSelector(
		(state) => state.discount
	);
	const editMode = 'id' in editableDiscount ? true : false;
	const formHeaderText = editMode
		? 'Edit Coupon':'Create Coupon';
    
    function onClose() {
		dispatch({
			type: 'HIDE_DISCOUNT_FORM',
			payload: {},
		});
		dispatch({
			type: 'DISCOUNT_LAST_UPDATED_TIME',
			payload: timeStamp(),
		});
	}

    function showDrawer(){
        dispatch({
            type: 'SHOW_DISCOUNT_FORM',
            payload: {},
        });
    }
    return (
        <div>
			<div style={{display:"flex", justifyContent:"flex-end"}}>
            <Button type="primary" onClick={showDrawer}>
				<PlusOutlined /> Add Coupon
			</Button>
			</div>
            <div>
            <Drawer
				title={formHeaderText}
				width={550}
				onClose={onClose}
				visible={discountFormVisible}
				destroyOnClose={true}
				closable={false}
				maskClosable={true}
			>
				<DiscountForm onClose={onClose} editMode={editMode} editableDiscount={editableDiscount}/>
			</Drawer>
            </div>
        </div>
    )
}


