import React, { useState, useEffect } from "react";
import { Table, Button,Modal,Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { API } from "services";
import moment from "moment-timezone";
import { useHistory } from "react-router";


export default function DTMarket() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showTimeSlot, setShowTimeSlot]= useState([]);
  const [showRecord, setShowRecord]=useState();
  const [markets, setMarkets] = useState([]);
  const { lastUpdatedTime } = useSelector((state) => state.marketpreference);
  let data = JSON.parse(localStorage.getItem('userInfoData'));
  let adminUserType = data?.userType?.id;
let adminMarket = {};

if(adminUserType === 4){
	adminMarket = data?.markets;
}
  
  console.log(adminMarket);

  // console.log(moment().endOf('week').format('DD-MM-YYYY'));
  useEffect(() => {
	  //gets market data
    const CREDENTIALS = {
      url: `/markets`,
      method: "get",
      queryParams: {
        all: true,

      },
    };

    API.common(CREDENTIALS)
      .then((response) => {
        let data = [];
       if(adminUserType === 4){
        data = response?.data?.markets?.filter(item=> adminMarket?.includes(item?.id));
       }else{
        data = response?.data?.markets;
       }
       console.log(data);
        setMarkets(data);
      })
      .catch((err) => console.log(err));
  }, [lastUpdatedTime]);

  //use when need to update the market
  function handleEdit(data) {
    dispatch({
      type: "SHOW_MARKET_PREFERENCE_FORM",
      payload: data,
    });
  }

  function getWorkingTime(id, record) {
	  //gets start time for shifts
    if (record?.marketpreference?.shiftStartTime === null) {
      return "NA";
    } else {
      let date = moment.utc().format("YYYY-MM-DD");
      let toLocal = moment
        .utc(
          `${date} ${record?.marketPreference?.shiftStartTime}`,
          "YYYY-MM-DD hh:mm:ss"
        )
        .clone()
        .tz(record?.timezone)
        .format("hh:mm A");

      if (toLocal === "Invalid date") {
        return "NA";
      } else {
        return toLocal;
      }
    }
  }

  function getEndWorkingTime(id, record) {
	  //gets end time for shifts
    if (record?.marketpreference?.shiftStartTime === null) {
      return "NA";
    } else {
      let date = moment.utc().format("YYYY-MM-DD");
      let toLocal = moment
        .utc(
          `${date} ${record?.marketPreference?.shiftEndTime}`,
          "YYYY-MM-DD HH:mm:ss"
        )
        .clone()
        .tz(record?.timezone)
        .format("hh:mm A");
        console.log(toLocal);
      if (toLocal === "Invalid date") {
        return "NA";
      } else {
        return toLocal;
      }
    }
  }

  const handleCopyService=(record)=>{
    dispatch({
      type: "SHOW_SERVICE",
      payload: record,
    });
  }

  const handleAddSchedule=(record)=>{
    dispatch({
      type: "SHOW_SCHEDULE",
      payload:record,
    });
  }

  const handleAddNewSchedule=(record)=>{
    setIsModalVisible(true);
    setShowTimeSlot([moment().day(3).format("YYYY-MM-DD"),moment().day(4).format("YYYY-MM-DD")]);
    setShowRecord(record);
    console.log(record);
    dispatch({
      type: "MARKET_MODAL",
      payload: {id:record?.id, timezone:record?.timezone, alias:record?.alias},
    });
    history.push({pathname:'markets/schedule', state:{record:record}});
  }
  
  const handleDelete=(record)=>{
    setIsModalVisible(true);
    setShowTimeSlot([moment().day(3).format("YYYY-MM-DD"),moment().day(4).format("YYYY-MM-DD")]);
    setShowRecord(record);
    console.log(record);
    dispatch({
      type: "MARKET_MODAL",
      payload: {id:record?.id, timezone:record?.timezone, alias:record?.alias},
    });
    history.push({pathname:'markets/timeslots', state:{record:record}});
  }

  // const showData=(record)=>{
  //   setShowTimeSlot(record);
  // }

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  // const days =[{label:"Monday", value:moment().day(1).format("YYYY-MM-DD")},{label:"Tuesday", value:moment().day(2).format("YYYY-MM-DD")},
  // {label:"Wednesday", value:moment().day(3).format("YYYY-MM-DD")},{label:"Thursday", value:moment().day(4).format("YYYY-MM-DD")},
  // {label:"Friday", value:moment().day(5).format("YYYY-MM-DD")}];

  // function handleDays(checkedValues) {
  //   console.log('checked = ', checkedValues);
    
  // // }
  const columns = [
    {
      title: "CITY",
      dataIndex: "city", // to get data from api
      key: "city", // for column sorting key
      sorter: false,
      render: (city) => city || "NA",
      width:"10%"
    },
    {
      title: "STATE",
      dataIndex: "state", // to get data from api
      sorter: false,
      key: "state", // for column sorting key
      render: (state) => state || "NA",
      width:"10%"
    },
    // {
    //   title: "COUNTRY",
    //   dataIndex: "country", // to get data from api
    //   key: "country", // for column sorting key
    //   sorter: false,
    //   render: (country) => country || "NA",
    // },
    // {
    //   title: "TIMEZONE",
    //   dataIndex: "timezone", // to get data from api
    //   key: "timezone", // for column sorting key
    //   sorter: false,
    // },

    {
      title: "TAX",
      dataIndex: "tax", // to get data from api
      key: "tax", // for column sorting key
      sorter: false,
      render: (tax) => (tax ? tax + " %" : "NA"),
      width:"5%"
    },
    {
      title:`MARKET \nDISPLAY\n NAME`,
      dataIndex:"alias",
      key:"_id",
      render:(record)=> record !==null ? record:"NA",
      width:"5%"
    },
    {
      title: `NUMBER OF\nSERVICES per\nAPPOINTMENT\nTIME SLOT`,
      dataIndex: "marketPreference", // to get data from api
      key: "id", // for column sorting key
      sorter: false,
      render: (marketPreference) =>
        marketPreference?.servicesPerTimeslot || "NA",
        width:"5%"
    },
    {
      title: "DISPATCH FEE",
      dataIndex: "marketPreference", // to get data from api
      key: "id", // for column sorting key
      sorter: false,
      render: (marketPreference) => marketPreference?.dispatch || "NA",
      width:"5%"
    },
    {
      title: "LENGTH OF\nAPPOINTMENT\n TIME SLOTS\n(Minutes)",
      dataIndex: "marketPreference", // to get data from api
      key: "id", // for column sorting key
      sorter: false,
      render: (marketPreference) => marketPreference?.frequency || "NA",
      width:"5%"
    },
    {
      title: `MANAGER \nAPPROVAL`,
      key: "id",
      render:(record) => record?.marketPreference?.reviewBooking === 1?"No Review":"Review",
      width:"10%"
    },
    {
      title: "START TIME",
      dataIndex: "id", // to get data from api
      key: "id", // for column sorting key
      sorter: false,
      render: (id, record) => getWorkingTime(id, record),
      width:"10%"
    },
    {
      title: "END TIME",
      dataIndex: "id", // to get data from api
      key: "id", // for column sorting key
      sorter: false,
      render: (id, record) => getEndWorkingTime(id, record),
      width:"10%"
    },
    {
      title: "ACTION",
      dataIndex: "_id", // to get data from api
      key: "_id", // for column sorting key
      sorter: false,
      width:"10%",
      render: (_id, record) => {
        return (
          <>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit Market
          </Button>
        <br/>
          <Button type="link" onClick={() => handleCopyService(record)}>
          Copy Service
        </Button>
        {/* <br/>
          <Button type="link" onClick={() => handleDelete(record)}>
          View Schedule
        </Button>
        <br/>
          <Button type="link" onClick={() => handleAddSchedule(record)}>
          Add Schedule
        </Button> */}
        <br/>
          <Button type="link" onClick={() => handleAddNewSchedule(record)}>
          Add / Edit Schedule
        </Button>
        </>
        );
      },
    },
    // {
    //   title: "DELETE SCHEDULE",
    //   key: "_id", // for column sorting key
    //   sorter: false,
    //   render: (_id, record) => {
    //     return (
    //       <Button type="link" onClick={() => handleDelete(record)}>
    //         Time Slots
    //       </Button>
    //     );
    //   },
    // }
  ];

  //show market data in table
  return (
    <div>
      {/* <Modal title="Delete Schedule" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <p>Deselect to Delete the Schedule for that Day:</p>
        <Checkbox.Group options={days} defaultValue={showTimeSlot} onChange={handleDays} />
      </Modal> */}
      {/* <Button onClick={()=> handleDelete()}>Del</Button> */}
      <Table
        size="small"
        columns={columns}
        rowKey={(record) => record?._id}
        dataSource={markets}
        locale={{ emptyText: "No markets available" }}
        style={{ marginTop: "50px", whiteSpace: "pre" }}
      />
    </div>
  );
}
